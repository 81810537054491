import React from 'react';
import { Link } from 'react-router-dom';

// STATIC
//! ICONS
import SalirIcon from '../static/icons/salirIcon.png';
import ModUsrIcon from '../static/icons/modUsrIcon.png';
import UserIcon from '../static/icons/userIcon.png';
//! IMG
import MiniLogo from '../static/img/miniLogo.png';

const Navbar = () => {

    // const site = useParams();
    // console.log("Desde Navbar: " + site);

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="btn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                    <div className="hamburguer"></div>
                    <div className="hamburguer"></div>
                    <div className="hamburguer"></div>
                </a>
                <Link className="navbar-brand ms-4" to="/"><img className="iconSide" src={MiniLogo} alt="Mini Logo" /> CliniPay</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 push-end">
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Agendamiento Web
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/aw_agendar_cita">Agendar Cita</Link></li>
                                <li><Link className="dropdown-item" to="/aw_eliminar_cita">Eliminar Cita</Link></li>
                            </ul>
                        </li> */}
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Agendamiento Interno
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/ai_agendar_cita">Agendar Cita</Link></li>
                            </ul>
                        </li> */}
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Atención
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/a">Action</Link></li>
                                <li><Link className="dropdown-item" to="/b">Another action</Link></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><Link className="dropdown-item" to="/c">Something else here</Link></li>
                            </ul>
                        </li> */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className="iconSide me-2" src={UserIcon} alt="usuario" />Julio Subaique
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/en_construccion"><img className="icon me-2" src={ModUsrIcon} alt="Modificar Usuario" />Administrar Usuario</Link></li>
                                {/* <li><Link className="dropdown-item" to="/en_construccion">Another action</Link></li> */}
                                <li><hr className="dropdown-divider" /></li>
                                <li><Link className="dropdown-item" to="/en_construccion"><img className="icon me-2" src={SalirIcon} alt="Salir" />Salir</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;