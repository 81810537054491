import React from 'react';
import UnderConstruction from '../enContrucción';

const ConfiguracionCaja = () => {
    return(
        <div className="container">
            <h1>Configuración de Caja</h1>
            <UnderConstruction />
        </div>
    );
}

export default ConfiguracionCaja;