const Citas = [
    {
        "id": 1,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "9:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Agendada"
    },
    {
        "id": 2,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "11:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Disponible"
    },
    {
        "id": 3,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "13:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Disponible"
    },
    {
        "id": 4,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "15:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Bloqueada"
    },
    {
        "id": 5,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "16:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Bloqueada"
    },
    {
        "id": 6,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "17:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Cancelada"
    },
    {
        "id": 7,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "19:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Cancelada"
    },
    {
        "id": 8,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "21:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Disponible"
    },
    {
        "id": 9,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "11:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Disponible"
    },
    {
        "id": 10,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "13:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Disponible"
    },
    {
        "id": 11,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "15:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Bloqueada"
    },
    {
        "id": 12,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "16:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Bloqueada"
    },
    {
        "id": 13,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "17:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Cancelada"
    },
    {
        "id": 14,
        "centro": "Centro Medico",
        "fecha": "27/01/2023",
        "hora": "19:00",
        "profesional": "Octavia Meyers Waller",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Cancelada"
    },
    {
        "id": 15,
        "centro": "Clínica",
        "fecha": "27/01/2023",
        "hora": "21:00",
        "profesional": "Kevin Aguilera Castro",
        "especialidad": "Cirugía Plástica",
        "prestacion": "Consulta Cirugía Plastica",
        "estado": "Disponible"
    },
]

export default Citas;

// const Citas = [
//     {
//         id: 0,
//         title: 'Board meeting',
//         start: new Date(2024, 1, 5, 9, 0, 0),
//         end: new Date(2024, 1, 5, 13, 0, 0),
//         resourceId: [1, 2],
//     },
//     {
//         id: 1,
//         title: 'MS training',
//         start: new Date(2024, 1, 5, 14, 0, 0),
//         end: new Date(2024, 1, 5, 16, 30, 0),
//         resourceId: 2,
//     },
//     {
//         id: 2,
//         title: 'Team lead meeting',
//         start: new Date(2024, 1, 5, 8, 30, 0),
//         end: new Date(2024, 1, 5, 12, 30, 0),
//         resourceId: 3,
//     },
//     {
//         id: 10,
//         title: 'Board meeting',
//         start: new Date(2024, 1, 6, 23, 0, 0),
//         end: new Date(2024, 1, 6, 23, 59, 0),
//         resourceId: 1,
//     },
//     {
//         id: 11,
//         title: 'Birthday Party',
//         start: new Date(2024, 1, 6, 7, 0, 0),
//         end: new Date(2024, 1, 6, 10, 30, 0),
//         resourceId: 4,
//     },
//     {
//         id: 12,
//         title: 'Board meeting',
//         start: new Date(2024, 1, 5, 23, 59, 0),
//         end: new Date(2024, 1, 6, 13, 0, 0),
//         resourceId: 1,
//     },
//     {
//         id: 13,
//         title: 'Board meeting',
//         start: new Date(2024, 1, 5, 23, 50, 0),
//         end: new Date(2024, 1, 6, 13, 0, 0),
//         resourceId: 2,
//     },
//     {
//         id: 14,
//         title: 'Board meeting',
//         start: new Date(2024, 1, 5, 23, 40, 0),
//         end: new Date(2024, 1, 6, 13, 0, 0),
//         resourceId: 4,
//     },
// ]

// export default Citas;