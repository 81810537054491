import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../content/appContext';
import Select from 'react-select';

import CheckIcon from '../../static/icons/checkIcon.png';
import BorrarIcon from '../../static/icons/borrarIcon.png';

const TablaCero = () => {

    const { store, actions } = useContext(Context);

    // Variables ComboBox
    const [optionsPrestaciones, setOptionsPrestaciones] = useState([]);
    const [selectedOptionPrestaciones, setSelectedOptionPrestaciones] = useState(store.codigoPrestacion);
    // Variable Valorización
    const [valorPrestacion, setValorPrestacion] = useState(0);
    // Variables Tabla
    // const [datosTabla, setDatosTabla] = useState([{ grupo: false, codigo: (store.fichaAtencion ? selectedOptionPrestaciones : ''), precio: valorPrestacion, cantidad: '1', seleccionado: false }]);
    const [datosTabla, setDatosTabla] = useState([{ grupo: false, codigo: 0, precio: 0, cantidad: 1, seleccionado: false }]);

    //! Creacion de la lista de precios
    const dataRespondeValorizacion = [];
    const [ estadoPromesa, setEstadoPromesa ] = useState(false);

    // useEffect(() => {
    // Utiliza Promise.all para esperar a que todas las Promesas se resuelvan
    const dataNueva = Promise.all(store.prestacionServicioCentroPrueba.map(async (prestacion, index) => {
        // Construye el cuerpo de la solicitud utilizando la función del flux
        const body = {
            // "cod_centro": Math.floor(parseFloat(store.centroCaja)),
            "cod_centro": 9,
            // "cod_servicio": Math.floor(parseFloat(store.servicioCaja)),
            "cod_servicio": 15,
            // "rut_clinica": Math.floor(parseFloat(store.rutClinica)),
            "rut_clinica": 777427817,
            "rut_tratante": 0,
            "ambiente": "A",
            "codigo_prestacion": Math.floor(parseFloat(prestacion.prS_CODIGO)),
            "nombre_prestacion": prestacion.prS_DESCRIPCION,
            "cod_interv_quirur": Math.floor(parseFloat(prestacion.iD_INT_QUIR)),
            "cod_homologo": Math.floor(parseFloat(prestacion.prS_CODIGO_HOMOLOGO)),
            "codigo_mm": prestacion.prS_CODIGO_MM,
            "cod_item": (prestacion.itM_CODIGO === null) ? 0 : prestacion.itM_CODIGO,
            "codigo_tipo_factura_X_componente": Math.floor(parseFloat(prestacion.prS_FACTURA_POR_GRUPO_COMPONENTE)),
            "codigo_tp": Math.floor(parseFloat(prestacion.tP_CODIGO)),
            "descripcion_tp": prestacion.prS_NOMBRE_BREVE,
            "codigo_sub_tp": Math.floor(parseFloat(prestacion.stP_CODIGO)),
            "descripcion_sub_tp": prestacion.stP_DESCRIPCION,
            "codprevisionpac": (store.citaAgendada.prevision ? Math.floor(parseFloat(store.citaAgendada.prevision)) : "50"),
            "codprevisionPart": store.codPrevisionPart,
            "cod_convenio": store.codConvenio,
            "fecha_valorizacion": new Date().toISOString(),
            "lst_componentes": [
                {
                    "_tp_codigo": Math.floor(parseFloat(prestacion.tP_CODIGO)),
                    "_sub_tp_codigo": Math.floor(parseFloat(prestacion.stP_CODIGO)),
                    "_codigo": Math.floor(parseFloat(prestacion.gtP_CODIGO)),
                    "_descripcion": prestacion.p_DESCRIPCION,
                    "_item": {
                        "_codigo": (prestacion.itM_CODIGO === null) ? 0 : prestacion.itM_CODIGO,
                        "_descripcion": prestacion.itM_DESCRIPCION
                    },
                    "_muestra": {
                        "_codigo": (prestacion.mT_CODIGO === null) ? 0 : prestacion.mT_CODIGO,
                        "_descripcion": prestacion.mT_DESCRIPCION
                    },
                    // "_codigo_intervencion_quirurg": Math.floor(parseFloat(store.codIntervQuirur)),
                    "_codigo_intervencion_quirurg": 0,
                    "_codigo_homologo": Math.floor(parseFloat(prestacion.prS_CODIGO_HOMOLOGO)),
                    "_nombre_tp": prestacion.prS_DESCRIPCION,
                    "_nombre_sub_tp": prestacion.prS_DESCRIPCION,
                    "_id_prestacion_orden_clinica": 0,
                    "_tipo_divergencia": 0,
                    "_valor_divergente": 0,
                    "_nombre_divergente": "string",
                    "_ID_RESERVA_PABELLON": 0
                }
            ],
            "cod_descuento": false,
            "con_recargo": false,
            "tipo_divergencia": 0,
            "valor_divergente": 0,
            "nombre_divergente": "string",
            "item": {
                "_codigo": 0,
                "_descripcion": "null"
            },
            "muestra": {
                "_codigo": 0,
                "_descripcion": "null"
            },
            "iD_DETALLE_ORDEN_CLINICA": 0
        }
        try {
            // Realiza la solicitud POST utilizando la función del flux
            const response = await fetch('http://10.40.20.13:5105/api/Agenda/valorizaproducto', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Verifica si la respuesta es exitosa
            if (response.ok) {
                const data = await response.json();
                // Extrae el precio de la respuesta y devuélvelo junto con otros datos
                // console.log(data)
                dataRespondeValorizacion.push({
                    codigo: data[0]._obj_prestacion._codigo,
                    descripcion: data[0]._obj_prestacion._descripcion,
                    precio: data[0]._valor_lista
                })

            } else {
                throw new Error('Error al obtener el precio');

            }
        } catch (error) {
            console.error('Error en la solicitud POST:', error);
            // Maneja el error como consideres apropiado
            return {
                value: prestacion.prS_CODIGO,
                label: `${Math.floor(parseFloat(prestacion.prS_CODIGO))} - ${prestacion.prS_DESCRIPCION}`,
                precio: 'hola' // Otra acción si hay un error
            };
        }
        setEstadoPromesa(true);
    }
    ));
    // }, [])

    const [ promesaCumplida, setPromesaCumplida ] = useState([]);

    useEffect(() => {
        dataNueva.then(() => {
        
            // console.log(dataRespondeValorizacion);
            setPromesaCumplida(dataRespondeValorizacion);
            
        }).catch(error => {
            console.error('Error al completar todas las solicitudes POST:', error);
        });
    }, [estadoPromesa])
    
    // console.log(dataRespondeValorizacion.length);
    // console.log(dataRespondeValorizacion[0]);
    useEffect(() => {
        console.log(promesaCumplida);
    }, [promesaCumplida])

    //! Manipulacion de la tabla
    const agregarFila = () => {
        setDatosTabla([...datosTabla, { grupo: false, codigo: 0, precio: 0, cantidad: 1, seleccionado: false }]);
    };

    const handleCheckboxChangeGrupo = (indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, grupo: !fila.grupo };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleCheckboxChangeSeleccionado = (indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, seleccionado: !fila.seleccionado };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleChangePrecio = (indice, precio, valor) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, precio: ((store.valorizacion.length > 0) ? store.valorizacion[0]._valor_lista : valor) };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleChangeCantidad = (indice, cantidad, valor) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, cantidad: valor };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const eliminarFila = (indice) => {
        const nuevasFilas = datosTabla.filter((fila, idx) => idx !== indice);
        setDatosTabla(nuevasFilas);
    };

    // Manipulacion del ComboBox en Tabla

    useEffect(() => {
        setOptionsPrestaciones(store.prestacionServicioCentroPrueba.map(option => ({ value: option.prS_CODIGO, label: `${Math.floor(parseFloat(option.prS_CODIGO))} - ${option.prS_DESCRIPCION}` })));
    }, [store.prestacionServicioCentroPrueba]);

    // const handleChangeLocalPrestaciones = (selectedOptionPrestaciones, indice) => {
    //     const nuevasFilas = datosTabla.map((fila, idx) => {
    //         if (idx === indice) {
    //             return { ...fila, codigo: selectedOptionPrestaciones.value };
    //         }
    //         return fila;
    //     });
    //     setDatosTabla(nuevasFilas);


    //     // actions.postValorizarProducto();
    // };

    // let handleChangeLocalPrestaciones;

    // useEffect(() => {
    const handleChangeLocalPrestaciones = (selectedOptionPrestaciones, indice) => {
        const codigoPrestacion = selectedOptionPrestaciones.value;

        // console.log(codigoPrestacion)
        // console.log(typeof(codigoPrestacion))

        // console.log(dataRespondeValorizacion[0].codigo)
        // console.log(typeof(dataRespondeValorizacion[0].codigo))

        // Buscar el precio correspondiente en dataRespondeValorizacion
        const precioPrestacion = dataRespondeValorizacion.find(item => item.codigo === Math.floor(parseFloat(codigoPrestacion)))?.precio || 0;

        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, codigo: codigoPrestacion, precio: precioPrestacion };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };
    // }, [dataRespondeValorizacion])

    // console.log(datosTabla);


    //# Condicional para definir la informacion que viene en la prestacion
    //# El primer condicional es cuando hago click en una cita Agendada existente y
    //# el segundo condicional es cuando no tengo cita agendada y sea espontanea.

    let descripcionCompleta;

    if (store.fichaAtencion === '') {
        // console.log("Sin FA");
        descripcionCompleta = store.prestacionServicioCentroPrueba.find(prestacion => prestacion.prS_CODIGO === datosTabla[0].codigo) || '';
    } else {
        // console.log("Con FA");
        descripcionCompleta = store.prestacionServicioCentroPrueba.find(prestacion => prestacion.prS_CODIGO === store.codigoPrestacion) || '';
    }

    //! Si cambia store.valorizacion (postValor... es 200) se carga el valor en valorPrestacion
    // useEffect(() => {
    //     if (store.valorizacion.length > 0) {
    //         setValorPrestacion(store.valorizacion[0]._valor_lista);
    //         // console.log(store.valorizacion[0]._valor_lista)
    //     } else {
    //         setValorPrestacion(0);
    //     }
    // }, [store.valorizacion])

    useEffect((indice) => {
        if (store.valorizacion.length > 0) {
            handleChangePrecio(indice, store.valorizacion[0]._valor_lista)
            console.log("valorizó");
        } else {
            return;
        }
    }, [store.valorizacion])


    //! Si cambia store.citaAgendada (Hice click en una agenda), se carga la informacion en SelectedOptionPrestaciones
    useEffect(() => {
        if (store.citaAgendada.length > 0) {
            setSelectedOptionPrestaciones(store.citaAgendada[0].prestacion);
        } else {
            setSelectedOptionPrestaciones('');
        }
    }, [store.citaAgendada]);

    // TODO Me llevo datosTabla al flux en una variable global en flux llamada datosPrestacion
    useEffect(() => {
        actions.handleChangeEspecialPrestacion(datosTabla);
    }, [datosTabla]);

    // TODO Me llevo toda la informacion de la prestacion a su correspondiente variable global en flux
    useEffect(() => {
        actions.setInfoPrestacion(descripcionCompleta);
    }, [descripcionCompleta]);

    // Efecto para cargar las prestaciones desde un comienzo sin otras dependencias
    useEffect(() => {
        actions.getPrestacionServicioCentroPrueba();
    }, [])

    // console.log(dataNueva);
    // console.log(store.prestacionServicioCentroPrueba);

    return (
        <div className="container">
            <h3>Tabla Cero</h3>
            <div className="tabla">

                {
                    (promesaCumplida.length > 0) ? (
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Grupo</th>
                                    <th>Prestación</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Particular</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datosTabla.map((fila, indice) => {
                                        return (
                                            <tr key={indice}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={fila.grupo}
                                                        onChange={() => handleCheckboxChangeGrupo(indice)}
                                                    />
                                                </td>
                                                {/* //! AQUI ESTOY TRABAJANDO!!!!
                                                //? TABLA DINAMICA PRESTACIONES
                                                //# AQUI ESTOY TRABAJANDO!!!! */}
                                                <td>
                                                    <Select
                                                        className="comboBoxPrestaciones"
                                                        options={optionsPrestaciones}
                                                        onChange={(selectedOptionPrestaciones) => { handleChangeLocalPrestaciones(selectedOptionPrestaciones, indice) }}
                                                        value={optionsPrestaciones.find(option => option.value === fila.codigo)}
                                                        menuPortalTarget={document.body}
                                                        placeholder="Prestaciones"
                                                    // isClearable
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={fila.precio}
                                                        onChange={(e) => handleChangePrecio(indice, 'precio', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={fila.cantidad}
                                                        onChange={(e) => handleChangeCantidad(indice, 'cantidad', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={fila.seleccionado}
                                                        onChange={() => handleCheckboxChangeSeleccionado(indice)}
                                                    />
                                                </td>
                                                <td>
                                                    {
                                                        indice === datosTabla.length - 1 && (
                                                            <button className="btn btn-success" onClick={agregarFila}><img className="icon whiteIcon" src={CheckIcon} alt="Agregar Fila" /></button>
                                                        )
                                                    }
                                                    {
                                                        indice !== datosTabla.length - 1 && (
                                                            <button className="btn btn-warning" onClick={() => eliminarFila(indice)}><img className="icon blackIcon" src={BorrarIcon} alt="Eliminar Fila" /></button>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    ) 
                    : (
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando</span>
                </div>
                )
                }

            </div>
        </div>
    );
}

export default TablaCero;