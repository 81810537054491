import React from 'react';

import '../styles/footer.css';

const Footer = () => {
    return(
        <footer>
            <div className="CliniColor"></div>
        </footer>
      
    );
}

export default Footer;