const getState = ({ getStore, getActions, setStore }) => {
    return {
        store: {
            //! STORE GLOBAL
            mainPath: 'http://10.40.20.13:5105/api/',
            mainPath2: 'http://10.40.20.13:5108/api/',
            mainPath3: 'http://10.40.20.11:5105/api/',
            eleccionBusqueda: 'rut',
            usuario: 'OCASTRO',
            estadoAgenda: 'disponibles',
            pcxnmb: [],
            fechaInicial: new Date().toISOString().slice(0, 10),
            fechaCaja: new Date().toISOString(),
            esperando: false,
            errors: [],

            //! getAseguradoras
            aseguradoras: [],

            //! getTipoDocumento
            tipoDocto: '',

            //! getPacientePorDocto
            pacientePorDocto: [],
            docto: '',
            tipoDoc: 'RU',

            //! getPacientePorNombre
            pacientePorNombre: [],
            nombre: '',
            ap_paterno: '',
            ap_materno: '',
            pacienteInfo: [],

            //! getSexo
            tipoSexo: [],

            //! getEstadosCiviles
            tipoEstadosCiviles: [],

            //! getEtnias
            tipoEtnias: [],

            //! getPaises
            nombrePais: [],
            paisElegido: '',

            //! getComunas
            nombreComunas: [],
            comunaElegida: '',

            //! getInstitucionesPrevisionales
            institucionesPrevisionales: [],
            prevision: '',

            //! getServiciosClinicos
            serviciosClinicos: [],
            servicio: '',

            //! getCentros
            centros: [],
            centro: '',

            //! getEspecialidades
            especialidades: [],
            especialidad: '',

            //! getProfesionales
            profesionales: [],
            profesional: '',

            //! getPrestaciones
            prestaciones: [],
            prestacion: '',

            //! getDisponibilidadFiltrada
            disponibilidadesFiltradas: '',

            // VARIABLES GLOBALES CAJA
            cajaBusquedaRut: '',

            //! getCentroCaja
            centrosCaja: [],
            centroCaja: '',
            infoCentroCompleto: [],
            rutClinica: '',

            //! getServiciosCaja
            serviciosCaja: [],
            servicioCaja: '',

            //! getEspecialidadCaja
            especialidadesCaja: [],
            especialidadCaja: '',

            //! getCitasAgendadas
            citasAgendadas: [],
            citaAgendada: [],
            codigoPrestacion: [],
            fichaAtencion: 0,

            //! handleChangeEspecialPrestacion
            datosPrestacion: [],

            //! getPrestacionServicioCentro
            prestacionesServicioCentro: [],
            prestacionSerCen: [],
            prestacionServicioCentroPrueba: [],

            //! getMedicoTratante
            rutMedicoCDV: '',
            infoMedico: '',
            nombreMedico: '',
            apPatMed: '',
            apMatMed: '',
            rutMedicoSDV: '',
            rutMedicoDV: '',

            //! getMedicosTratantesCompleto
            medicosTratantesCompleto: [],
            medicoTratanteFull: '',

            //! getMedicosDerivadoresCompleto
            medicosDerivadores: [],
            medicoDerivador: '',

            //! postAgregarPaciente (21 variables = 5 preexistentes + 5 fijas + 1 reutilizada + 10 nuevas)
            //? tipoDoc: '', --> getPacientePorDocto
            //? docto: '', --> getPacientePorDocto
            //? usuario: 'OCASTRO', --> Store Global
            //? paisElegido: '', --> getPaises
            //? comunaElegida: '', --> getComunas
            nombrePaciente: '',
            apPaternoPaciente: '',
            apMaternoPaciente: '',
            celularPaciente: '',
            emailPaciente: '',
            fecNacPaciente: '',
            sexoPaciente: '',
            estCivilPaciente: '',
            direccionPaciente: '',
            etniaPaciente: '',
            nombreSocial: '',

            //! handleChangeEspecialCaja
            fechaCita: '',

            //! postModificarPaciente
            //? Variables en postAgregarPaciente más estas
            codIdent: '',
            datosTabla: [],

            //! postValorizarProducto
            // cod_centro: 29, --> Existe como "centro"
            // cod_servicio: 1, --> Existe como "servicio"
            // rutClinica: 0, --> Existe como "rutClinica"
            // rutTratante: 0, --> Existe como "rutMedicoCDV"
            ambiente: 'A', // Parametro queda Fijo A = Ambulario, H = Hospitalario y U = Urgencia
            // codigoPrestacion: null, -- Existe como codigoPrestacion
            nombrePrestacion: '',
            codIntervQuirur: '',
            codHomologo: '',
            codigoMm: '',
            codItem: '',
            codigoTipoFacturaXComponente: '',
            codigoTp: '',
            descripcionTp: '',
            codigoSubTp: '',
            descripcionSubTp: '',
            codPrevisionPac: '',
            codPrevisionPart: '',
            codConvenio: '',
            fechaValorizacion: "", // Now() -> 2024-03-14T23:50:41.683Z
            tpCodigo: '',
            subTpCodigo: '',
            gtpCodigo: '',
            pDescripcion: '',
            itemCodigo: '',
            itemDescripcion: '',
            muestraCodigo: '',
            muestraDescripcion: '',
            valorizacion: [],

            // getComponentesPrestacion
            componentesPrestacion: [],

            // API PRUEBA --> Sin acceso a la red
            apiPrueba: [],
            dataRespondeValorizacion: [],



        },
        actions: {
            // ! ---------------
            // ! FUNCIONALIDADES GLOBALES Y/O ESPECIALES
            // ! ---------------

            handleChange: e => {
                e.preventDefault(e);
                setStore({
                    [e.target.name]: e.target.value
                })
            },

            handleChangeInputs: e => {
                setStore({
                    [e.target.name]: e.target.value
                })
            },

            handleChangeEspecial: pcxnmb => {
                setStore({
                    pacienteInfo: pcxnmb,
                    codIdent: pcxnmb.pgL_IDENT,
                    paisElegido: pcxnmb.pS_CODIGO,
                    comunaElegida: pcxnmb.coM_CODIGO,
                    docto: pcxnmb.pgL_NRO_DOCUMENTO,
                    tipoDoc: pcxnmb.pgL_TIPO_DOCUMENTO,
                    apPaternoPaciente: pcxnmb.pgL_PATERNO,
                    apMaternoPaciente: pcxnmb.pgL_MATERNO,
                    nombrePaciente: pcxnmb.pgL_NOMBRES,
                    fecNacPaciente: pcxnmb.pgL_FEC_NACIM,
                    sexoPaciente: pcxnmb.pgL_SEXO,
                    direccionPaciente: pcxnmb.pgL_DIRECCION,
                    celularPaciente: pcxnmb.pgL_CELULAR,
                    estCivilPaciente: pcxnmb.pgL_ESTCIVIL,
                    emailPaciente: pcxnmb.pgL_EMAIL,
                    etniaPaciente: pcxnmb.pgL_ETNIA
                })
            },

            getRutClinica: () => {
                const store = getStore();
                const centroCompleto = store.centrosCaja.find(centro => centro.ceN_IDENT === store.centroCaja) || '';

                if (store.rutClinica) {
                    return; // Evitar ejecuciones innecesarias
                }
                setStore({
                    infoCentroCompleto: centroCompleto,
                    rutClinica: centroCompleto.ceN_RUT,
                })
            },

            handleChangeEspecialCaja: citaAgendada => {
                const store = getStore();
                const actions = getActions();

                setStore({
                    citaAgendada: citaAgendada,
                    codIdent: citaAgendada.pgL_IDENT,
                    paisElegido: citaAgendada.pS_CODIGO,
                    comunaElegida: citaAgendada.coM_CODIGO,
                    docto: citaAgendada.pgL_NRO_DOCUMENTO,
                    tipoDoc: citaAgendada.pgL_TIPO_DOCUMENTO,
                    apPaternoPaciente: citaAgendada.pgL_PATERNO,
                    apMaternoPaciente: citaAgendada.pgL_MATERNO,
                    nombrePaciente: citaAgendada.pgL_NOMBRES,
                    fecNacPaciente: citaAgendada.pgL_FEC_NACIM,
                    sexoPaciente: citaAgendada.pgL_SEXO,
                    direccionPaciente: citaAgendada.pgL_DIRECCION,
                    celularPaciente: citaAgendada.pgL_CELULAR,
                    estCivilPaciente: citaAgendada.pgL_ESTCIVIL,
                    emailPaciente: citaAgendada.pgL_EMAIL,
                    etniaPaciente: citaAgendada.pgL_ETNIA,
                    fechaCita: citaAgendada.fechA_CITA,
                    codigoPrestacion: citaAgendada.prS_CODIGO,
                    rutMedicoCDV: ((citaAgendada.ruT_MEDICO) === null ? 0 : citaAgendada.ruT_MEDICO),
                    // rutMedicoDV: rutMod2,
                    fichaAtencion: citaAgendada.fA_ID,
                })

                // actions.setInfoPrestacion();
                // actions.postValorizarProducto();

            },

            handleChangeEspecialPrestacion: datosTabla => {
                // console.log(datosTabla[0].codigo);
                setStore({ 
                    datosPrestacion: datosTabla,
                    // codigoPrestacion: datosTabla[0].codigo,

                });
            },

            setRutCaja: rut => {
                setStore({
                    docto: rut,
                })
            },

            setInfoPrestacion: descripcionCompleta => {
                const store = getStore();
                const actions = getActions();
                setStore({
                    prestacionSerCen: descripcionCompleta,
                    rutClinica: store.rutClinica,
                    codigoPrestacion: descripcionCompleta.prS_CODIGO,
                    nombrePrestacion: descripcionCompleta.prS_DESCRIPCION,
                    codIntervQuirur: Math.floor(parseFloat(descripcionCompleta.iD_INT_QUIR)),
                    codHomologo: Math.floor(parseFloat(descripcionCompleta.prS_CODIGO_HOMOLOGO)),
                    codigoMm: descripcionCompleta.prS_CODIGO_MM,
                    codItem: descripcionCompleta.itM_CODIGO,
                    codigoTipoFacturaXComponente: Math.floor(parseFloat(descripcionCompleta.prS_FACTURA_POR_GRUPO_COMPONENTE)),
                    codigoTp: Math.floor(parseFloat(descripcionCompleta.tP_CODIGO)),
                    descripcionTp: descripcionCompleta.prS_NOMBRE_BREVE,
                    codigoSubTp: Math.floor(parseFloat(descripcionCompleta.stP_CODIGO)),
                    descripcionSubTp: descripcionCompleta.stP_DESCRIPCION,
                    codPrevisionPac: (store.citaAgendada.prevision ? Math.floor(parseFloat(store.citaAgendada.prevision)) : "50"),
                    codPrevisionPart: "50",
                    codConvenio: 0,
                    fechaValorizacion: new Date().toISOString(), // Now() -> 2024-03-14T23:50:41.683Z
                    tpCodigo: descripcionCompleta.tP_CODIGO,
                    subTpCodigo: descripcionCompleta.stP_CODIGO,
                    gtpCodigo: descripcionCompleta.gtP_CODIGO,
                    pDescripcion: descripcionCompleta.p_DESCRIPCION,
                    itemCodigo: descripcionCompleta.itM_CODIGO,
                    itemDescripcion: descripcionCompleta.itM_DESCRIPCION,
                    muestraCodigo: descripcionCompleta.mT_CODIGO,
                    muestraDescripcion: descripcionCompleta.mT_DESCRIPCION,
                })

                //# ESTE ES EL QUE VALORIZA AUTOMATICAMENTE AL INICIAR EL COMPONENTE CAJA
                actions.postValorizarProducto();
            },

            stopWaiting: () => {
                setStore({
                        esperando: false,
                    })
            },

            refreshPage: () => {
                window.location.reload();
            },

            resetVars: (e) => {
                // console.log("Se ejecuto resetVars");
                setStore({
                    codIdent: '',
                    paisElegido: '',
                    comunaElegida: '',
                    docto: '',
                    tipoDoc: '',
                    apPaternoPaciente: '',
                    apMaternoPaciente: '',
                    nombrePaciente: '',
                    fecNacPaciente: '',
                    sexoPaciente: '',
                    direccionPaciente: '',
                    celularPaciente: '',
                    estCivilPaciente: '',
                    emailPaciente: '',
                    etniaPaciente: '',
                })
            },

            resetVarsCaja: (e) => {
                // console.log("Se ejecuto resetVars");
                setStore({
                    codIdent: '',
                    paisElegido: '',
                    comunaElegida: '',
                    // docto: '',
                    tipoDoc: '',
                    apPaternoPaciente: '',
                    apMaternoPaciente: '',
                    nombrePaciente: '',
                    fecNacPaciente: '',
                    sexoPaciente: '',
                    direccionPaciente: '',
                    celularPaciente: '',
                    estCivilPaciente: '',
                    emailPaciente: '',
                    etniaPaciente: '',
                    fechaCita: '',
                    nombreMedico: '',
                    apPatMed: '',
                    apMatMed: '',
                    rutMedicoSDV: '',
                })
            },

            setPacientePorNombre: (pgL_IDENT,
                pS_CODIGO,
                coM_CODIGO,
                pgL_NRO_DOCUMENTO,
                pgL_TIPO_DOCUMENTO,
                pgL_PATERNO,
                pgL_MATERNO,
                pgL_NOMBRES,
                pgL_FEC_NACIM,
                pgL_SEXO,
                pgL_DIRECCION,
                pgL_CELULAR,
                pgL_ESTCIVIL,
                pgL_EMAIL,
                pgL_VIGENTE,
                pgL_ETNIA) => {
                setStore({
                    pgL_IDENT,
                    pS_CODIGO,
                    coM_CODIGO,
                    pgL_NRO_DOCUMENTO,
                    pgL_TIPO_DOCUMENTO,
                    pgL_PATERNO,
                    pgL_MATERNO,
                    pgL_NOMBRES,
                    pgL_FEC_NACIM,
                    pgL_SEXO,
                    pgL_DIRECCION,
                    pgL_CELULAR,
                    pgL_ESTCIVIL,
                    pgL_EMAIL,
                    pgL_VIGENTE,
                    pgL_ETNIA
                })
            },

            getPrueba: () => {
                fetch('https://jsonplaceholder.typicode.com/users', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data)
                        setStore({
                            apiPrueba: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },



            // ! ---------------
            // ! INICIO GETS
            // ! ---------------
            // ? ---------------
            // ? DATOS MAESTROS
            // ? ---------------



            getAseguradoras: async () => {

                const store = getStore();
                const urlGetAseguradoras = store.main + "InstitucionesPrev/listar";

                await fetch(urlGetAseguradoras, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data)
                        setStore({
                            aseguradoras: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },



            // ? ---------------
            // ? AGENDA INTERNA - FILTROS
            // ? ---------------



            getCentros: async () => {
                const store = getStore();
                const urlGetCentros = store.mainPath2 + "Agenda/Get_CentrosAgendables";
                // console.log(urlGetCentros);

                await fetch(urlGetCentros, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            centros: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getServiciosClinicos: async () => {
                const store = getStore();
                const urlGetServiciosClinicos = store.mainPath2 + "Agenda/Get_TiposObjetos";

                await fetch(urlGetServiciosClinicos, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        setStore({
                            serviciosClinicos: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
                // console.log(store.serviciosClinicos);
            },

            getEspecialidades: async () => {
                const store = getStore();
                const urlGetEspecialidades = store.mainPath2 + "Agenda/Get_EspAgendables_X_TipoObjeto?codTipObjeto=" + store.servicio;

                await fetch(urlGetEspecialidades, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            especialidades: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getInstitucionesPrevisionales: async () => {

                const store = getStore();
                const urlGetInstitucionesPrevisionales = store.mainPath + "Caja/Get_InstPrevisionales";

                await fetch(urlGetInstitucionesPrevisionales, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            institucionesPrevisionales: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getProfesionales: async () => {
                const store = getStore();
                const urlGetProfesionales = store.mainPath2 + "Agenda/Get_ProfMedAgend_X_ProfPrevision?tIPOoBJETO=" + store.servicio + "&CodEspecialidad=" + store.especialidad + "&CodPrevision=" + Math.floor(parseFloat(store.prevision));

                await fetch(urlGetProfesionales, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            profesionales: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getPrestaciones: async () => {
                const store = getStore();
                const urlGetPrestacion = store.mainPath2 + "Agenda/Get_Prestaciones_X_Objeto?pCodTipoObjeto=" + store.servicio + "&pCodEsp=" + store.especialidad + "&pPrfIdent=" + store.profesional;

                await fetch(urlGetPrestacion, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            prestaciones: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getDisponibilidades: async () => {
                const store = getStore();
                const urlGetDisponibilidad = store.mainPath2 + "Agenda/GetDisponibilidad?centro=" + Math.floor(parseFloat(store.centro)) + "&tipObjeto=" + store.servicio + "&esp=" + store.especialidad + "&idProf=" + store.profesional + "&codPrevision=" + Math.floor(parseFloat(store.prevision)) + "&codPrestacion=" + store.prestacion;

                // console.log(store.centro);
                console.log(urlGetDisponibilidad);

                try {
                    const response = await fetch(urlGetDisponibilidad, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setStore({ disponibilidadesFiltradas: data });
                    // console.log(disponibilidadesFiltradas);
                } catch (error) {
                    console.error('There was an error fetching data:', error);
                }
            },



            // ? ---------------
            // ? AGENDA INTERNA - PACIENTE Y SUS VARIABLES
            // ? ---------------



            getTipoDocumento: async () => {

                const store = getStore();
                const urlGetTipoDocumento = store.mainPath + "Global/BuscarTiposDocumentos";

                await fetch(urlGetTipoDocumento, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data)
                        setStore({
                            tipoDocto: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getSexo: async () => {

                const store = getStore();
                const urlGetSexo = store.mainPath + "Global/BuscarSexo";

                await fetch(urlGetSexo, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            tipoSexo: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getEstadoCivil: async () => {

                const store = getStore();
                const urlGetEstadoCivil = store.mainPath + "Global/BuscarEstadosCiviles";

                await fetch(urlGetEstadoCivil, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            tipoEstadosCiviles: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getEtnias: async () => {

                const store = getStore();
                const urlGetEtnia = store.mainPath + "Global/BuscarEtnias";
                
                await fetch(urlGetEtnia, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            tipoEtnias: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getPaises: async () => {

                const store = getStore();
                const urlGetPaises = store.mainPath + "Global/BuscarPaises";
                
                await fetch(urlGetPaises, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            nombrePais: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getComunaPorPais: async () => {
                const store = getStore();
                const urlComunaPorPais = store.mainPath + 'Global/BuscarComunas?codigo_pais=213';
                // + store.paisElegido;
                // console.log("urlComuna: " + urlComunaPorPais);

                await fetch(urlComunaPorPais, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            nombreComunas: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getPacientePorDocto: async (e) => {
                e.preventDefault(e);
                const store = getStore();
                // const actions = getActions();
                const urlGetPacientePorDocto = store.mainPath + 'Paciente/BuscarPacienteXDocto?docto=' + store.docto;

                await fetch(urlGetPacientePorDocto, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        if (!data || store.docto === '') {
                            setStore({
                                codIdent: '',
                                paisElegido: '',
                                comunaElegida: '',
                                docto: '',
                                tipoDoc: '',
                                apPaternoPaciente: '',
                                apMaternoPaciente: '',
                                nombrePaciente: '',
                                fecNacPaciente: '',
                                sexoPaciente: '',
                                direccionPaciente: '',
                                celularPaciente: '',
                                estCivilPaciente: '',
                                emailPaciente: '',
                                etniaPaciente: '',
                                nombreSocial: ''
                            })
                        } else {
                            setStore({
                                pacientePorDocto: data,
                                codIdent: data[0].pgL_IDENT,
                                paisElegido: data[0].pS_CODIGO,
                                comunaElegida: data[0].coM_CODIGO,
                                docto: data[0].pgL_NRO_DOCUMENTO,
                                tipoDoc: data[0].pgL_TIPO_DOCUMENTO,
                                apPaternoPaciente: data[0].pgL_PATERNO,
                                apMaternoPaciente: data[0].pgL_MATERNO,
                                nombrePaciente: data[0].pgL_NOMBRES,
                                fecNacPaciente: data[0].pgL_FEC_NACIM,
                                sexoPaciente: data[0].pgL_SEXO,
                                direccionPaciente: data[0].pgL_DIRECCION,
                                celularPaciente: data[0].pgL_CELULAR,
                                estCivilPaciente: data[0].pgL_ESTCIVIL,
                                emailPaciente: data[0].pgL_EMAIL,
                                etniaPaciente: data[0].pgL_ETNIA,
                                nombreSocial: data[0].nombre_social
                            })
                        }

                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getPacientePorNombre: async (e) => {
                e.preventDefault();
                const store = getStore();
                const baseUrl = store.mainPath + 'Paciente/BuscarPacienteNombres?nombre=' + store.nombre + "&ap_paterno=" + store.ap_paterno + "&ap_materno=" + store.ap_materno;
                console.log(store.nombre);
                console.log(store.ap_paterno);
                console.log(store.ap_materno);
                await fetch(baseUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        if (data) {
                            setStore({
                                pacientePorNombre: data,
                                codIdent: data[0].pgL_IDENT,
                                paisElegido: data[0].pS_CODIGO,
                                comunaElegida: data[0].coM_CODIGO,
                                docto: data[0].pgL_NRO_DOCUMENTO,
                                tipoDoc: data[0].pgL_TIPO_DOCUMENTO,
                                apPaternoPaciente: data[0].pgL_PATERNO,
                                apMaternoPaciente: data[0].pgL_MATERNO,
                                nombrePaciente: data[0].pgL_NOMBRES,
                                fecNacPaciente: data[0].pgL_FEC_NACIM,
                                sexoPaciente: data[0].pgL_SEXO,
                                direccionPaciente: data[0].pgL_DIRECCION,
                                celularPaciente: data[0].pgL_CELULAR,
                                estCivilPaciente: data[0].pgL_ESTCIVIL,
                                emailPaciente: data[0].pgL_EMAIL,
                                etniaPaciente: data[0].pgL_ETNIA,
                                paisElegido: data[0].pS_CODIGO,
                                nombreSocial: data[0].nombre_social,
                            })
                        } else {
                            setStore({
                                codIdent: '',
                                paisElegido: '',
                                comunaElegida: '',
                                docto: '',
                                tipoDoc: '',
                                apPaternoPaciente: '',
                                apMaternoPaciente: '',
                                nombrePaciente: '',
                                fecNacPaciente: '',
                                sexoPaciente: '',
                                direccionPaciente: '',
                                celularPaciente: '',
                                estCivilPaciente: '',
                                emailPaciente: '',
                                etniaPaciente: '',
                                nombreSocial: ''
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },



            // ? ---------------
            // ? CAJA - FILTROS
            // ? ---------------



            getCentrosCaja: async () => {
                const store = getStore();
                const urlGetCentrosCaja = store.mainPath + "Caja/Get_Centros?usuario=" + store.usuario;

                await fetch(urlGetCentrosCaja, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            centrosCaja: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getServiciosCaja: async () => {
                const store = getStore();
                const urlGetServiciosCaja = store.mainPath + "Caja/GetServiciosClinicosUsuario?usuario=" + store.usuario + "&centro=" + Math.floor(parseFloat(store.centroCaja));

                await fetch(urlGetServiciosCaja, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            serviciosCaja: data,
                            esperando: true
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getEspecialidadCaja: async () => {
                const store = getStore();
                const urlGetEspecialidadCaja = store.mainPath + "Caja/Get_Especialidades";

                await fetch(urlGetEspecialidadCaja, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            especialidadesCaja: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getCitasAgendadas: async () => {
                const store = getStore();

                // Obtener la fecha de hoy en formato AAAAMMDD
                const fechaHoyFormat = store.fechaInicial.replace(/-/g, "");
                // console.log("Hoy: " + fechaHoyFormat);

                const today = store.fechaInicial; // Obtiene la fecha de hoy
                const tomorrow = new Date(today); // Crea una nueva fecha a partir de la de hoy
                tomorrow.setDate(tomorrow.getDate() + 1); // Añade un día a la fecha
                const fechaManana = tomorrow.toISOString().slice(0, 10);
                const fechaMananaFormat = fechaManana.replace(/-/g, "");

                // console.log("diaManana: " + fechaMananaFormat);

                // const urlGetCitasAgendadas = store.mainPath + "Agenda/GetCitasMedicas_X_ServicioCentro?dFEC_DESDE=20240305&dFEC_HASTA=20240306&nCOD_SERVICIO=" + store.servicioCaja + "&nCOD_CENTRO=" + Math.floor(parseFloat(store.centroCaja)) + "&nCOD_ESP=" + store.especialidadCaja;
                const urlGetCitasAgendadas = store.mainPath + "Agenda/GetCitasMedicas_X_ServicioCentro?dFEC_DESDE=" + fechaHoyFormat + "&dFEC_HASTA=" + fechaMananaFormat + "&nCOD_SERVICIO=" + Math.floor(parseFloat(store.servicioCaja)) + "&nCOD_CENTRO=" + Math.floor(parseFloat(store.centroCaja)) + "&nCOD_ESP=" + store.especialidadCaja;

                // console.log(urlGetCitasAgendadas);
                await fetch(urlGetCitasAgendadas, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            citasAgendadas: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getPrestacionServicioCentroPrueba: async () => {
                const store = getStore();
                const actions = getActions();
                const urlGetPrestacionServicioCentro = store.mainPath + "Prestacion/Obtener_Prestaciones_servicio_centro?idcentro=" + 9 +"&idservicio=" + 15 + "&tipoproducto=0";
                // const urlGetPrestacionServicioCentro = store.mainPath + "Prestacion/Obtener_Prestaciones_servicio_centro?idcentro=" + store.centroCaja + "&idservicio=" + store.servicioCaja + "&tipoproducto=0";
                // http://10.40.20.13:5105/api/Prestacion/Obtener_Prestaciones_servicio_centro?idcentro=29&idservicio=1&tipoproducto=0&codigo=1&descripcion=c
                // console.log(urlGetPrestacionServicioCentro);
                await fetch(urlGetPrestacionServicioCentro, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            prestacionServicioCentroPrueba: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

                // actions.getMedicoTratante();
            },

            getPrestacionServicioCentro: async () => {
                const store = getStore();
                const actions = getActions();
                const urlGetPrestacionServicioCentro = store.mainPath + "Prestacion/Obtener_Prestaciones_servicio_centro?idcentro=" + Math.floor(parseFloat(store.centroCaja)) +"&idservicio=" + store.servicioCaja + "&tipoproducto=0";
                // const urlGetPrestacionServicioCentro = store.mainPath + "Prestacion/Obtener_Prestaciones_servicio_centro?idcentro=" + store.centroCaja + "&idservicio=" + store.servicioCaja + "&tipoproducto=0";
                // http://10.40.20.13:5105/api/Prestacion/Obtener_Prestaciones_servicio_centro?idcentro=29&idservicio=1&tipoproducto=0&codigo=1&descripcion=c
                // console.log(urlGetPrestacionServicioCentro);
                await fetch(urlGetPrestacionServicioCentro, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.dir(data);
                        setStore({
                            prestacionesServicioCentro: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })

                // actions.getMedicoTratante();
            },

            getMedicoTratante: async () => {
                const store = getStore();

                let rutMedicoSDV;

                if (store.rutMedicoCDV === '') {
                    rutMedicoSDV = '';
                } else {
                    const formatoRutMedico = store.rutMedicoCDV;
                    rutMedicoSDV = formatoRutMedico.slice(0, -2);
                }

                let rutMedicoDV;

                if (store.rutMedicoCDV === '') {
                    rutMedicoDV = '';
                } else {
                    const obtenerDV = store.rutMedicoCDV;
                    rutMedicoDV = obtenerDV.charAt(obtenerDV.length - 1);
                }

                // console.log(rutMedicoSDV);
                const urlGetMedicoTratante = store.mainPath + "Caja/BuscarMedicoPorRut?Rut=" + rutMedicoSDV;
                // console.log(urlGetMedicoTratante);

                await fetch(urlGetMedicoTratante, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            infoMedico: data,
                            rutMedicoSDV: rutMedicoSDV,
                            rutMedicoDV: rutMedicoDV,
                            nombreMedico: data[0].med_nombre,
                            apPatMed: data[0].med_apellido_p,
                            apMatMed: data[0].med_apellido_m,
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getMedicosTratantesCompleto: async () => {
                const store = getStore();
                const urlGetMedicoTratanteCompleto = store.mainPath + "Caja/BuscarMedico?rut=0&nombre=0";
                // http://10.40.20.13:5105/api/Caja/BuscarMedico?rut=0&nombre=0
                // console.log(urlGetMedicoTratanteCompleto);

                

                await fetch(urlGetMedicoTratanteCompleto, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            medicosTratantesCompleto: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getMedicosDerivadoresCompleto: async () => {
                const store = getStore();

                const urlGetMedicosDerivadoresCompleto = store.mainPath + "Caja/BuscarMedicoDerivador?rut=0&nombre=0";

                await fetch(urlGetMedicosDerivadoresCompleto, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            medicosDerivadores: data
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getComponentesPrestacion: async () => {
                const store = getStore();

                const urlGetComponentesPrestacion = store.mainPath + "Prestacion/BuscarComponentesPrestacion?centro=29&servicio=15&prestacion=123123123";
                
                await fetch(urlGetComponentesPrestacion, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        setStore({
                            componentesPrestacion: data,
                            
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },



            // ! ---------------
            // ! INICIO POST
            // ! ---------------



            postAgregarPaciente: async (e) => {
                e.preventDefault();
                const store = getStore();
                const urlModificarPaciente = store.mainPath + "Paciente/CrearPaciente";

                // Modificación de la fecha para ingresarla a la BBDD
                // const fechaIngresada = store.fecNacPaciente;
                // console.log("FI: " + fechaIngresada);
                // const [diaIngresado, mesIngresado, añoIngresado] = fechaIngresada.split('/');
                // const fechaModificada = new Date(`${diaIngresado}-${mesIngresado}-${añoIngresado}`);
                // console.log("FM: " + fechaModificada);
                // const fechaISO = fechaModificada.toISOString();
                // console.log("FISO: " + fechaISO);

                await fetch(urlModificarPaciente, {
                    method: 'POST',
                    body: JSON.stringify({
                        pS_CODIGO: store.paisElegido,
                        coM_CODIGO: store.comunaElegida,
                        pgL_NRO_DOCUMENTO: store.docto,
                        pgL_TIPO_DOCUMENTO: store.tipoDoc,
                        pgL_PATERNO: store.apPaternoPaciente,
                        pgL_MATERNO: store.apMaternoPaciente,
                        pgL_NOMBRES: store.nombrePaciente,
                        pgL_FEC_NACIM: store.fecNacPaciente,
                        pgL_SEXO: store.sexoPaciente,
                        pgL_DIRECCION: store.direccionPaciente,
                        pgL_CODIGO_PAIS_FIJO: '56',
                        pgL_CODIGO_CIUDAD_FIJO: '2',
                        pgL_TELEFONO: store.celularPaciente,
                        pgL_CODIGO_PAIS_MOVIL: '56',
                        pgL_CODIGO_CIUDAD_MOVIL: '9',
                        pgL_CELULAR: store.celularPaciente,
                        pgL_ESTCIVIL: store.estCivilPaciente,
                        pgL_EMAIL: store.emailPaciente,
                        pgL_VIGENTE: '1',
                        pgL_ETNIA: store.etniaPaciente,
                        nombre_social: (store.nombreSocial === null ? '' : store.nombreSocial),
                        usuario: store.usuario
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        console.log(data);
                        if (data.msg) {
                            setStore({
                                errors: data
                            })
                        } else {
                            setStore({
                                tipoDoc: '',
                                docto: '',
                                paisElegido: '',
                                comunaElegida: '',
                                nombrePaciente: '',
                                apPaternoPaciente: '',
                                apMaternoPaciente: '',
                                celularPaciente: '',
                                emailPaciente: '',
                                fecNacPaciente: '',
                                sexoPaciente: '',
                                estCivilPaciente: '',
                                direccionPaciente: '',
                                etniaPaciente: '',
                                nombreSocial: '',
                                errors: ''
                            })
                        }
                    })
                // window.location.reload();
            },

            postModificarPaciente: async (e) => {
                e.preventDefault();
                const store = getStore();
                const urlModificarPaciente = store.mainPath + "Paciente/ModificarPaciente";
                await fetch(urlModificarPaciente, {
                    method: 'POST',
                    body: JSON.stringify({
                        pgL_IDENT: store.codIdent + '',
                        pS_CODIGO: store.paisElegido,
                        coM_CODIGO: store.comunaElegida,
                        pgL_NRO_DOCUMENTO: store.docto,
                        pgL_TIPO_DOCUMENTO: store.tipoDoc,
                        pgL_PATERNO: store.apPaternoPaciente,
                        pgL_MATERNO: store.apMaternoPaciente,
                        pgL_NOMBRES: store.nombrePaciente,
                        pgL_FEC_NACIM: store.fecNacPaciente,
                        pgL_SEXO: store.sexoPaciente,
                        pgL_DIRECCION: store.direccionPaciente,
                        pgL_CODIGO_PAIS_FIJO: '56',
                        pgL_CODIGO_CIUDAD_FIJO: '2',
                        pgL_TELEFONO: store.celularPaciente,
                        pgL_CODIGO_PAIS_MOVIL: '56',
                        pgL_CODIGO_CIUDAD_MOVIL: '9',
                        pgL_CELULAR: store.celularPaciente,
                        pgL_ESTCIVIL: store.estCivilPaciente,
                        pgL_EMAIL: store.emailPaciente,
                        pgL_VIGENTE: '1',
                        pgL_ETNIA: store.etniaPaciente,
                        usuario: store.usuario,
                        nombre_social: (store.nombreSocial === null ? '' : store.nombreSocial),
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        console.log(data);
                        if (data.msg) {
                            // const action = getActions()
                            // if (store.eleccionBusqueda === nombre) {
                            //     action.getPacientePorNombre();
                            // } else if (store.eleccionBusqueda === rut) {
                            //     action.getPacientePorDocto();
                            // } else {
                            //     null
                            // }
                            setStore({
                                errors: data
                            })
                        } else {
                            setStore({
                                errors: null
                            })
                        }
                    })
                // window.location.reload();
            },

            postValorizarProducto: async () => {
                // e.preventDefault();
                const store = getStore();
                const urlValorizarProducto = store.mainPath + "Agenda/valorizaproducto";

                // TODO Cuando una cita agendada es para consulta, viene con un doctor asignado, 
                // TODO pero cuando es Toma de Muestra el doctor viene "NULL" y es el motivo de 
                // TODO la existencia de "rutMod".

                let rutModSDV;
                let rutModDV;

                let rutMedicoModificado;

                if (store.rutMedicoCDV === 0 ) {
                    rutMedicoModificado = 0;
                } else {
                    rutModSDV = store.rutMedicoCDV.slice(0, -2);
                    rutModDV = store.rutMedicoCDV.charAt(store.rutMedicoCDV.length -1);
                    rutMedicoModificado = rutMedicoModificado; // TODO --> Cambiar
                }

                // console.log(rutMedicoModificado);

                const body = {

                    "cod_centro": Math.floor(parseFloat(store.centroCaja)),
                    "cod_servicio": Math.floor(parseFloat(store.servicioCaja)),
                    "rut_clinica": Math.floor(parseFloat(store.rutClinica)),
                    "rut_tratante": rutMedicoModificado,
                    "ambiente": "A",
                    "codigo_prestacion": Math.floor(parseFloat(store.codigoPrestacion)),
                    "nombre_prestacion": store.nombrePrestacion,
                    "cod_interv_quirur": store.codIntervQuirur,
                    "cod_homologo": Math.floor(parseFloat(store.codHomologo)),
                    "codigo_mm": store.codigoMm,
                    "cod_item": (store.codItem === null) ? 0 : store.codItem,
                    "codigo_tipo_factura_X_componente": Math.floor(parseFloat(store.codigoTipoFacturaXComponente)),
                    "codigo_tp": Math.floor(parseFloat(store.codigoTp)),
                    "descripcion_tp": store.descripcionTp,
                    "codigo_sub_tp": Math.floor(parseFloat(store.codigoSubTp)),
                    "descripcion_sub_tp": store.descripcionSubTp,
                    "codprevisionpac": Math.floor(parseFloat(store.codPrevisionPac)),
                    "codprevisionPart": store.codPrevisionPart,
                    "cod_convenio": store.codConvenio,
                    "fecha_valorizacion": store.fechaValorizacion,
                    "lst_componentes": [
                        {
                        "_tp_codigo": Math.floor(parseFloat(store.tpCodigo)),
                        "_sub_tp_codigo": Math.floor(parseFloat(store.subTpCodigo)),
                        "_codigo": Math.floor(parseFloat(store.gtpCodigo)),
                        "_descripcion": store.pDescripcion,
                        "_item": {
                            "_codigo": (store.itemCodigo === null) ? 0 : store.itemCodigo,
                            "_descripcion": store.itemDescripcion
                        },
                        "_muestra": {
                            "_codigo": (store.muestraCodigo === null) ? 0 : store.muestraCodigo,
                            "_descripcion": store.muestraDescripcion
                        },
                        "_codigo_intervencion_quirurg": store.codIntervQuirur,
                        "_codigo_homologo": store.codHomologo,
                        "_nombre_tp": store.nombrePrestacion,
                        "_nombre_sub_tp": store.nombrePrestacion,
                        "_id_prestacion_orden_clinica": 0,
                        "_tipo_divergencia": 0,
                        "_valor_divergente": 0,
                        "_nombre_divergente": "string",
                        "_ID_RESERVA_PABELLON": 0
                        }
                    ],
                    "cod_descuento": false,
                    "con_recargo": false,
                    "tipo_divergencia": 0,
                    "valor_divergente": 0,
                    "nombre_divergente": "string",
                    "item": {
                        "_codigo": 0,
                        "_descripcion": "null"
                    },
                    "muestra": {
                        "_codigo": 0,
                        "_descripcion": "null"
                    },
                    "iD_DETALLE_ORDEN_CLINICA": 0

                }

                await fetch(urlValorizarProducto, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        if (data.msg) {
                            setStore({
                                errors: data
                            })
                        } else {
                            setStore({
                                valorizacion: data,
                            })
                        }
                    })
                // window.location.reload();

            },

            postValorizarProductoPrueba: async () => {
                // e.preventDefault();
                const store = getStore();
                const urlValorizarProducto = store.mainPath + "Agenda/valorizaproducto";

                let rutModSDV;
                let rutModDV;

                let rutMedicoModificado;

                if (store.rutMedicoCDV === '' || store.rutMedicoCDV === 0) {
                    rutMedicoModificado = 0;
                } else {
                    rutModSDV = store.rutMedicoCDV.slice(0, -2);
                    rutModDV = store.rutMedicoCDV.charAt(store.rutMedicoCDV.length -1);
                    rutMedicoModificado = rutMedicoModificado; // TODO --> Cambiar
                }

                const body = {
                    
                    // "cod_centro": Math.floor(parseFloat(store.centroCaja)),
                    "cod_centro": 9,
                    // "cod_servicio": Math.floor(parseFloat(store.servicioCaja)),
                    "cod_servicio": 15,
                    // "rut_clinica": Math.floor(parseFloat(store.rutClinica)),
                    "rut_clinica": 777427817,
                    "rut_tratante": rutMedicoModificado,
                    "ambiente": "A",
                    "codigo_prestacion": Math.floor(parseFloat(store.codigoPrestacion)),
                    "nombre_prestacion": store.nombrePrestacion,
                    "cod_interv_quirur": Math.floor(parseFloat(store.codIntervQuirur)),
                    "cod_homologo": Math.floor(parseFloat(store.codHomologo)),
                    "codigo_mm": store.codigoMm,
                    "cod_item": (store.codItem === null) ? 0 : store.codItem,
                    "codigo_tipo_factura_X_componente": Math.floor(parseFloat(store.codigoTipoFacturaXComponente)),
                    "codigo_tp": Math.floor(parseFloat(store.codigoTp)),
                    "descripcion_tp": store.descripcionTp,
                    "codigo_sub_tp": Math.floor(parseFloat(store.codigoSubTp)),
                    "descripcion_sub_tp": store.descripcionSubTp,
                    "codprevisionpac": Math.floor(parseFloat(store.codPrevisionPac)),
                    "codprevisionPart": store.codPrevisionPart,
                    "cod_convenio": store.codConvenio,
                    "fecha_valorizacion": store.fechaValorizacion,
                    "lst_componentes": [
                        {
                        "_tp_codigo": Math.floor(parseFloat(store.tpCodigo)),
                        "_sub_tp_codigo": Math.floor(parseFloat(store.subTpCodigo)),
                        "_codigo": Math.floor(parseFloat(store.gtpCodigo)),
                        "_descripcion": store.pDescripcion,
                        "_item": {
                            "_codigo": (store.itemCodigo === null) ? 0 : store.itemCodigo,
                            "_descripcion": store.itemDescripcion
                        },
                        "_muestra": {
                            "_codigo": (store.muestraCodigo === null) ? 0 : store.muestraCodigo,
                            "_descripcion": store.muestraDescripcion
                        },
                        // "_codigo_intervencion_quirurg": Math.floor(parseFloat(store.codIntervQuirur)),
                        "_codigo_intervencion_quirurg": 0,
                        "_codigo_homologo": store.codHomologo,
                        "_nombre_tp": store.nombrePrestacion,
                        "_nombre_sub_tp": store.nombrePrestacion,
                        "_id_prestacion_orden_clinica": 0,
                        "_tipo_divergencia": 0,
                        "_valor_divergente": 0,
                        "_nombre_divergente": "string",
                        "_ID_RESERVA_PABELLON": 0
                        }
                    ],
                    "cod_descuento": false,
                    "con_recargo": false,
                    "tipo_divergencia": 0,
                    "valor_divergente": 0,
                    "nombre_divergente": "string",
                    "item": {
                        "_codigo": 0,
                        "_descripcion": "null"
                    },
                    "muestra": {
                        "_codigo": 0,
                        "_descripcion": "null"
                    },
                    "iD_DETALLE_ORDEN_CLINICA": 0

                }

                await fetch(urlValorizarProducto, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => resp.json())
                    .then(data => {
                        // console.log(data);
                        if (data.msg) {
                            setStore({
                                errors: data
                            })
                        } else {
                            setStore({
                                valorizacion: data,
                            })
                        }
                    })
                    
                // window.location.reload();
                    console.log(store.valorizacion);
            },




        },
    }
}

export default getState;