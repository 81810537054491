import React, { useState } from 'react';

const Spinner = () => {
    const [waiting, setWaiting] = useState(false);
    const [promesa, setPromesa] = useState("");

    const handleClick = () => {
        setWaiting(true);

        setTimeout(() => {
            setPromesa("Lo logre!");
            setWaiting(false);
        }, 2000);

    }

    console.log(waiting);
    console.log(promesa);

    return (
        <div className="container">
            <h3>spinner</h3>
            <button type="button" className="btn btn-danger" onClick={(e) => { handleClick(e) }}>Acción</button>
            <div className="promesa">
                {
                    (waiting === false) ? (
                        (promesa !== "") ? (
                            <p>{promesa}</p>
                        ) : (
                            <p>Nada que mostrar</p>
                        )
                        
                    ) : (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Cargando</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Spinner;