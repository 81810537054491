import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../content/appContext';

import "../styles/home.css";
import Logo from "../static/img/logo.png";

const Home = props => {
    const { store, actions } = useContext(Context);
    return (
        <div className="container">
            <div className="row my-5">
                <img src={Logo} alt="Logo_Clini" className='logoimg center' />
            </div>
            <div className="row my-5">
                <form className='col-xl-6 col-md-9 col-sm-12 center'>
                    <div className="mb-3">
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='nombre@correo.xyz' name="email" onChange={actions.handleChange} value={store.email} />
                    </div>
                    <div className="mb-3">
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder='Password' name="password" onChange={actions.handleChange} value={store.password} />
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                    <div className="text-center">
                        <Link className="btn btn-success" to="/dashboard">Ingresar</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Home;