import React from 'react';
import UnderConstruction from '../enContrucción';

const CentrosMedicos = () => {
    return(
        <div className="container">
            <h1>Centros Médicos</h1>
            <UnderConstruction />
        </div>
    );
}

export default CentrosMedicos;