import React from "react";

// STYLES
import "../../styles/valorizarPrestaciones.css";

const ValorizarPrestaciones = () => {
  return (
    <div className="container valorizarPrestaciones">
      <div className="row padd">
        <div>
          <h5>
            <b className="letter-dark-red">Detalle de Prestaciones</b>
          </h5>

          <div className="table-responsive">
            <table className="table table-bordered table-hover table-sm text-center tabla-prestaciones">
              <thead className="purple-background text-white">
                <tr>
                  <th className="resized" scope="col">
                    Código
                  </th>
                  <th className="resized" scope="col">
                    Denominación
                  </th>
                  <th className="resized" scope="col">
                    Cantidad
                  </th>
                  <th className="resized" scope="col">
                    Monto
                  </th>
                  <th className="resized" scope="col">
                    Convenio
                  </th>
                  <th className="resized" scope="col">
                    Particular
                  </th>
                  <th className="resized" scope="col">
                    Seleccionar
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>301045</td>
                  <td>Hemograma</td>
                  <td>1</td>
                  <td>2100</td>
                  <td>N/A</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
                <tr>
                  <td>3090422</td>
                  <td>Orina Completa</td>
                  <td>1</td>
                  <td>1800</td>
                  <td>N/A</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
                <tr>
                  <td>301045</td>
                  <td>Electrolito Plasmatico</td>
                  <td>1</td>
                  <td>2100</td>
                  <td>N/A</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
                <tr>
                  <td>301045</td>
                  <td>Glucosa en Sangre</td>
                  <td>1</td>
                  <td>2100</td>
                  <td>N/A</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
                <tr>
                  <td>301045</td>
                  <td>Venosa Adulto</td>
                  <td>1</td>
                  <td>2100</td>
                  <td>N/A</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
                <tr>
                  <td>301045</td>
                  <td>Glucosa Liquida</td>
                  <td>1</td>
                  <td>2100</td>
                  <td>N/A</td>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <input type="checkbox" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row padd">
            <div className="btn-group-toggle">
              <button
                className="btn btn-warning text-white col-md-2 col-2 botones"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                B. Electrónico
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">

                    <button
                        type="button"
                        className ="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                     
                    </div>

                    <div>
                      <div className="row">
                      <h5
                        className="modal-title fs-5 purple-text left"
                        id="exampleModalLabel">
                        Bono Electrónico
                      </h5>

                      <table className="table">
                        <thead>
                          <tr>
                            <th><h6 className="col-1 purple-text">Folio</h6></th>
                            <th><h6 className="col-1 purple-text">Fecha</h6></th>
                            <th><h6 className="col-1 purple-text">Valor</h6></th>
                            <th><h6 className="col-1 purple-text">Bonif.</h6></th>
                            <th><h6 className="col-1 purple-text">Ot. Boni.</h6></th>
                            <th><h6 className="col-1 purple-text">Copago</h6></th>
                            <th><h6 className="col-1 purple-text">O. F. Pago</h6></th>
                            <th><h6 className="col-1 purple-text">A pagar</h6></th>
                          </tr>
                        </thead>
                        <body>
                          <tr>
                            {/* <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td>
                                <td className="col-1">
                                <input className="form-control" type="text" /></td> */}
                          </tr>
                        </body>
                      </table>
                      </div>
                      </div>

                    <div className="modal-body purple-outline">

                      <div className="row">
                        <h5 className="left">
                          <strong className="purple-text">Prestaciones</strong>
                        </h5>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-md-1">
                                <h6 className="purple-text left">Código</h6>
                              </th>
                              <th className="col-md-4">
                                <h6 className="purple-text left">
                                  Denominación
                                </h6>
                              </th>
                              <th className="col-md-1">
                                <h6 className="purple-text left">Cantidad</h6>
                              </th>
                              <th className="col-md-1">
                                <h6 className="purple-text left">Valor</h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-6">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-6">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-6">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-6">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                              <td className="col-md-2">
                                <input className="form-control" type="text" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        {/*<button type="button" class="btn btn-primary">
                        Save changes
                      </button>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button className="btn btn-warning text-white col-md-2 col-2 botones ">
                B. Manual
              </button>
              <button className="btn btn-warning text-white col-md-2 col-2 botones">
                B. Físico
              </button>
              <button className="btn btn-success text-white col-md-3 col-3 botones">
                Continuar
              </button>
            </div>
          </div>

          <div style={{ height: "50px;" }}>
            <b className="letter-dark-red ">Recaudación</b>
          </div>

          <div className="row padd">
            <div className="col-md-6 col-12 ">
              {/* aqui va incluida la tabla que no se realizar */}
              <div className="row">
                <div className="col-5 center">
                  <h7>Formas de pago</h7>
                </div>
                <div className="col-5 center">
                  <h7>Saldo a pagar paciente</h7>
                </div>
              </div>

              <table className="table-hover table-sm ">
                <thead>
                  <tr>
                    <td className="col-md-2 col-2"></td>
                    <td className="col-md-2 col-2"></td>
                    <td className="col-md-2 col-2">$9.000</td>
                  </tr>
                </thead>
                <tbody className="table-secondary">
                  <tr>
                    <td>T. Débito</td>
                    <td>
                      <input className="form-control input" type="text" />
                    </td>
                    <td>
                      <button className="btn bg-primary text-white col-md-8 col-8">
                        Ir
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>T. Crédito</td>
                    <td>
                      <input className="form-control input" type="text" />
                    </td>
                    <td>
                      <button className="btn bg-primary text-white col-md-8 col-8">
                        Ir
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Efectivo</td>
                    <td>
                      <input className="form-control input" type="text" />
                    </td>
                    <td>
                      <button className="btn bg-primary text-white col-md-8 col-8">
                        Ir
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Bono Clini</td>
                    <td>
                      <input className="form-control input" type="text" />
                    </td>
                    <td>
                      <button className="btn bg-primary text-white col-md-8 col-8">
                        Ir
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Transferencia</td>
                    <td>
                      <input className="form-control input" type="text" />
                    </td>
                    <td>
                      <button className="btn bg-primary text-white col-md-8 col-8">
                        Ir
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Venta al Crédito</td>
                    <td>
                      <input className="form-control input" type="text" />
                    </td>
                    <td>
                      <button className="btn bg-primary text-white col-md-8 col-8">
                        Ir
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6 col-12">
              {/* aqui va incluida la tabla resumen valorizacion*/}
              <h6>Resumen Valorización</h6>
              <table className="table table-bordered table-hover table-s-10">
                <thead className="dark-red text-white">
                  <tr>
                    <th className="resized" scope="col-6">
                      Descripción
                    </th>
                    <th className="resized" scope="col-6">
                      Monto
                    </th>
                  </tr>
                </thead>
                <tbody className="lead-colored text-white">
                  <tr>
                    <td>total</td>
                    <td>$15.000</td>
                  </tr>
                  <tr>
                    <td>Bonificación</td>
                    <td>$5.000</td>
                  </tr>
                  <tr>
                    <td>Desc Convenio</td>
                    <td>$1.000</td>
                  </tr>
                  <tr>
                    <td>T. Débito</td>
                    <td>$4.000</td>
                  </tr>
                  <tr>
                    <td>Efectivo</td>
                    <td>$5.000</td>
                  </tr>
                  <tr>
                    <td>Saldo por asignar</td>
                    <td>$0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="btn-group-toggle d-flex justify-content-end">
            <button className="btn btn-success text-white botones">
              Pago Parcial
            </button>
            <button className="btn btn-success text-white botones">
              Recaudar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValorizarPrestaciones;
