const Especialidad = [
        {
            "id": 1,
            "nombre": "LABORATORIO"
        },
        {
            "id": 2,
            "nombre": "SERVICIO A DOMICILIO"
        },
        {
            "id": 3,
            "nombre": "LABORATORIO PCR CLINI"
        },
        {
            "id": 4,
            "nombre": "LABORATORIO CLINICO CLINI DOM"
        },
        {
            "id": 5,
            "nombre": "LABORATORIO PCR CLINI DOM"
        },
        {
            "id": 6,
            "nombre": "IMAGENES"
        },
        {
            "id": 7,
            "nombre": "LABORATORIO DE CARDIOLOGIA"
        },
        {
            "id": 8,
            "nombre": "OFTALMOLOGIA"
        }
    ]

    export default Especialidad;