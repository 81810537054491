import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../content/appContext';

const Prueba = () => {

    const {store, actions } = useContext(Context);

    const [rut, setRut] = useState('');
    const [resultado, setResultado] = useState('');
    const [resultadoValidacion, setResultadoValidacion] = useState('');

    useEffect(() => {
        const handleChange = (e) => {
            let valor = e.target.value.replace(/-/g, ''); // Elimina todos los guiones
            if (valor.length > 5) {
                // Si hay más de un dígito, formatea el RUT
                valor = valor.slice(0, -1) + '-' + valor.slice(-1);
            }
            e.target.value = valor; // Actualiza el valor del campo con el formato aplicado

            setRut(valor);

            // Validar RUT
            const esValido = validarRut(valor);
            setResultadoValidacion(esValido);
            const mensaje = esValido ? "Válido" : "Inválido";
            setResultado("El RUT ingresado es " + mensaje);
        };

        const validarRut = (rut) => {
            rut = rut.trim();
            if (!/^[0-9]+[-]{1}[0-9kK]{1}$/.test(rut)) return false;

            const rutSinGuion = rut.replace('-', '');
            const dv = rut.slice(-1);
            const rutNumeros = rutSinGuion.slice(0, -1);

            let suma = 0;
            let multiplo = 2;
            for (let i = rutNumeros.length - 1; i >= 0; i--) {
                suma += parseInt(rutNumeros.charAt(i)) * multiplo;
                if (multiplo < 7) multiplo++;
                else multiplo = 2;
            }
            let resto = suma % 11;
            let resultado = 11 - resto;
            if (resultado === 11) resultado = 0;
            else if (resultado === 10) resultado = "K";

            return resultado.toString().toUpperCase() === dv.toUpperCase();
        };

        const inputElement = document.getElementById('rutInput');
        inputElement.addEventListener('input', handleChange);

        return () => {
            inputElement.removeEventListener('input', handleChange);
        };
    }, []); // Ejecutar solo una vez al montar el componente

    return (
        <>
            <input
                type="text"
                id="rutInput"
                placeholder="Ingresa el RUT"
            />
            {/* <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.docto} placeholder="12345678-9" /> */}

            <p>{resultadoValidacion}</p>
            <p>{resultado}</p>
        </>
    );
};

export default Prueba;