import React from 'react';
import { Link } from 'react-router-dom';

const TipoOperacionesBorrar = () => {
    return(
        <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Tipo de Operaciones Borrar</h2>
                <div className="col-8 offset-2">
                    <form action="">
                        <div className="row">
                            <div className="col-5">
                                <p>Top Código</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>TMO Código</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Top Descripción</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Top Exige Documento</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Top Sigla</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} disabled />
                            </div>
                        </div>
                        <h5 className="my-5"><strong>Estás seguro que quieres eliminar a XXXXX? (Esto será permanente)</strong></h5>
                        <Link className="btn btn-danger me-2" to="/caja_tipo_operaciones/">Atrás</Link>
                        <Link className="btn btn-success" to="/">Eliminar</Link>
                        {/* <button className="btn btn-success" type="submit">Agregar</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TipoOperacionesBorrar;