import React from 'react'
import { Link, useParams } from 'react-router-dom';

import '../../styles/confirmarAgenda.css';

// FAKEDATA
import Disponibilidades from '../../content/fake_db/fakeDisponibilidad.jsx';

const AWConfirmarAgenda = props => {

    const { agenda_id } = useParams();

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div>
                        {
                            Disponibilidades ? Disponibilidades.map((Disponibilidad) => {
                                if (parseInt(Disponibilidad.id) === parseInt(agenda_id)) {
                                    return (
                                        <div className="card card-reserva m-5" key={Disponibilidad.id}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-body" key={Disponibilidad.id}>
                                                        <h4 className="card-title">¡Estás a punto de confirmar una hora!</h4>
                                                        <div className="d-flex">
                                                            <p className="card-text">El día {Disponibilidad.fecha} a las {Disponibilidad.hora} en {Disponibilidad.centro} con doctor(a): {Disponibilidad.profesional}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            }) : (
                                <div className="d-flex justify-content-center text-center my-5">
                                    <div className="spinner-grow text-warning my-5" role="status">
                                        <span className="visually-hidden"></span>
                                    </div>
                                </div>
                            )
                        }
                        <form className="col-md-4 offset-md-4 col-sm-12">
                            <h5 className="my-5">Confirma los datos de contacto del paciente</h5>
                            <div className="input-group mb-3">
                                <span className="input-group-text">RUT</span>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Nombre</span>
                                <input type="text" className="form-control" disabled />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Apellido Paterno</span>
                                <input type="text" className="form-control" disabled />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Apellido Materno</span>
                                <input type="text" className="form-control" disabled />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Teléfono</span>
                                <input type="text" className="form-control" disabled />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Correo</span>
                                <input type="text" className="form-control" disabled />
                            </div>
                            <div className="d-flex justify-content-center my-5">
                                <Link className="btn btn-danger" to="/">Cancelar</Link>
                                {/* <button className="btn btn-success ms-3" type="submit">Agendar</button> */}
                                <Link className="btn btn-success ms-3" to="/">Agendar</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AWConfirmarAgenda;