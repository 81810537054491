import React, { useState, useEffect } from 'react';

const PruebaIMC = () => {
    const [cuenta, setCuenta] = useState(0);
    const [peso, setPeso] = useState("");
    const [talla, setTalla] = useState("");
    const [imc, setImc] = useState("");
    const [resultadoIMC, setResultadoIMC] = useState("");

    const contar = () => {
        setCuenta(cuenta + 1);
    }

    const cambiarPeso = e => {
        setPeso(e.target.value);
    }

    const cambiarTalla = e => {
        setTalla(e.target.value);
    }

    useEffect(() => {
        if (peso && talla) {
            const nuevoIMC = parseFloat(peso) / (parseFloat(talla) * parseFloat(talla));
            setImc(nuevoIMC.toFixed(2)); // Redondeamos el IMC a dos decimales
            if (nuevoIMC < 18.5) {
                setResultadoIMC("Bajo Peso");
            } else if (nuevoIMC >= 18.5 && nuevoIMC < 24.9) {
                setResultadoIMC("Normal");
            } else if (nuevoIMC >= 24.9 && nuevoIMC < 29.9) {
                setResultadoIMC("Sobrepeso");
            } else {
                setResultadoIMC("Obesidad");
            }
        } else {
            setImc("");
            setResultadoIMC("");
        }
    }, [peso, talla]);

    console.log("hola", peso, talla);

    return (
        <div className="container">
            <button onClick={contar}>Apretar</button>
            <p>{cuenta}</p>
            <div className="d-flex my-2">
                <div className="col-2">
                    <label>Peso</label>
                </div>
                <div className="col-10">
                    <input type="text" className="form-control" onChange={cambiarPeso} />
                </div>
            </div>
            <div className="d-flex my-2">
                <div className="col-2">
                    <label>Talla</label>
                </div>
                <div className="col-10">
                    <input type="text" className="form-control" onChange={cambiarTalla} />
                </div>
            </div>
            <div className="d-flex my-2">
                <div className="col-2">
                    <label>IMC</label>
                </div>
                <div className="col-10">
                    <input type="text" className="form-control" value={imc} disabled />
                </div>
            </div>
            <div className="d-flex my-2">
                <div className="col-2">
                    <label>Resultado</label>
                </div>
                <div className="col-10">
                    <input type="text" className="form-control" value={resultadoIMC} disabled />
                </div>
            </div>
        </div>
    );
}

export default PruebaIMC;