import React from 'react';
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="container">
            <div className="row my-5 text-center">
                <div className="col-12">
                    <h1>Lamentablemente aqui no hay nada</h1>
                    <Link className="btn btn-success" to="/">Ir al inicio</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;