import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../content/appContext';
import { useEffect } from 'react';

// import moment from 'moment';

//! STYLES
import '../../styles/agendaInterna.css';

//! STATIC
//? ICONS
import AgendarIcon from '../../static/icons/agendarIcon.png';
import ConfirmIcon from '../../static/icons/confirmIcon.png';
import HistoryIcon from '../../static/icons/historyIcon.png';
import BuscarIcon from '../../static/icons/lupaIcon.png';
import ModificarIcon from '../../static/icons/modificarIcon.png';

//! DATEPICKER CALENDAR --> Documentación: https://react-day-picker.js.org/
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

//! REACT-AUTOSUGGEST --> Documentación: https://github.com/moroshko/react-autosuggest#installation


//! FAKEAPI
import Horarios from '../../content/fake_db/fakeHoras';
// import Disponibilidades from '../../content/fake_db/fakeDisponibilidad';
// import Citas from '../../content/fake_db/fakeCitas';

const pastMonth = new Date(Date.now());

const AgendaInterna = () => {

    //! GLOBAL -> FLUX
    const { store, actions } = useContext(Context);

    //! MENSAJE ERROR DE VALIDACIÓN
    // const [errorValidacion, setErrorValidacion] = useState(false);

    //! DATEPICKER

    // const [selected, setSelected] = useState(pastMonth);

    // let footer = <p>Please pick a day.</p>;
    // if (selected) {
    //     footer = <p>You picked {format(selected, 'PP')}.</p>;
    // }

    const disabledDays = [
        { from: new Date(1900, 4, 18), to: (Date.now() - 24 * 60 * 60 * 1000) }
        // --> Deshabilitar en el calendario los dias anteriores hasta "ayer"
    ];

    //! VALIDADOR RUT
    const [resultado, setResultado] = useState('');
    const [resultadoValidacion, setResultadoValidacion] = useState('');

    useEffect(() => {
        const handleChange1 = (e) => {
            let valor = e.target.value.replace(/-/g, ''); // Elimina todos los guiones
            if (valor.length > 1) {
                // Si hay más de un dígito, formatea el RUT
                valor = valor.slice(0, -1) + '-' + valor.slice(-1);
            }
            e.target.value = valor; // Actualiza el valor del campo con el formato aplicado

            // Validar RUT
            const esValido = validarRut(valor);
            setResultadoValidacion(esValido);
            const mensaje = esValido ? "Válido" : "Inválido";
            setResultado("El RUT ingresado es " + mensaje);
        };

        const validarRut = (rut) => {
            rut = rut.trim();
            if (!/^[0-9]+[-]{1}[0-9kK]{1}$/.test(rut)) return false;

            const rutSinGuion = rut.replace('-', '');
            const dv = rut.slice(-1);
            const rutNumeros = rutSinGuion.slice(0, -1);

            let suma = 0;
            let multiplo = 2;
            for (let i = rutNumeros.length - 1; i >= 0; i--) {
                suma += parseInt(rutNumeros.charAt(i)) * multiplo;
                if (multiplo < 7) multiplo++;
                else multiplo = 2;
            }
            let resto = suma % 11;
            let resultado = 11 - resto;
            if (resultado === 11) resultado = 0;
            else if (resultado === 10) resultado = "K";

            return resultado.toString().toUpperCase() === dv.toUpperCase();
        };

        const inputElement = document.getElementById('rutInput');
        inputElement.addEventListener('input', handleChange1);

        return () => {
            inputElement.removeEventListener('input', handleChange1);
        };
    }, []); // Ejecutar solo una vez al montar el componente

    // 18946245-k --> Rut con guion K para pruebas (el mio termina con número)

    //! Formato Rut en input dinámico

    // useEffect(() => {
    //     const handleChange = (e) => {
    //       let valor = e.target.value.replace(/-/g, ''); // Elimina todos los guiones
    //       if (valor.length > 1) {
    //         // Si hay más de un dígito, formatea el RUT
    //         valor = valor.slice(0, -1) + '-' + valor.slice(-1);
    //       }
    //       e.target.value = valor; // Actualiza el valor del campo con el formato aplicado
    //     };

    //     const inputElement = document.getElementById('rutInput');
    //     inputElement.addEventListener('input', handleChange);

    //     return () => {
    //       inputElement.removeEventListener('input', handleChange);
    //     };
    //   }, []);

    //! Manejar click en Retroceso (No es aconsejable, pero solicitado)

    useEffect(() => {
        const handlePopstate = () => {
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                // Verificar si el modal está abierto
                const modal = document.querySelector('.modal.show');
                if (modal) {
                    // Si el modal está abierto, cerrarlo
                    const modalCloseButton = modal.querySelector('[data-bs-dismiss="modal"]');
                    if (modalCloseButton) {
                        modalCloseButton.click();
                    }
                }
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    //! Formato Fecha Nacimiento
    const fechaString = store.fecNacPaciente;
    const fecha = new Date(fechaString);

    // Extraer los componentes de la fecha
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear().toString();

    // Formatear la fecha en formato DD/MM/AAAA
    const fechaFormateada = `${dia}/${mes}/${año}`;

    //! Activar algunas funciones
    useEffect(() => {
        actions.getCentros();
    }, []);

    //! Sector console.log()
    console.log(store.fecNacPaciente);

    return (
        <div className="container agendaInterna">
            <p>{store.errors}</p>
            <div className="row">
                <div className="col-12 conditionalBody">
                    <div className="boxes text-center">
                        <span className="">AGENDAMIENTO INTERNO</span>
                    </div>
                    <div className="boxes options">
                        <select className="form-select" name="centro" onChange={(e) => { actions.handleChange(e); actions.getServiciosClinicos(e) }} value={store.centro} >
                            <option value="">Centros</option>
                            {
                                store.centros ? store.centros.map(centro => {
                                    return (
                                        <option key={centro.pCod_Centro} value={centro.pCod_Centro}>{centro.pDescripcion}</option>
                                    )
                                }) : null
                            }
                        </select>
                        <select className="form-select" name="servicio" onChange={(e) => { actions.handleChange(e); actions.getEspecialidades(e) }} value={store.servicio} >
                            <option defaultValue>Servicios</option>
                            {
                                store.serviciosClinicos ? store.serviciosClinicos.map(servicioClinico => {
                                    return (
                                        <option key={servicioClinico.pCODIGO} value={servicioClinico.pCODIGO}>{servicioClinico.pDESCP}</option>
                                    )
                                }) : null
                            }
                        </select>
                        <select className="form-select" name="especialidad" onChange={(e) => { actions.handleChange(e); actions.getInstitucionesPrevisionales(e) }} value={store.especialidad} >
                            <option value="">Especialidad</option>
                            {
                                store.especialidades ? store.especialidades.map(esp => {
                                    return (
                                        <option value={esp.pIDENT_ESP} key={esp.pIDENT_ESP}>{esp.pDescp}</option>
                                    )
                                }) : null
                            }
                        </select>
                        <select className="form-select" name="prevision" onChange={(e) => { actions.handleChange(e); actions.getProfesionales(e) }} value={store.prevision} >
                            <option defaultValue>Previsión</option>
                            {
                                store.institucionesPrevisionales && store.institucionesPrevisionales.map(institucionPrevisional => {
                                    return (
                                        <option value={institucionPrevisional.ipR_CODIGO_PREV} key={institucionPrevisional.ipR_CODIGO_PREV}>{institucionPrevisional.ipR_DESCRIPCION_PREV}</option>
                                    )
                                })
                            }
                        </select>
                        <select className="form-select" name="profesional" onChange={(e) => { actions.handleChange(e); actions.getPrestaciones(e) }} value={store.profesional} >
                            <option value="">Profesional</option>
                            {
                                store.profesionales && store.profesionales.map(prof => {
                                    return (
                                        <option value={prof.idenT_PROFMEDICO} key={prof.idenT_PROFMEDICO}>{prof.nombrE_PROFMEDICO}</option>
                                    )
                                })
                            }
                        </select>
                        <select className="form-select" name="prestacion" onChange={(e) => { actions.handleChange(e); actions.getDisponibilidades(e) }} value={store.prestacion}>
                            <option defaultValue>Prestación</option>
                            {
                                store.prestaciones && store.prestaciones.map(prest => {
                                    return (
                                        <option value={prest.coD_PRESTACION} key={prest.coD_PRESTACION}>{prest.prestacion}</option>

                                    )
                                })
                            }
                        </select>
                        <div className="estadosAgenda ms-3">
                            <div class="bd-example m-0 border-0">

                                <div className="d-flex px-3">
                                    <div className="form-check">
                                        <input className="form-check-input" role="switch" type="radio" name="estadoAgenda" value="agendadas" id="agendadas" onClick={e => { actions.handleChangeInputs(e) }} checked={store.estadoAgenda === "agendadas"} />
                                        <label className="form-check-label" htmlFor="agendadas">
                                            Agendada
                                        </label>
                                    </div>
                                    <div className="form-check mx-4">
                                        <input className="form-check-input" role="switch" type="radio" name="estadoAgenda" value="bloqueadas" id="bloqueadas" onChange={e => { actions.handleChangeInputs(e) }} checked={store.estadoAgenda === "bloqueadas"} />
                                        <label className="form-check-label" htmlFor="bloqueadas">
                                            Bloqueada
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" role="switch" type="radio" name="estadoAgenda" value="canceladas" id="canceladas" onChange={e => { actions.handleChangeInputs(e) }} checked={store.estadoAgenda === "canceladas"} />
                                        <label className="form-check-label" htmlFor="canceladas">
                                            Cancelada
                                        </label>
                                    </div>
                                    <div className="form-check ms-4">
                                        <input className="form-check-input" role="switch" type="radio" name="estadoAgenda" value="disponibles" id="disponibles" onChange={e => { actions.handleChangeInputs(e) }} checked={store.estadoAgenda === "disponibles"} />
                                        <label className="form-check-label" htmlFor="disponibles">
                                            Disponible
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link className='btn btn-success ms-5' to="/"><img className="icon whiteIcon" src={BuscarIcon} alt="buscar" /></Link>
                    </div>
                    <div className='d-flex'>
                        <div className="boxes date">
                            <DayPicker
                                defaultMonth={pastMonth}
                                showOutsideDays
                                mode="single"
                                selected={store.fechaDesde}
                                onSelect={e => actions.setFechas(e)}
                                disabled={disabledDays}
                                ISOWeek
                                locale={es}
                            />
                            <div className="d-flex">
                                <p>Hora Inicial: </p>
                                <select className="horaInput" name="" id="horaInput">
                                    {
                                        Horarios ? Horarios.map(horario => {
                                            return (
                                                <option name="" id="" key={horario.id}>{horario.hora}</option>
                                            )
                                        }) : null
                                    }
                                </select>
                            </div>
                            <div className="d-flex">
                                <p>Hora Final: </p>
                                <select className="horaInput" name="" id="">
                                    {
                                        Horarios ? Horarios.map(horario => {
                                            return (
                                                <option name="" id="" key={horario.id}>{horario.hora}</option>
                                            )
                                        }) : null
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='boxes tabla scrollTable'>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="resized" scope="col">Agendar</th>
                                        <th className="resized" scope="col">Confirmar</th>
                                        <th className="resized" scope="col">Historial</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Hora</th>
                                        <th scope="col">Duración</th>
                                        <th scope="col">Especialidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        store.disponibilidadesFiltradas ? store.disponibilidadesFiltradas.map((disponibilidad) => {
                                            //! Formeteo Fecha Inicial (String -> Date + formatos) 
                                            const fechaBrutaInicial = new Date(disponibilidad.pHR_INICIO_BLOQUE);
                                            const fechaInicial = fechaBrutaInicial.toISOString().slice(0, 10);
                                            const [anio, mes, dia] = fechaInicial.split("-");
                                            const fechaFormateada = `${dia}-${mes}-${anio}`;
                                            const horaInicial = fechaBrutaInicial.toTimeString().slice(0, 8);
                                            const [horas, minutos] = horaInicial.split(":");
                                            const horaInicialFormateada = `${horas}:${minutos}`;
                                            // Formeteo Fecha Final (String -> Date) 
                                            const fechaBrutaFinal = new Date(disponibilidad.pHR_FIN_BLOQUE);
                                            const horaFinal = fechaBrutaFinal.toTimeString().slice(0, 8);
                                            // Variable de diferencia horaria.
                                            const [horasInicial, minutosInicial, segundosInicial] = horaInicial.split(":").map(Number);
                                            const tiempoMinutosInicial = horasInicial * 60 + minutosInicial + segundosInicial / 60;
                                            const [horasFinal, minutosFinal, segundosFinal] = horaFinal.split(":").map(Number);
                                            const tiempoMinutosFinal = horasFinal * 60 + minutosFinal + segundosFinal / 60;
                                            const difHoraria = tiempoMinutosFinal - tiempoMinutosInicial;

                                            // Creacion de un Key único por que no existe en los datos.
                                            const idAgenda = disponibilidad.pIDENT_AGENDA;
                                            const bloqueConcurrente = disponibilidad.pBLOQUE_CONCURRENTE;
                                            const fechaInicialMilisegundos = Date.parse(fechaBrutaFinal)
                                            const keyUnica = idAgenda + "-" + bloqueConcurrente + "-" + fechaInicialMilisegundos;

                                            return (
                                                <tr className="text-center" key={keyUnica} >
                                                    <td><button className="btn btn-success" value="rut" name="eleccionBusqueda" onClick={e => actions.handleChange(e)} data-bs-toggle="modal" data-bs-target="#agendarModal"><img className="icon whiteIcon" src={AgendarIcon} alt="Agendar" /></button></td>
                                                    {/* <td><Link className="btn btn-warning" to={"/agendar-cita/" + Cita.id}><img className="icon" src={ConfirmIcon} alt="Agendar" /></Link></td> */}
                                                    <td className="dropdown">
                                                        <button className="btn btn-warning dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img className="icon whiteIcon" src={ConfirmIcon} alt="Confirmar Agendar" />
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#agendarModal" value="" name="" onClick={null}>Confirmar</button></li>
                                                            <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#agendarModal" value="" name="" onClick={null}>Anular</button></li>
                                                        </ul>
                                                    </td>
                                                    <td><Link className="btn btn-danger" to={"/agendar-cita/" + disponibilidad.id}><img className="icon" src={HistoryIcon} alt="Agendar" /></Link></td>
                                                    <td>{fechaFormateada}</td>
                                                    <td>{horaInicialFormateada}</td>
                                                    <td>{difHoraria + " min"}</td>
                                                    <td>{disponibilidad.pESPECIALIDAD}</td>
                                                </tr>
                                            )
                                        }) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    <div className="spinner-grow spinner-conf" role="status">
                                                        <span className="visually-hidden">Cargando...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <span className="modal modalAgenda fade" id="agendarModal" tabIndex="-1" aria-hidden="true">
                <span className="modal-dialog modal-xl">
                    {
                        // Alert de datos mal ingresados
                        (resultadoValidacion === false || resultadoValidacion === null) ? (
                            <div className="alert alert-danger fade show text-center" role="alert">
                                Ingresa Correctamente los datos
                            </div>
                        ) : (
                            null
                        )
                    }
                    <span className="modal-content d-flex">
                        <span className="modal-header row">
                            <div className="col-12 col-md-3 d-flex">
                                <h5 className="modal-title mt-2 pt-1">Paciente</h5>
                                <div className="radio-group d-block ms-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="eleccionBusqueda" value="rut" checked={store.eleccionBusqueda === "rut"} onChange={e => [actions.handleChangeInputs(e), actions.resetVars(e)]} />
                                        <label className="form-check-label">
                                            RUT
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="eleccionBusqueda" value="nombre" checked={store.eleccionBusqueda === "nombre"} onChange={e => [actions.handleChangeInputs(e), actions.resetVars(e)]} />
                                        <label className="form-check-label">
                                            Nombre
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                {/* Selección de Forma de Búsqueda de Paciente */}
                                {
                                    //! AQUI ESTOY TRABAJANDOOOOOO!!!!
                                    (store.eleccionBusqueda === "rut") ? (
                                        // Buscador Superior
                                        <form className="d-flex" onSubmit={e => actions.getPacientePorDocto(e)}>
                                            {/* RUT */}
                                            <div className="col-12 col-md-9 me-2">
                                                <span className="input-group my-3">
                                                    <span className="input-group-text">RUT</span>
                                                    <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => actions.handleChange(e)} value={store.docto} placeholder="12345678-9" />
                                                </span>
                                            </div>
                                            {/* Boton Buscar */}
                                            <div className="col-12 col-md-2">
                                                <button type="submit" className="btn btn-danger mt-3">
                                                    <img className="icon mx-1 whiteIcon" src={BuscarIcon} alt="" />
                                                </button>
                                            </div>
                                        </form>
                                    ) : (store.eleccionBusqueda === "nombre") ? (
                                        <form className="boxes buscadorPacienteNombre d-flex" onChange={e => actions.getPacientePorNombre(e)}>
                                            <span className="">Buscar Paciente: </span>
                                            <input className="form-control mx-2" type="text" name="nombre" value={store.nombre} onChange={e => actions.handleChange(e)} placeholder="Nombre" />
                                            <input className="form-control mx-2" type="text" name="ap_paterno" value={store.ap_paterno} onChange={e => actions.handleChange(e)} placeholder="Apellido Paterno" />
                                            <input className="form-control mx-2" type="text" name="ap_materno" value={store.ap_materno} onChange={e => actions.handleChange(e)} placeholder="Apellido Materno" />
                                        </form>
                                    ) : (null)
                                }
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => actions.refreshPage(e)}></button>
                        </span>
                        <span className="modal-body">
                            {
                                (store.eleccionBusqueda === "rut") ? (
                                    <div className="row">
                                        {
                                            (store.pacientePorDocto !== null && store.pacientePorDocto.length !== 0) ? store.pacientePorDocto.map(pcxdoc => {
                                                return (
                                                    // Formulario Usuario Encontrado por Rut
                                                    <form key={pcxdoc.pgL_IDENT} onSubmit={null} >
                                                        <p>Usuario por RUT Encontrado</p>
                                                        <div className="d-flex">
                                                            {/* Col. Izq. */}
                                                            <span className="col-md-4 offset-md-1 col-sm-12">
                                                                {/* Tipo Docto => Select */}
                                                                <span className="input-group my-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Docto.</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="tipoDoc" onChange={e => actions.handleChange(e)} value={store.tipoDoc}>
                                                                            <option value="">Elige Documento</option>
                                                                            {
                                                                                store.tipoDocto && store.tipoDocto.map(docto => {
                                                                                    return (
                                                                                        <option value={docto.tD_ID} key={docto.tD_ID}>{docto.tD_DESCP}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Nombre => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Nombre</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="nombrePaciente" value={store.nombrePaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Ap. Paterno => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Apellido Paterno</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="apPaternoPaciente" value={store.apPaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Ap. Materno => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Apellido Materno</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="apMaternoPaciente" value={store.apMaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Teléfono => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Teléfono</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="celularPaciente" value={store.celularPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Correo => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Correo</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" name="emailPaciente" value={store.emailPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Fecha Nacimiento => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Fecha Nacimiento</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" placeholder="dd/mm/aaaa" name="fecNacPaciente" value={fechaFormateada} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                            </span>
                                                            {/* Col. Der. */}
                                                            <span className="col-md-4 offset-md-1 col-12">
                                                                {/* Rut => Input */}
                                                                <span className="input-group my-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">RUT</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.docto} placeholder="12345678-9" />
                                                                    </div>
                                                                </span>
                                                                {/* Sexo => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Sexo</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="sexoPaciente" value={store.sexoPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoSexo && store.tipoSexo.map(sexo => (
                                                                                    <option value={sexo.seX_ID} key={sexo.seX_ID}>{sexo.seX_DESCP}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Estado Civíl => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Estado Civil</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="estCivilPaciente" value={store.estCivilPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoEstadosCiviles && store.tipoEstadosCiviles.map(estadoCivil => (
                                                                                    <option value={estadoCivil.eC_ID} key={estadoCivil.eC_ID}>{estadoCivil.eC_DESCP}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Dirección => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Dirección</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" name="direccionPaciente" value={store.direccionPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* País => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Pais</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="paisElegido" value={store.paisElegido} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value="">--Elige País--</option>
                                                                            <option value="213">CHILE</option>
                                                                            {
                                                                                store.nombrePais && store.nombrePais.map(pais => {
                                                                                    return (
                                                                                        <option value={Math.floor(parseFloat(pais.pS_CODIGO))} key={pais.pS_CODIGO}>{pais.pS_DESCRIPCION}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Comuna => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Comuna</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="comunaElegida" value={store.comunaElegida} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value="">--Elige Comuna--</option>
                                                                            {
                                                                                store.nombreComunas && store.nombreComunas.map(comuna => {
                                                                                    return (
                                                                                        <option value={comuna.coM_CODIGO} key={comuna.coM_CODIGO}>{comuna.coM_DESCRIPCION}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <option value=""></option>
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Etnia => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Etnia</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="etniaPaciente" value={store.etniaPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoEtnias && store.tipoEtnias.map(etnia => (
                                                                                    <option value={etnia.etN_CODIGO} key={etnia.etN_CODIGO}>{etnia.etN_DESCRIPCION}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-success" onClick={e => actions.postModificarPaciente(e)}><img className="icon mx-1 whiteIcon" src={ModificarIcon} alt="Modificar" /></button>
                                                            <button className="btn btn-warning mx-2" type="submit"><img className="icon mx-1 whiteIcon" src={AgendarIcon} alt="Guardar" /></button>
                                                        </div>
                                                    </form>
                                                )
                                            }) : (
                                                // Formulario Usuario No Encontrado por Rut (Escenario Base)
                                                <form onSubmit={e => { actions.postAgregarPaciente(e) }}>
                                                    <p>Usuario por RUT No Encontrado</p>
                                                    <div className="d-flex">
                                                        {/* Col. Izq. */}
                                                        <span className="col-md-4 offset-md-1 col-sm-12">
                                                            {/* Tipo Docto => Select */}
                                                            <span className="input-group my-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Docto.</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <select className="form-control m-0" name="tipoDoc" onChange={e => actions.handleChange(e)} value={store.tipoDoc}>
                                                                        <option value="">Elige Documento</option>
                                                                        {
                                                                            store.tipoDocto && store.tipoDocto.map(docto => {
                                                                                return (
                                                                                    <option value={docto.tD_ID} key={docto.tD_ID}>{docto.tD_DESCP}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </span>
                                                            {/* Nombre => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className='col-5'>
                                                                    <span className="input-group-text">Nombre</span>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text" className="form-control" name="nombrePaciente" value={store.nombrePaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                            {/* Ap. Paterno => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className='col-5'>
                                                                    <span className="input-group-text">Apellido Paterno</span>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text" className="form-control" name="apPaternoPaciente" value={store.apPaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                            {/* Ap. Materno => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className='col-5'>
                                                                    <span className="input-group-text">Apellido Materno</span>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text" className="form-control" name="apMaternoPaciente" value={store.apMaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                            {/* Teléfono => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className='col-5'>
                                                                    <span className="input-group-text">Teléfono</span>
                                                                </div>
                                                                <div className='col-7'>
                                                                    <input type="text" className="form-control" name="celularPaciente" value={store.celularPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                            {/* Correo => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Correo</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control" name="emailPaciente" value={store.emailPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                            {/* Fecha Nacimiento => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Fecha Nacimiento</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control" placeholder="dd/mm/aaaa" name="fecNacPaciente" value={store.fecNacPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                        </span>
                                                        {/* Col. Der. */}
                                                        <span className="col-md-4 offset-md-1 col-12">
                                                            {/* Rut => Input */}
                                                            <span className="input-group my-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">RUT</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.docto} placeholder="12345678-9" />
                                                                </div>
                                                            </span>
                                                            {/* Sexo => Select */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Sexo</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <select className="form-control m-0" name="sexoPaciente" value={store.sexoPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                        <option value=""></option>
                                                                        {
                                                                            store.tipoSexo && store.tipoSexo.map(sexo => (
                                                                                <option value={sexo.seX_ID} key={sexo.seX_ID}>{sexo.seX_DESCP}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </span>
                                                            {/* Estado Civíl => Select */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Estado Civil</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <select className="form-control m-0" name="estCivilPaciente" value={store.estCivilPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                        <option value=""></option>
                                                                        {
                                                                            store.tipoEstadosCiviles && store.tipoEstadosCiviles.map(estadoCivil => (
                                                                                <option value={estadoCivil.eC_ID} key={estadoCivil.eC_ID}>{estadoCivil.eC_DESCP}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </span>
                                                            {/* Dirección => Input */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Dirección</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <input type="text" className="form-control" name="direccionPaciente" value={store.direccionPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                </div>
                                                            </span>
                                                            {/* País => Select */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Pais</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <select className="form-control m-0" name="paisElegido" value={store.paisElegido} onChange={e => actions.handleChange(e)}>
                                                                        <option value="">--Elige País--</option>
                                                                        <option value="213">CHILE</option>
                                                                        {
                                                                            store.nombrePais && store.nombrePais.map(pais => {
                                                                                return (
                                                                                    <option value={Math.floor(parseFloat(pais.pS_CODIGO))} key={pais.pS_CODIGO}>{pais.pS_DESCRIPCION}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </span>
                                                            {/* Comuna => Select */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Comuna</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <select className="form-control m-0" name="comunaElegida" value={store.comunaElegida} onChange={e => { actions.handleChange(e) }}>
                                                                        <option value="">--Elige Comuna--</option>
                                                                        {
                                                                            store.nombreComunas && store.nombreComunas.map(comuna => {
                                                                                return (
                                                                                    <option value={comuna.coM_CODIGO} key={comuna.coM_CODIGO}>{comuna.coM_DESCRIPCION}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                        <option value=""></option>
                                                                    </select>
                                                                </div>
                                                            </span>
                                                            {/* Etnia => Select */}
                                                            <span className="input-group mb-3">
                                                                <div className="col-5">
                                                                    <span className="input-group-text">Etnia</span>
                                                                </div>
                                                                <div className="col-7">
                                                                    <select className="form-control m-0" name="etniaPaciente" value={store.etniaPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                        <option value=""></option>
                                                                        {
                                                                            store.tipoEtnias && store.tipoEtnias.map(etnia => (
                                                                                <option value={etnia.etN_CODIGO} key={etnia.etN_CODIGO}>{etnia.etN_DESCRIPCION}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <hr />
                                                    <div className="d-flex justify-content-end">
                                                        <button className="btn btn-primary mx-2" type="submit"><img className="icon mx-1 whiteIcon" src={AgendarIcon} alt="Guardar" />Grabar y Agendar</button>
                                                    </div>
                                                </form>
                                            )
                                        }
                                    </div>
                                ) : (store.eleccionBusqueda === "nombre") ? (
                                    <div>
                                        {
                                            //! TABLA DE BUSQUEDA POR NOMBRE --> HACERLO MODAL
                                            (store.nombre !== '' || store.ap_paterno !== '' || store.ap_materno !== '') && (
                                                <div className="boxes tabla scrollableTable scrollTableY">
                                                    <table className="table table-striped table-hover text-small">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th className="resized" scope="col">Nombre</th>
                                                                <th scope="col">Ap. Paterno</th>
                                                                <th scope="col">Ap. Materno</th>
                                                                <th scope="col">Rut</th>
                                                                <th scope="col">Teléfono</th>
                                                                <th scope="col">Correo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                // Formulario Usuario Encontrado por Nombre
                                                                store.pacientePorNombre ? store.pacientePorNombre.map((pcxnmb) => {
                                                                    return (
                                                                        <tr className="text-center" name="pacienteInfo" value={pcxnmb} key={pcxnmb.fcP_IDENT} onClick={() => actions.handleChangeEspecial(pcxnmb)} >
                                                                            <th scope="row">{pcxnmb.pgL_NOMBRES} </th>
                                                                            <td>{pcxnmb.pgL_PATERNO}</td>
                                                                            <td>{pcxnmb.pgL_MATERNO}</td>
                                                                            <td>{pcxnmb.pgL_NRO_DOCUMENTO}</td>
                                                                            <td>{pcxnmb.pgL_CELULAR}</td>
                                                                            <td>{pcxnmb.pgL_EMAIL}</td>
                                                                        </tr>
                                                                    )
                                                                }) : (
                                                                    null
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        }
                                        <div className="row mt-3">
                                            {
                                                (store.pacienteInfo !== null && store.pacienteInfo.length !== 0) ? (
                                                    // Formulario Usuario Encontrado por Nombre
                                                    <form key={store.codIdent} onSubmit={null} >
                                                        <p>Encontrado</p>
                                                        <div className="d-flex">
                                                            {/* Col. Izq. */}
                                                            <span className="col-md-4 offset-md-1 col-sm-12">
                                                                {/* Tipo Docto => Select */}
                                                                <span className="input-group my-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Docto.</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="tipoDoc" onChange={e => actions.handleChange(e)} value={store.pacienteInfo.pgL_TIPO_DOCUMENTO}>
                                                                            <option value="">Elige Documento</option>
                                                                            {
                                                                                store.tipoDocto && store.tipoDocto.map(docto => {
                                                                                    return (
                                                                                        <option value={docto.tD_ID} key={docto.tD_ID}>{docto.tD_DESCP}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Nombre => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Nombre</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="nombrePaciente" value={store.nombrePaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Ap. Paterno => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Apellido Paterno</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="apPaternoPaciente" value={store.apPaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Ap. Materno => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Apellido Materno</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="apMaternoPaciente" value={store.apMaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Teléfono => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Teléfono</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="celularPaciente" value={store.celularPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Correo => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Correo</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" name="emailPaciente" value={store.emailPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Fecha Nacimiento => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Fecha Nacimiento</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" placeholder="dd/mm/aaaa" name="fecNacPaciente" value={fechaFormateada} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                            </span>
                                                            {/* Col. Der. */}
                                                            <span className="col-md-4 offset-md-1 col-12">
                                                                {/* Rut => Input */}
                                                                <span className="input-group my-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">RUT</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.pacienteInfo.pgL_NRO_DOCUMENTO} placeholder="12345678-9" />
                                                                    </div>
                                                                </span>
                                                                {/* Sexo => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Sexo</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="sexoPaciente" value={store.sexoPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoSexo && store.tipoSexo.map(sexo => (
                                                                                    <option value={sexo.seX_ID} key={sexo.seX_ID}>{sexo.seX_DESCP}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Estado Civíl => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Estado Civil</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="estCivilPaciente" value={store.estCivilPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoEstadosCiviles && store.tipoEstadosCiviles.map(estadoCivil => (
                                                                                    <option value={estadoCivil.eC_ID} key={estadoCivil.eC_ID}>{estadoCivil.eC_DESCP}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Dirección => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Dirección</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" name="direccionPaciente" value={store.direccionPaciente} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* País => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Pais</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="paisElegido" value={store.paisElegido} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value="">--Elige País--</option>
                                                                            <option value="213">CHILE</option>
                                                                            {
                                                                                store.nombrePais && store.nombrePais.map(pais => {
                                                                                    return (
                                                                                        <option value={Math.floor(parseFloat(pais.pS_CODIGO))} key={pais.pS_CODIGO}>{pais.pS_DESCRIPCION}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Comuna => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Comuna</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="codIdent" value={store.codIdent} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value="">--Elige Comuna--</option>
                                                                            {
                                                                                store.nombreComunas && store.nombreComunas.map(comuna => {
                                                                                    return (
                                                                                        <option value={comuna.coM_CODIGO} key={comuna.coM_CODIGO}>{comuna.coM_DESCRIPCION}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <option value=""></option>
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Etnia => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Etnia</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="etniaPaciente" value={store.etniaPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoEtnias && store.tipoEtnias.map(etnia => (
                                                                                    <option value={etnia.etN_CODIGO} key={etnia.etN_CODIGO}>{etnia.etN_DESCRIPCION}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-success" onClick={e => actions.postModificarPaciente(e)}><img className="icon mx-1 whiteIcon" src={ModificarIcon} alt="Modificar" /></button>
                                                            <button className="btn btn-warning mx-2" type="submit"><img className="icon mx-1 whiteIcon" src={AgendarIcon} alt="Guardar" /></button>
                                                        </div>
                                                    </form>
                                                ) : (
                                                    // Formulario Usuario No Encontrado por NOMBRE (Escenario Base)
                                                    <form onSubmit={e => { actions.postAgregarPaciente(e) }}>
                                                        <div className="d-flex">
                                                            <p>No Encontrado</p>
                                                            {/* Col. Izq. */}
                                                            <span className="col-md-4 offset-md-1 col-sm-12">
                                                                {/* Tipo Docto => Select */}
                                                                <span className="input-group my-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Docto.</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="tipoDoc" onChange={e => actions.handleChange(e)} value={store.tD_ID}>
                                                                            <option value="">Elige Documento</option>
                                                                            {
                                                                                store.tipoDocto && store.tipoDocto.map(docto => {
                                                                                    return (
                                                                                        <option value={docto.tD_ID} key={docto.tD_ID}>{docto.tD_DESCP}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Nombre => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Nombre</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="pgL_NOMBRES" value={store.pgL_NOMBRES} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Ap. Paterno => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Apellido Paterno</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="pgL_PATERNO" value={store.pgL_PATERNO} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Ap. Materno => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Apellido Materno</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="pgL_MATERNO" value={store.pgL_MATERNO} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Teléfono => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className='col-5'>
                                                                        <span className="input-group-text">Teléfono</span>
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <input type="text" className="form-control" name="pgL_CELULAR" value={store.pgL_CELULAR} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Correo => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Correo</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" name="pgL_EMAIL" value={store.pgL_EMAIL} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* Fecha Nacimiento => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Fecha Nacimiento</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" placeholder="dd/mm/aaaa" name="pgL_FEC_NACIM" value={store.pgL_FEC_NACIM} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                            </span>
                                                            {/* Col. Der. */}
                                                            <span className="col-md-4 offset-md-1 col-12">
                                                                {/* Rut => Input */}
                                                                <span className="input-group my-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">RUT</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.docto} placeholder="12345678-9" />
                                                                    </div>
                                                                </span>
                                                                {/* Sexo => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Sexo</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="pgL_SEXO" value={store.pgL_SEXO} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoSexo && store.tipoSexo.map(sexo => (
                                                                                    <option value={sexo.seX_ID} key={sexo.seX_ID}>{sexo.seX_DESCP}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Estado Civíl => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Estado Civil</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="pgL_ESTCIVIL" value={store.pgL_ESTCIVIL} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoEstadosCiviles && store.tipoEstadosCiviles.map(estadoCivil => (
                                                                                    <option value={estadoCivil.eC_ID} key={estadoCivil.eC_ID}>{estadoCivil.eC_DESCP}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Dirección => Input */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Dirección</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <input type="text" className="form-control" name="pgL_DIRECCION" value={store.pgL_DIRECCION} onChange={e => { actions.handleChange(e) }} />
                                                                    </div>
                                                                </span>
                                                                {/* País => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Pais</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="pS_CODIGO" value={store.pS_CODIGO} onChange={e => actions.handleChange(e)}>
                                                                            <option value="">--Elige País--</option>
                                                                            <option value="213">CHILE</option>
                                                                            {
                                                                                store.nombrePais && store.nombrePais.map(pais => {
                                                                                    return (
                                                                                        <option value={Math.floor(parseFloat(pais.pS_CODIGO))} key={pais.pS_CODIGO}>{pais.pS_DESCRIPCION}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Comuna => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Comuna</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="coM_CODIGO" value={store.coM_CODIGO} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value="">--Elige Comuna--</option>
                                                                            {
                                                                                store.nombreComunas && store.nombreComunas.map(comuna => {
                                                                                    return (
                                                                                        <option value={comuna.coM_CODIGO} key={comuna.coM_CODIGO}>{comuna.coM_DESCRIPCION}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <option value=""></option>
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                                {/* Etnia => Select */}
                                                                <span className="input-group mb-3">
                                                                    <div className="col-5">
                                                                        <span className="input-group-text">Etnia</span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control m-0" name="pgL_ETNIA" value={store.pgL_ETNIA} onChange={e => { actions.handleChange(e) }}>
                                                                            <option value=""></option>
                                                                            {
                                                                                store.tipoEtnias && store.tipoEtnias.map(etnia => (
                                                                                    <option value={etnia.etN_CODIGO} key={etnia.etN_CODIGO}>{etnia.etN_DESCRIPCION}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-primary mx-2" type="submit"><img className="icon mx-1 whiteIcon" src={AgendarIcon} alt="Guardar" />Grabar y Agendar</button>
                                                        </div>
                                                    </form>
                                                )
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center my-5">
                                        <h4 className="my-5">Elije un método de busqueda de paciente</h4>
                                    </div>
                                )
                            }
                        </span>
                    </span>
                </span>
            </span>
        </div>
    );
}

export default AgendaInterna;