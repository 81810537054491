// import React, { useContext, useEffect, useState } from 'react';
// import { Context } from '../../content/appContext';

// import Select from 'react-select';

// const ComboBox = () => {
//     const {store, actions} = useContext(Context);
    
//     const [selectedOption, setSelectedOption] = useState('');
//     const [options, setOptions] = useState([]);
    
//     useEffect(() => {
//         setOptions(store.apiPrueba);
//     }, [store.apiPrueba]);
//     // const options = store.prestacionesServicioCentro;

//     console.log(store.apiPrueba);
//     const getOptionLabel = (option) => `${option.name} ${option.phone}`;

//     const handleChange1 = (selectedOption) => {
//         setSelectedOption(selectedOption);
//         console.log('Option selected:', selectedOption);
//       };

//     useEffect(() => {
//         // actions.getPrestacionServicioCentro();
//         actions.getPrueba();
//     }, []);

//     return (
//         <div>
//             <div className="my-5 py-5 mx-5">
//                 <Select 
//                     options={options} 
//                     // name="prestacionSerCen" 
//                     getOptionLabel={getOptionLabel} 
//                     onChange={e => {
//                         handleChange1(e); 
//                         // console.log(handleChange1);
//                         // actions.handleChangeInputs(e);
//                     }} 
//                     value={selectedOption} 
//                     // value={store.prestacionSerCen} 
//                 />
//             </div>
//         </div>
//     );
// }

// export default ComboBox;

import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../content/appContext';

import Select from 'react-select';

const ComboBox = () => {
    const { store, actions } = useContext(Context);
    
    const [selectedOptionMedicoTratante, setSelectedOptionMedicoTratante] = useState([]);
    const [optionsMedicoTratante, setOptionsMedicoTratante] = useState([]);

    useEffect(() => {
        setOptionsMedicoTratante(store.medicosTratantesCompleto.map(option => ({ value: Math.floor(parseFloat(option.meD_RUT)), label: `${Math.floor(parseFloat(option.meD_RUT))} - ${option.meD_NOMBRE} ${option.meD_APELLIDO_P} ${option.meD_APELLIDO_M}` })));
    }, [store.medicosTratantesCompleto]);

    // console.log(store.medicosTratantesCompleto)

    const handleChangeMedicoTratante = (selectedOption) => {
        setSelectedOptionMedicoTratante(selectedOption);
        console.log('Option selected:', selectedOption);
    };

    useEffect(() => {
        actions.getMedicosTratantesCompleto();
    }, []);

    return (
        <div>
            <div className="my-5 py-5 mx-5">
                <Select 
                    className="comboBoxPrestaciones"
                    options={optionsMedicoTratante} 
                    onChange={handleChangeMedicoTratante} 
                    value={selectedOptionMedicoTratante} 
                    // isClearable
                />
            </div>
        </div>
    );
}

export default ComboBox;

