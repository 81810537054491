import React from 'react';
import { Link } from 'react-router-dom';

const EmpresasFacturadorasEditar = () => {
    return (
        <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Editar Empresa Facturadora</h2>
                <div className="col-8 offset-2">
                    <form action="">
                        <div className="row">
                            <div className="col-5">
                                <p>Cod.</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Nombre</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>País</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Ciudad</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Dirección</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Teléfono</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Otro Teléfono</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Correo</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Término de Vigencia</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Factura Exento</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>

                        <th scope="col"></th>
                        <Link className="btn btn-danger me-2" to="/fz-empresas-facturadoras">Atrás</Link>
                        <Link className="btn btn-warning mx-2" to="/fz-empresas-facturadoras/modificar/1">Editar</Link>
                        {/* <button className="btn btn-success" type="submit">Agregar</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EmpresasFacturadorasEditar;