import React, {
    // useCallback,
    useContext,
    // useMemo,
    useEffect, 
    useState
} from 'react';
import { Context } from '../../content/appContext';

// ICONS
import CheckIcon from '../../static/icons/checkIcon.png';
import BorrarIcon from '../../static/icons/borrarIcon.png';
import FavoriteIcon from '../../static/icons/favoriteIcon.png';
import AddFavoriteIcon from '../../static/icons/addFavoriteIcon.png';
import PrinterIcon from '../../static/icons/printerIcon.png';

// STYLES
import '../../styles/fichaPaciente.css';

// FAKEAPI
import CitasTomadas from '../../content/fake_db/fakeCitasTomadas';

const FichaPaciente = () => {

    const {
        store,
        // actions 
    } = useContext(Context);

    //! FUNCIONES AUXILIARES DEL COMPONENTE
    //? APROXIMAR UN CALCULO A UN DECIMAL
    const aproximarUnDecimal = (numero) => {
        let numeroAproximado = parseFloat(numero).toFixed(1);
        return numeroAproximado;
    };

    //! FILTRO DE CITASTOMADAS USANDO LOS CHECKBOXES
    const [filtros, setFiltros] = useState({
        agendadas: true,
        espera: true,
        enAtencion: true,
        atendido: true,
        canceladas: true
    });

    const toggleFiltro = filtro => {
        setFiltros(prevFiltros => ({
            ...prevFiltros,
            [filtro]: !prevFiltros[filtro]
        }));
    };

    const filtrarCitas = cita => {
        return filtros[cita.estado];
    };

    //! FUNCIONALIDAD PARA ELEGIR UN PACIENTE DE LA LISTA
    const [citaElegida, setCitaElegida] = useState([]);

    const elegirCita = (citaTomada) => {
        setCitaElegida(citaTomada);
    }

    //! TRATAMIENTO FAKE API --> POR ELIMINAR
    var hoy = new Date();
    // Convertir la fecha a formato ISOString
    var fechaHoy = hoy.toISOString().split('T')[0]; // Obtiene la parte de la fecha sin la parte de la hora
    // Iterar sobre cada objeto en el array CitasTomadas y establecer la fecha actual
    CitasTomadas.forEach(cita => {
        cita.fecha = fechaHoy;
    });

    function calcularEdad(fechaNacimiento, fechaActual) {
        // Convertir las fechas a objetos Date
        var nacimiento = new Date(fechaNacimiento);
        var actual = new Date(fechaActual);

        // Calcular años
        var anios = actual.getFullYear() - nacimiento.getFullYear();
        // Ajustar años en caso de que aún no haya cumplido nuevamente años
        if (actual.getMonth() < nacimiento.getMonth() ||
            (actual.getMonth() === nacimiento.getMonth() && actual.getDate() < nacimiento.getDate())) {
            anios--;
        }

        // Calcular meses
        var meses = actual.getMonth() - nacimiento.getMonth();
        if (meses < 0) {
            meses += 12;
        }

        // Calcular días
        var dias = actual.getDate() - nacimiento.getDate();
        if (dias < 0) {
            // Obtener el último día del mes anterior
            var ultimoDiaMesAnterior = new Date(actual.getFullYear(), actual.getMonth(), 0).getDate();
            dias += ultimoDiaMesAnterior;
            meses--;
        }

        // Retornar la edad en el formato requerido
        return `${anios} años ${meses} meses ${dias} días`;
    }

    //! ACTIVAR PACIENTE
    const abrirConsulta = () => {
        // Actualiza el estado de la cita elegida a "enAtencion"
        setCitaElegida(prevCita => ({
            ...prevCita,
            estado: "enAtencion"
        }));
    };

    //! CAMBIAR AREA DE TRABAJO
    const [areaTrabajo, setAreaTrabajo] = useState("antecedentes");

    const cambiarArea = (e) => {
        const nuevaArea = e.target.value;
        setAreaTrabajo(nuevaArea);
    }

    //! CAMBIAR ANTECEDENTES MORBIDOS
    const [antecedentesMorbidos, setAntecedentesMorbidos] = useState("cronicos");

    const cambiarAntecedentes = (e) => {
        const cambiarArea = e.target.value;
        setAntecedentesMorbidos(cambiarArea);
    }

    //! PRESION ARTERIAL
    const [ sistolica, setSistolica ] = useState("");
    const [ diastolica, setDiastolica ] = useState("");
    const [ presionArterialMedia, setPresionArterialMedia ] = useState("");

    const presionSistolica = (e) => {
        let nuevaSistolica = e.target.value;
        setSistolica(nuevaSistolica);
    }

    const presionDiastolica = (e) => {
        let nuevaDiastolica = e.target.value;
        setDiastolica(nuevaDiastolica);
    }

    useEffect(() => {
        if ( sistolica && diastolica) {
            let calcularPAM = (2/3 * diastolica) + (1/3 * sistolica);
            let pamAprox = aproximarUnDecimal(calcularPAM);
            setPresionArterialMedia(pamAprox);
        } else {
            return;
        }
    }, [sistolica, diastolica]);

    //! CALCULO IMC
    const [ peso, setPeso ] = useState("");
    const [ talla, setTalla ] = useState("");
    const [ indiceMasaCorporal, setIndiceMasaCorporal ] = useState("");
    const [ resultadoIMC, setResultadoIMC ] = useState("");

    const ingresoPeso = (e) => {
        const nuevoPeso = e.target.value.replace(",", ".");
        setPeso(nuevoPeso);
    }

    const ingresoTalla = (e) => {
        const nuevaTalla = e.target.value.replace(",", ".");
        setTalla(nuevaTalla);
    }

    useEffect(() => {
        if (peso && talla) {
            const nuevoIMC = peso / ( talla * talla );
            const imcAprox = aproximarUnDecimal(nuevoIMC);
            setIndiceMasaCorporal(imcAprox);
        
            if (nuevoIMC < 18.5) {
                setResultadoIMC("Bajo Peso");
            } else if (nuevoIMC >= 18.5 && nuevoIMC < 24.9) {
                setResultadoIMC("Normal");
            } else if (nuevoIMC >= 24.9 && nuevoIMC < 29.9) {
                setResultadoIMC("Sobrepeso");
            } else {
                setResultadoIMC("Obesidad");
            }
        } else {
            setResultadoIMC("");
        }
    }, [ peso, talla ])  
    
    console.log("hola");

    return (
        <div className="container fichaPaciente">
            <div className="titulo text-center">
                {/* <h3>Ficha Paciente</h3> */}
                <div className="row">

                </div>
            </div>
            <div className="row">
                <div className="col-sm-3 col-12 panelControl">
                    <div className="filtrador">
                        <div className="mx-auto px-3 d-flex">
                            <div className="px-1">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="agendadas" checked={filtros.agendadas} onChange={() => toggleFiltro('agendadas')} />
                                    <label className="form-check-label">Agendado</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="espera" checked={filtros.espera} onChange={() => toggleFiltro('espera')} />
                                    <label className="form-check-label">En Espera</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="enAtencion" checked={filtros.enAtencion} onChange={() => toggleFiltro('enAtencion')} />
                                    <label className="form-check-label">En Atención</label>
                                </div>
                            </div>
                            <div className="px-1">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="atendido" checked={filtros.atendido} onChange={() => toggleFiltro('atendido')} />
                                    <label className="form-check-label">Atendido</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="canceladas" checked={filtros.canceladas} onChange={() => toggleFiltro('canceladas')} />
                                    <label className="form-check-label">Canceladas</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablaPacientes">
                        <table className="table table-striped table-hover scrollableTable scrollTableY">
                            <thead className="text-center">
                                <tr>
                                    <th scope="col">Hora</th>
                                    <th scope="col">Nombre</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {
                                    CitasTomadas.filter(filtrarCitas).sort((a, b) => {
                                        // Separar las horas y minutos de las cadenas de tiempo y convertirlas a números enteros
                                        const [horaA, minutosA] = a.hora.split(':').map(Number);
                                        const [horaB, minutosB] = b.hora.split(':').map(Number);
                                        // Comparar las horas y los minutos para determinar el orden
                                        if (horaA === horaB) {
                                            return minutosA - minutosB; // Ordenar por minutos si las horas son iguales
                                        } else {
                                            return horaA - horaB; // Ordenar por horas
                                        }
                                    }).map(citaTomada => {
                                        return (
                                            <tr onClick={e => elegirCita(citaTomada)} key={citaTomada.id}>
                                                <td>{citaTomada.hora}</td>
                                                <td>{citaTomada.nombre} {citaTomada.apellido_paterno} {citaTomada.apellido_materno}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <button className="btn btn-warning btnAbreConsulta" onClick={abrirConsulta}>Abrir Consulta</button>
                </div>
                <div className="col-sm-9 col-12 tablaTrabajo p-0">
                    <div className="col-12 areaPaciente d-flex">
                        <div className="col-9 info1">
                            <div className="d-flex" >
                                <div className="w-50">
                                    <p><strong>Paciente: {citaElegida.nombre} {citaElegida.apellido_paterno} {citaElegida.apellido_materno}</strong></p>
                                </div>
                                <span>
                                    <p><strong>Edad: </strong>{(citaElegida.length !== 0) ? calcularEdad(citaElegida.fecha_nacimiento, citaElegida.fecha) : ""}</p>
                                    <p><strong>Sexo: </strong>{(citaElegida.sexo === "m") ? "MASCULINO" : (citaElegida.sexo === "f") ? "FEMENINO" : (citaElegida.sexo === "i") ? "SIN ESPECIFICAR" : ""}</p>
                                </span>
                            </div>
                            <div className="info2 d-flex justify-content-space-between">
                                <button className={areaTrabajo === "antecedentes" ? "btn btn-success smallerBtn" : "btn btn-primary smallerBtn"} value="antecedentes" onClick={e => cambiarArea(e)}>Antecedentes Clínicos</button>
                                <button className={areaTrabajo === "consulta" ? "btn btn-success smallerBtn" : "btn btn-primary smallerBtn"} value="consulta" onClick={e => cambiarArea(e)}>Consulta Médica</button>
                                <button className={areaTrabajo === "historico" ? "btn btn-success smallerBtn" : "btn btn-primary smallerBtn"} value="historico" onClick={e => cambiarArea(e)}>Historico de Consultas</button>
                                {/* <button className={areaTrabajo === "finalizar" ? "btn btn-success smallerBtn" : "btn btn-primary smallerBtn"} value="finalizar" onClick={e => cambiarArea(e)}>Finalizar Atención</button>
                                <button className={areaTrabajo === "devolver" ? "btn btn-success smallerBtn" : "btn btn-primary smallerBtn"} value="devolver" onClick={e => cambiarArea(e)}>Devolver Atención</button> */}
                            </div>
                        </div>
                        <div className="col-3 info3">
                            <p><strong>Núm. Ficha: </strong>{citaElegida.num_ficha}</p>
                            <p><strong>Núm. Atencion: </strong>{citaElegida.id}</p>
                            <p><strong>Fecha: </strong>{citaElegida.fecha} {citaElegida.hora}</p>
                            <p><strong>Estado: </strong>{(citaElegida.estado === "agendadas") ? "AGENDADO" : (citaElegida.estado === "espera") ? "EN ESPERA" : (citaElegida.estado === "enAtencion") ? "EN ATENCION" : (citaElegida.estado === "atendido") ? "ATENDIDO" : (citaElegida.estado === "canceladas") ? "CANCELADO" : ""}</p>
                        </div>
                    </div>
                    {
                        (areaTrabajo === "antecedentes") ? (
                            <div className="d-flex areaConsulta">
                                <div className="col-2 areaConsultaGeneral">
                                    <div className="d-flex flex-column">
                                        <button className={(antecedentesMorbidos === "cronicos") ? "btn btn-warning smallerBtn w-100" : "btn btn-secondary smallerBtn w-100"} value="cronicos" onClick={e => cambiarAntecedentes(e)}>Crónicos</button>
                                        <button className={(antecedentesMorbidos === "cirugias") ? "btn btn-warning smallerBtn w-100" : "btn btn-secondary smallerBtn w-100"} value="cirugias" onClick={e => cambiarAntecedentes(e)}>Cirugías</button>
                                        <button className={(antecedentesMorbidos === "medicamentos") ? "btn btn-warning smallerBtn w-100" : "btn btn-secondary smallerBtn w-100"} value="medicamentos" onClick={e => cambiarAntecedentes(e)}>Medicamentos</button>
                                        <button className={(antecedentesMorbidos === "alergias") ? "btn btn-warning smallerBtn w-100" : "btn btn-secondary smallerBtn w-100"} value="alergias" onClick={e => cambiarAntecedentes(e)}>Alergias</button>
                                        <button className={(antecedentesMorbidos === "hospitalizaciones") ? "btn btn-warning smallerBtn w-100" : "btn btn-secondary smallerBtn w-100"} value="hospitalizaciones" onClick={e => cambiarAntecedentes(e)}>Hospitalizaciones</button>
                                        <button className={(antecedentesMorbidos === "otros") ? "btn btn-warning smallerBtn w-100" : "btn btn-secondary smallerBtn w-100"} value="otros" onClick={e => cambiarAntecedentes(e)}>Otros</button>
                                    </div>
                                </div>
                                <div className="col-10 areaConsultaEspecifica">
                                    {
                                        (antecedentesMorbidos === "cronicos") ? (
                                            <div className="areaConsulta">
                                                <div className="d-flex justify-content-between">
                                                    <p>Antecedentes Cronicos</p>
                                                    <button className="btn btn-danger smallerBtn me-5" data-bs-toggle="modal" data-bs-target="#modalAgregarAntecedentesCronicos">Agregar</button>
                                                </div>
                                                <div className="tablaAntecedentesCronicos">
                                                    <table className="table table-striped m-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Diagnostico</th>
                                                                <th scope="col">Fecha Registro</th>
                                                                <th scope="col">Profesional Registra</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (antecedentesMorbidos === "cirugias") ? (
                                            <div className="areaConsulta">
                                                <div className="d-flex justify-content-between">
                                                    <p>Antecedentes de Cirugías</p>
                                                    <button className="btn btn-danger smallerBtn me-5" data-bs-toggle="modal" data-bs-target="#modalAgregarAntecedentesCirugias">Agregar</button>
                                                </div>
                                                <div className="tablaAntecedentesCronicos">
                                                    <table className="table table-striped m-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Diagnostico</th>
                                                                <th scope="col">Fecha Registro</th>
                                                                <th scope="col">Profesional Registra</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (antecedentesMorbidos === "medicamentos") ? (
                                            <div className="areaConsulta">
                                                <div className="d-flex justify-content-between">
                                                    <p>Antecedentes de Medicamentos</p>
                                                    <button className="btn btn-danger smallerBtn me-5" data-bs-toggle="modal" data-bs-target="#modalAgregarAntecedentesMedicamentos">Agregar</button>
                                                </div>
                                                <div className="tablaAntecedentesCronicos">
                                                    <table className="table table-striped m-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Diagnostico</th>
                                                                <th scope="col">Fecha Registro</th>
                                                                <th scope="col">Profesional Registra</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (antecedentesMorbidos === "alergias") ? (
                                            <div className="areaConsulta">
                                                <div className="d-flex justify-content-between">
                                                    <p>Antecedentes de Alergias</p>
                                                    <button className="btn btn-danger smallerBtn me-5" data-bs-toggle="modal" data-bs-target="#modalAgregarAntecedentesAlergias">Agregar</button>
                                                </div>
                                                <div className="tablaAntecedentesCronicos">
                                                    <table className="table table-striped m-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Diagnostico</th>
                                                                <th scope="col">Fecha Registro</th>
                                                                <th scope="col">Profesional Registra</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (antecedentesMorbidos === "hospitalizaciones") ? (
                                            <div className="areaConsulta">
                                                <div className="d-flex justify-content-between">
                                                    <p>Antecedentes de Hospitalizaciones Históricas</p>
                                                    <button className="btn btn-danger smallerBtn me-5" data-bs-toggle="modal" data-bs-target="#modalAgregarAntecedentesHospitalizaciones">Agregar</button>
                                                </div>
                                                <div className="tablaAntecedentesCronicos">
                                                    <table className="table table-striped m-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Diagnostico</th>
                                                                <th scope="col">Fecha Registro</th>
                                                                <th scope="col">Profesional Registra</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (antecedentesMorbidos === "otros") ? (
                                            <div className="areaConsulta">
                                                <div className="d-flex justify-content-between">
                                                    <p>Otros Antecedentes</p>
                                                    <button className="btn btn-danger smallerBtn me-5" data-bs-toggle="modal" data-bs-target="#modalAgregarAntecedentesOtros">Agregar</button>
                                                </div>
                                                <div className="tablaAntecedentesCronicos">
                                                    <table className="table table-striped m-2">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Diagnostico</th>
                                                                <th scope="col">Fecha Registro</th>
                                                                <th scope="col">Profesional Registra</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-center">
                                                <p>Elige un Antecedente que quieras revisar o modificar</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        ) : (areaTrabajo === "consulta") ? (
                            <div className="d-flex areaConsulta">
                                <div className="col-6 areaConsultaGeneral px-5">
                                    {/* <div className="d-flex">
                                        <div className="col-4"> */}
                                            <p>Motivo de Consulta: </p>
                                        {/* </div>
                                        <div className="col-8 pe-3"> */}
                                            <input className="form-control my-3" type="text" />
                                        {/* </div>
                                    </div> */}
                                    {/* <div className="d-flex mt-3">
                                        <div className="col-4"> */}
                                            <p>Anamnesis y Exámen Físico: </p>
                                        {/* </div>
                                        <div className="col-8 pe-3"> */}
                                            <textarea className="form-control my-3" type="text" rows={9} />
                                        {/* </div>
                                    </div> */}
                                </div>
                                <div className="col-6 areaConsultaEspecifica">
                                    <p>Signos Vitales</p>
                                    <div className="signosVitales d-flex">
                                        <div className="col-7">
                                            <div className="d-flex my-1">
                                                <p>P/A:</p>
                                                <input type="text" className="form-control w-50p mx-2 text-small" onChange={e => presionSistolica(e)} />
                                                <p>/</p>
                                                <input type="text" className="form-control w-50p mx-2 text-small" onChange={e => presionDiastolica(e)} />
                                                <p>mmHg</p>
                                            </div>
                                            <div className="d-flex">
                                                <p>PAM:</p>
                                                <input type="text" className="form-control w-50p mx-2 text-small" value={presionArterialMedia.replace(".", ",")} disabled />
                                                <p>mmHg</p>

                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="d-flex">
                                                <p>T°:</p>
                                                <input type="text" className="form-control w-50p mx-2 text-small" />
                                                <p>°C</p>
                                            </div>
                                            <div className="d-flex my-1">
                                                <p>F/C:</p>
                                                <input type="text" className="form-control w-50p mx-2 text-small" />
                                                <p>X'</p>
                                            </div>
                                            <div className="d-flex">
                                                <p>F/R:</p>
                                                <input type="text" className="form-control w-50p mx-2 text-small" />
                                                <p>X'</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Control Nutricional</p>
                                    <div className="controlNutricional">
                                        <div className="d-flex">
                                            <div className="col-6">
                                                <div className="d-flex my-1">
                                                    <p>Peso:</p>
                                                    <input type="text" className="form-control w-50p mx-2 text-small" onChange={(e) => ingresoPeso(e)} />
                                                    <p>Kgs</p>
                                                </div>
                                                <div className="d-flex my-1">
                                                    <p>Talla:</p>
                                                    <input type="text" className="form-control w-50p mx-2 text-small" onChange={(e) => ingresoTalla(e)} />
                                                    <p>Mts</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex my-1">
                                                    <p>IMC:</p>
                                                    <input type="text" className="form-control w-50p mx-2 text-small" value={indiceMasaCorporal.replace(".", ",")} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mx-2">
                                            <p>Evaluación Nutricional</p>
                                            <input type="text" className="form-control my-2 text-small" value={resultadoIMC} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (areaTrabajo === "historico") ? (
                            <div className="d-flex areaConsulta">
                                <div className="col-6 areaConsultaGeneral">
                                    <h5>Histórico</h5>
                                </div>
                                <div className="col-6 areaConsultaEspecifica">
                                    <h5>Histórico 2</h5>
                                </div>
                            </div>
                        ) : (areaTrabajo === "finalizar") ? (
                            <div className="d-flex justify-content-center align-items-center areaConsulta">
                                <div className="mx-auto text-center my-5">
                                    <p>¿Esta seguro que desea Guardar y Finalizar la Consulta?</p>
                                    <button className="btn btn-secondary my-5" onClick={null}>Guardar y Finalizar</button>
                                </div>
                            </div>
                        ) : (areaTrabajo === "devolver") ? (
                            <div className="d-flex justify-content-center align-items-center areaConsulta">
                                <div className="mx-auto text-center">
                                    <p>¿Esta seguro que desea Cerrar la Consulta sin guardar?</p>
                                    <button className="btn btn-secondary my-5" onClick={null}>Finalizar Sin Guardar</button>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                <p>Elige un área de trabajo</p>
                            </div>
                        )
                    }
                    <div className="col-12 areaResultadoConsulta">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#diagnosticos-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Diagnóstico</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#ordenClinica-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Ordenes Clínicas</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#recetasMedicas-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Recetas Médicas</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#indicaciones-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">Indicaciones</button>
                            </li>
                        </ul>
                        <div className="tab-content my-3" id="myTabContent">
                            <div className="tab-pane fade show active" id="diagnosticos-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                <div className="d-flex">
                                    <div className="col-4">
                                        <select class="form-select my-1 text-small">
                                            <option selected>Tipo de Diagnóstico</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <select class="form-select text-small">
                                            <option selected>Diagnóstico</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="col-8 d-flex justify-content-between">
                                        <div className="ms-2">
                                            <button className="btn btn-primary mx-1"><img className="iconMini" src={CheckIcon} alt="Agregar" /></button>
                                            <button className="btn btn-secondary"><img className="iconMini" src={BorrarIcon} alt="Borrar" /></button>
                                        </div>
                                        <div className="me-2">
                                            <button className="btn btn-primary mx-1"><img className="iconMed" src={AddFavoriteIcon} alt="Agregar a Favoritos" /></button>
                                            <button className="btn btn-secondary"><img className="iconMed" src={FavoriteIcon} alt="Favoritos" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-2 my-2">
                                    <table className="table table-striped table-hover scrollableTable scrollTableY">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sel.</th>
                                                <th scope="col">Código</th>
                                                <th scope="col">Diagnóstico</th>
                                                <th scope="col">Observaciones y Especificaciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>101266</th>
                                                <th>Gastritis, No Identificada</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>101267</th>
                                                <th>Pancreatitis, No Identificada</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>101270</th>
                                                <th>Úlcera Gástrica, No Identificada</th>
                                                <th></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ordenClinica-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                <div className="d-flex justify-content-between">
                                    <div className="ms-2">
                                        <button className="btn btn-primary">B1</button>
                                        <button className="btn btn-secondary mx-1">B2</button>
                                        <button className="btn btn-secondary"><img className="iconMed" src={FavoriteIcon} alt="Favoritos" /></button>
                                    </div>
                                    <div className="me-2">
                                        <button className="btn btn-primary mx-1"><img className="iconMed" src={BorrarIcon} alt="Borrar" /></button>
                                        <button className="btn btn-secondary"><img className="iconMed" src={PrinterIcon} alt="Imprimir" /></button>
                                    </div>
                                </div>
                                <div className="mx-2 my-2">
                                    <table className="table table-striped table-hover scrollableTable scrollTableY">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sel.</th>
                                                <th scope="col">Código</th>
                                                <th scope="col">Unidad de Exámen</th>
                                                <th scope="col">Exámen</th>
                                                <th scope="col">Observaciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>300122</th>
                                                <th>Imagenes</th>
                                                <th>Rx</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>300145</th>
                                                <th>Laboratorio</th>
                                                <th>Hemograma</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>300199</th>
                                                <th>Laboratorio</th>
                                                <th>Creatina</th>
                                                <th></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="recetasMedicas-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                                <div className="d-flex">
                                    <div className="col-6">
                                        <div className="mx-2 d-flex">
                                            <p>Medicamento</p>
                                            <input className="form-control text-small mx-2" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-6 ms-auto">
                                        <div className="d-flex">
                                            <label htmlFor="">Indicaciones</label>
                                            <input className="form-control text-small mx-2 mb-2" type="text" />
                                        </div>
                                        <div className="d-flex">
                                            <select class="form-select my-1 mx-1 text-small">
                                                <option selected>Fecha</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <input type="checkbox" name="" id="" />
                                            <button className="btn btn-primary mx-1"><img className="iconMed" src={CheckIcon} alt="Agregar" /></button>
                                            <button className="btn btn-secondary"><img className="iconMed" src={BorrarIcon} alt="Borrar" /></button>
                                            <button className="btn btn-secondary mx-1"><img className="iconMed" src={PrinterIcon} alt="Imprimir" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-2 my-2">
                                    <table className="table table-striped table-hover scrollableTable scrollTableY">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sel.</th>
                                                <th scope="col">Fecha</th>
                                                <th scope="col">Medicamento</th>
                                                <th scope="col">Indicaciones</th>
                                                <th scope="col">Retenido</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>15/04/2024</th>
                                                <th>Remedio 1</th>
                                                <th>10mg c/8hrs</th>
                                                <th><input type="checkbox" name="" id="" /></th>
                                            </tr>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>15/04/2024</th>
                                                <th>Remedio 2</th>
                                                <th>100mg c/8hrs</th>
                                                <th><input type="checkbox" name="" id="" /></th>
                                            </tr>
                                            <tr>
                                                <th><input type="checkbox" name="" id="" /></th>
                                                <th>15/04/2024</th>
                                                <th>Remedio 3</th>
                                                <th>150mg c/12hrs</th>
                                                <th><input type="checkbox" name="" id="" /></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="indicaciones-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">
                                <div className="d-flex justify-content-between">
                                    <div className="ms-3">
                                        <p>Indicaciones</p>
                                    </div>
                                    <div className="d-flex me-3">
                                        <input type="checkbox" name="" id="" />
                                        <p className="mx-3">Licencia Médica</p>
                                        <button className="btn btn-primary"><img className="iconMed" src={PrinterIcon} alt="Imprimir" /></button>
                                    </div>
                                </div>
                                <div className="m-2">
                                    <textarea name="" id="" cols="146" rows="8"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary btnFinalizarGuardar my-3 me-3" onClick={null}>Finalizar y Guardar</button>
                        <button className="btn btn-secondary btnFinalizarGuardar my-3 me-3" onClick={null}>Salir Sin Guardar</button>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAgregarAntecedentesCronicos" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Agregar Antecedente Crónico</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-bottom">
                                <div className="col-3">
                                    <p>Diagnóstico</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Fecha</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Observador</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" value={store.usuario} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir sin Guardar</button>
                            <button type="button" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAgregarAntecedentesCirugias" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Agregar Antecedente de Cirugías</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-bottom">
                                <div className="col-3">
                                    <p>Diagnóstico</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Fecha</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Observador</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" value={store.usuario} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir sin Guardar</button>
                            <button type="button" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAgregarAntecedentesMedicamentos" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Agregar Antecedente de Medicamentos</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-bottom">
                                <div className="col-3">
                                    <p>Diagnóstico</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Fecha</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Observador</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" value={store.usuario} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir sin Guardar</button>
                            <button type="button" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAgregarAntecedentesAlergias" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Agregar Antecedente de Alergias</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-bottom">
                                <div className="col-3">
                                    <p>Diagnóstico</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Fecha</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Observador</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" value={store.usuario} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir sin Guardar</button>
                            <button type="button" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAgregarAntecedentesHospitalizaciones" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Agregar Antecedente de Hospitalizaciones</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-bottom">
                                <div className="col-3">
                                    <p>Diagnóstico</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Fecha</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Observador</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" value={store.usuario} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir sin Guardar</button>
                            <button type="button" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalAgregarAntecedentesOtros" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Agregar Otro Antecedente</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-bottom">
                                <div className="col-3">
                                    <p>Diagnóstico</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Fecha</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <div className="col-3">
                                    <p>Observador</p>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control" value={store.usuario} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Salir sin Guardar</button>
                            <button type="button" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default FichaPaciente;