import React from 'react';
import UnderConstruction from '../enContrucción';

const ProfesionalesSalud = () => {
    return(
        <div className="container">
            <h1>Profesionales de Salud</h1>
            <UnderConstruction />
        </div>
    );
}

export default ProfesionalesSalud;