import React, { Component } from 'react';
import getState from './flux';

export const Context = React.createContext(null);

const injectContext = PassedComponent => {
    class StoreWrapper extends Component {
        constructor(props){
            super(props);

            this.state = getState({
                getStore: () => this.state.store,
                getActions: () => this.state.actions,
                setStore: updateStore => this.setState({
                    store: Object.assign(this.state.store, updateStore),
                    actions: {...this.state.actions}
                })
            })
        }

        componentDidMount(){
            // Estructura básica para crear el enrutamiento a una API
            // this.state.actions.getAseguradoras('http://10.40.20.13:5100/api/InstitucionesPrev/listar');
            // this.state.actions.getTipoDocumento('http://10.40.20.13:5105/api/Global/BuscarTiposDocumentos');
            // this.state.actions.getSexo('http://10.40.20.13:5105/api/Global/BuscarSexo');
            // this.state.actions.getEstadoCivil('http://10.40.20.13:5105/api/Global/BuscarEstadosCiviles');
            // this.state.actions.getEtnias('http://10.40.20.13:5105/api/Global/BuscarEtnias');
            // this.state.actions.getPaises('http://10.40.20.13:5105/api/Global/BuscarPaises');
            // this.state.actions.getComunaPorPais('http://10.40.20.13:5105/api/Global/BuscarComunas?codigo_pais=213');
            // this.state.actions.getInstitucionesPrevisionales('http://10.40.20.13:5105/api/Caja/Get_InstPrevisionales');
            // this.state.actions.getServiciosClinicos('http://10.40.20.11:5105/api/Caja/Get_ServiciosClinicos'); --> Se reemplaza con una nueva URL con parametros en el flux
            // this.state.actions.getCentros('http://10.40.20.11:5105/api/Caja/Get_Centros'); --> Centro sin usuario
            // this.state.actions.getCentros('http://10.40.20.11:5105/api/Caja/CentrosUsuario?usuarios=OCASTRO'); --> Centro con Usuario (se modifica en el flux)
            // this.state.actions.getEspecialidades('http://10.40.20.11:5105/api/Caja/Get_Especialidades');
            // this.state.actions.getDisponibilidad('http://10.40.20.11:5108/api/Agenda/GetDisponibilidad?centro=29&tipObjeto=46&esp=89&idProf=8626985&codPrevision=50&codPrestacion=101622');
            
        }

        render(){
            return (
                <Context.Provider value={this.state}>
                    <PassedComponent {...this.props} />
                </Context.Provider>
            )
        }
    }
    return StoreWrapper;
}

export default injectContext; 