import React, { useContext } from 'react';
import { Context } from '../../../../../content/appContext';
import { Link } from 'react-router-dom';

// STYLES
import '../../../../../styles/dmAseguradoras.css';

// FAKEAPI
// import Aseguradorasfk from '../../../../../content/fake_db/fakeAseguradoras';

// ICONS
import lupaIcon from '../../../../../static/icons/lupaIcon.png';
import modIcon from '../../../../../static/icons/modificarIcon.png';
import trashIcon from '../../../../../static/icons/trashIcon.png';

const Aseguradoras = () => {

    const { store } = useContext(Context);
    console.log(store.aseguradoras)

    return (
        <div className="container dmAseguradoras my-3">
            <div className="row center">
                <div className="col">
                    <div className="text-center my-3">
                        <h1>Datos Maestros Aseguradoras</h1>
                        <Link className="btn btn-success" to="/dm-aseguradoras/agregar">Agregar Nuevo</Link>
                    </div>
                    <div className="scrollTable">
                        <table className="table table-striped table-hover align-middle text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Cod.</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Alias</th>
                                    <th scope="col">Id. identificador</th>
                                    <th scope="col">Razón Social</th>
                                    <th scope="col">Inicio Vigencia</th>
                                    <th scope="col">Modo Facturar Ambulatorio</th>
                                    <th scope="col">Modo Facturar Hospitalizado</th>
                                    <th scope="col">Modo Facturar Urgencia</th>
                                    <th scope="col">Clase</th>
                                    <th scope="col">Orden</th>
                                    <th scope="col">Id Externo</th>
                                    <th scope="col">Término Vigencia</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col" className="size">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    store.aseguradoras ? store.aseguradoras.map((Aseguradora) => {
                                        return (
                                            <tr key={Aseguradora.ipr_Codigo_Prev}>
                                                <th scope="row">{Aseguradora.ipr_Codigo_Prev}</th>
                                                <td>{Aseguradora.ipr_Razon_Social}</td>
                                                <td>{Aseguradora.ipr_Alias}</td>
                                                <td>{Aseguradora.ipr_Rut}</td>
                                                <td>{Aseguradora.ipr_Descripcion_Prev}</td>
                                                <td>{Aseguradora.ipr_Forma_Fac_Amb}</td>
                                                <td>{Aseguradora.ipr_Forma_Fac_Hosp}</td>
                                                <td>{Aseguradora.ipr_Forma_Fac_Urg}</td>
                                                <td>{Aseguradora.ipr_Fec_inicio}</td>
                                                <td>{Aseguradora.cas_Codigo}</td>
                                                <td>{Aseguradora.ipr_Orden}</td>
                                                <td>{Aseguradora.ipr_Id_Externo}</td>
                                                <td>{Aseguradora.ipr_Fec_Termino}</td>
                                                <td>{Aseguradora.ipr_Tipo_Prevision}</td>
                                                <td className="size">
                                                    <Link className="btn btn-success" to={"/aseguradoras/ver/" + Aseguradora.ipr_Codigo_Prev}><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                                    <Link className="btn btn-warning" to={"/aseguradoras/modificar/" + Aseguradora.ipr_Codigo_Prev}><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                                    <Link className="btn btn-danger" to={"/aseguradoras/eliminar/" + Aseguradora.ipr_Codigo_Prev}><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                                </td>
                                            </tr>
                                        )
                                    }) : (
                                        <div className="d-flex justify-content-center text-center my-5">
                                            <div className="spinner-grow spinner-conf my-5" role="status">
                                                <span className="visually-hidden"></span>
                                            </div>
                                        </div>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Aseguradoras;