const Prevision = [
        {
            "id": 1,
            "nombre": "PARTICULAR"
        },
        {
            "id": 2,
            "nombre": "BANMEDICA"
        },
        {
            "id": 3,
            "nombre": "COLMENA"
        },
        {
            "id": 4,
            "nombre": "ISALUD"
        },
        {
            "id": 5,
            "nombre": "FONASA"
        },
        {
            "id": 6,
            "nombre": "CRUZ DEL NORTE"
        },
        {
            "id": 7,
            "nombre": "CRUZBLANCA"
        },
        {
            "id": 8,
            "nombre": "FUNDACION"
        },
        {
            "id": 9,
            "nombre": "NUEVA MASVIDA"
        },
        {
            "id": 10,
            "nombre": "VIDA TRES"
        }
    ]

export default Prevision;