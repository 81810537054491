const Horarios = [
    {
        "id": 1,
        "hora": "8:00"
    },
    {
        "id": 2,
        "hora": "9:00"
    },
    {
        "id": 3,
        "hora": "10:00"
    },
    {
        "id": 4,
        "hora": "11:00"
    },
    {
        "id": 5,
        "hora": "12:00"
    },
    {
        "id": 6,
        "hora": "13:00"
    },
    {
        "id": 7,
        "hora": "14:00"
    },
    {
        "id": 8,
        "hora": "15:00"
    },
    {
        "id": 9,
        "hora": "16:00"
    },
    {
        "id": 10,
        "hora": "17:00"
    },
    {
        "id": 11,
        "hora": "18:00"
    },
    {
        "id": 12,
        "hora": "19:00"
    },
    {
        "id": 13,
        "hora": "20:00"
    },
    {
        "id": 14,
        "hora": "21:00"
    }
]

export default Horarios;