import React from 'react';
import { Link } from 'react-router-dom';

// FAKEDATA
import Citas from '../../content/fake_db/fakeCitas';

const AWEliminarCita = () => {
    return(
        <div className="container">
            <div className="row">
            <div className="col-md-6 col-sm-12">
                    <h3 className="my-5">Eliminar Cita</h3>
                    <form action="" className="ps-5" onClick={null}>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>Tipo de Documento</p>
                            </div>
                            <div className="col-8">
                                <select name="" className="form-select" required>
                                    <option value="" selected disabled>-------</option>
                                    <option value="">RUT</option>
                                    <option value="">Folio Cita</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>N° de Documento</p>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>Nombre</p>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>Apellido Paterno</p>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>Apellido Materno</p>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>Teléfono</p>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 offset-1">
                                <p>Correo</p>
                            </div>
                            <div className="col-8">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <hr className="my-5" />
                        <Link className="btn btn-danger" to="/dashboard">Cancelar</Link>
                        <button type="submit" className="btn btn-success ms-2">Buscar</button>
                    </form>
                </div>
                <div className="col-md-6 col-sm-12">
                    <h3 className="my-5">Agenda Disponible</h3>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Centro</th>
                                <th scope="col">Profesional</th>
                                <th scope="col">Especialidad</th>
                                <th scope="col">Prestación</th>
                                <th scope="col">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Citas ? Citas.map( ( Cita ) => {
                                    return (
                                        <tr>
                                            <th scope="row" key={ Cita.id }>{ Cita.id } </th>
                                            <td>{ Cita.fecha }</td>
                                            <td>{ Cita.hora }</td>
                                            <td>{ Cita.centro }</td>
                                            <td>{ Cita.profesional }</td>
                                            <td>{ Cita.especialidad }</td>
                                            <td>{ Cita.prestacion }</td>
                                            <td><Link className="btn btn-warning" to={"/eliminar-cita/" + Cita.id }>Pre-Eliminar</Link></td>
                                        </tr>
                                    )
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AWEliminarCita;