import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext } from 'react';
import { useEffect } from 'react';

// desde folder content
import injectContext, { Context } from './content/appContext.jsx';

// desde folder components
import Footer from './components/footer.jsx';
import Navbar from './components/navbar.jsx';
import SideBar from './components/sidebar.jsx';

// desde folder views
//! main
import Home from './views/home.jsx';
import NotFound from './views/notfound.jsx';
import Dashboard from './views/dashboard.jsx';
import UnderConstruction from './views/enContrucción.jsx';

//! views > agendaInterna
import AgendaInterna from './views/agendaInterna/agendaInterna.jsx';

//! views > caja
// import Caja from './views/caja/caja.jsx';
import Caja1 from './views/caja/caja1.jsx';
import ValorizarPrestaciones from './views/caja/valorizarPrestaciones.jsx';

//! views > pruebas
import Prueba from './views/testing/prueba.jsx';
import Tabla from './views/testing/tablaDinamica.jsx';
import ComboBox from './views/testing/combobox.jsx';
import Modal from './views/testing/modal.jsx';
import ComboTable from './views/testing/combotable.jsx';
import TablaCero from './views/testing/tablaCero.jsx';
import Spinner from './views/testing/spinner.jsx';
import PruebaIMC from './views/testing/pruebaIMC.jsx';

//! views > fichaPacientes
import FichaPaciente from './views/fichaPaciente/fichaPaciente.jsx';

//! dm_views
import AseguradorasMain from './views/dm_views/aseguradoras.jsx';
import CentrosMedicos from './views/dm_views/centrosMedicos.jsx';
import ConfiguracionCaja from './views/dm_views/configuracionCaja.jsx';
import Finanzas from './views/dm_views/finanzas.jsx';
import ManejoAgenda from './views/dm_views/manejoAgenda.jsx';
import Prestaciones from './views/dm_views/prestaciones.jsx';
import ProfesionalesSalud from './views/dm_views/profesionalesSalud.jsx';

//! dm_views > agendaWeb
import AgendaWeb from './views/agendaWeb/agendaWeb.jsx';
import AWConfirmarAgenda from './views/agendaWeb/confirmarAgenda.jsx';
import AWEliminarCita from './views/agendaWeb/eliminarCita.jsx';
import AWConfirmarEliminarCita from './views/agendaWeb/confirmarEliminarCita.jsx';


//! dm_views > aseguradoras
import Aseguradoras from './views/dm_views/aseguradoras/maestrosGenerales/aseguradoras/aseguradoras.jsx';
import AseguradorasAgregar from './views/dm_views/aseguradoras/maestrosGenerales/aseguradoras/aseguradorasAgregar.jsx';
import AseguradorasVer from './views/dm_views/aseguradoras/maestrosGenerales/aseguradoras/aseguradorasVer.jsx';
import AseguradorasEditar from './views/dm_views/aseguradoras/maestrosGenerales/aseguradoras/aseguradorasEditar.jsx';
import AseguradorasBorrar from './views/dm_views/aseguradoras/maestrosGenerales/aseguradoras/aseguradorasBorrar.jsx';

//! dm_views > finanzas
//? Centros Recaudadores
import CentrosRecaudadores from './views/dm_views/finanzas/centros_recaudadores/centrosRecaudadores.jsx';
import CentrosRecaudadoresAgregar from './views/dm_views/finanzas/centros_recaudadores/centrosRecaudadoresAgregar.jsx';
import CentrosRecaudadoresVer from './views/dm_views/finanzas/centros_recaudadores/centrosRecaudadoresVer.jsx';
import CentrosRecaudadoresEditar from './views/dm_views/finanzas/centros_recaudadores/centrosRecaudadoresEditar.jsx';
import CentrosRecaudadoresBorrar from './views/dm_views/finanzas/centros_recaudadores/centrosRecaudadoresBorrar.jsx';
//? Empresas Deudoras
import EmpresasDeudoras from './views/dm_views/finanzas/empresas_deudoras/empresasDeudoras.jsx';
import EmpresasDeudorasAgregar from './views/dm_views/finanzas/empresas_deudoras/empresasDeudorasAgregar.jsx';
import EmpresasDeudorasVer from './views/dm_views/finanzas/empresas_deudoras/empresasDeudorasVer.jsx';
import EmpresasDeudorasEditar from './views/dm_views/finanzas/empresas_deudoras/empresasDeudorasEditar.jsx';
import EmpresasDeudorasBorrar from './views/dm_views/finanzas/empresas_deudoras/empresasDeudorasBorrar.jsx';
//? Empresas facturadoras
import EmpresasFacturadoras from './views/dm_views/finanzas/empresas_facturadoras/empresasFacturadoras.jsx';
import EmpresasFacturadorasAgregar from './views/dm_views/finanzas/empresas_facturadoras/empresasFacturadorasAgregar.jsx';
import EmpresasFacturadorasVer from './views/dm_views/finanzas/empresas_facturadoras/empresasFacturadorasVer.jsx';
import EmpresasFacturadorasEditar from './views/dm_views/finanzas/empresas_facturadoras/empresasFacturadorasEditar.jsx';
import EmpresasFacturadorasBorrar from './views/dm_views/finanzas/empresas_facturadoras/empresasFacturadorasBorrar.jsx';
//? Centro Costo Beneficio
import CentrosCostoBeneficio from './views/dm_views/finanzas/centros_costo_beneficio/centrosCostoBeneficio.jsx';
import CentrosCostoBeneficioAgregar from './views/dm_views/finanzas/centros_costo_beneficio/centrosCostoBeneficioAgregar.jsx';
import CentrosCostoBeneficioVer from './views/dm_views/finanzas/centros_costo_beneficio/centrosCostoBeneficioVer.jsx';
import CentrosCostoBeneficioEditar from './views/dm_views/finanzas/centros_costo_beneficio/centrosCostoBeneficioEditar.jsx';
import CentrosCostoBeneficioBorrar from './views/dm_views/finanzas/centros_costo_beneficio/centrosCostoBeneficioBorrar.jsx';
//? Cuentas Contables de Productos de Salud
import CuentasContablesProdSalud from './views/dm_views/finanzas/cuentas_contables_prod_salud/cuentasContablesProdSalud.jsx';
import CuentasContablesProdSaludAgregar from './views/dm_views/finanzas/cuentas_contables_prod_salud/cuentasContablesProdSaludAgregar.jsx';
import CuentasContablesProdSaludVer from './views/dm_views/finanzas/cuentas_contables_prod_salud/cuentasContablesProdSaludVer.jsx';
import CuentasContablesProdSaludEditar from './views/dm_views/finanzas/cuentas_contables_prod_salud/cuentasContablesProdSaludEditar.jsx';
import CuentasContablesProdSaludBorrar from './views/dm_views/finanzas/cuentas_contables_prod_salud/cuentasContablesProdSaludBorrar.jsx';
//? Tipos Documentos Legales
import TiposDocumentosLegales from './views/dm_views/finanzas/tipos_documentos_legales/tiposDocumentosLegales.jsx';

//! dm_views > estructuraCentrosClinicos
//? Centros Clinicos
import CentrosClinicos from './views/dm_views/estructuraCentrosClinicos/centrosClinicos/centrosClinicos.jsx';
import CentrosClinicosAgregar from './views/dm_views/estructuraCentrosClinicos/centrosClinicos/centrosClinicosAgregar.jsx';
import CentrosClinicosVer from './views/dm_views/estructuraCentrosClinicos/centrosClinicos/centrosClinicosVer.jsx';
import CentrosClinicosEditar from './views/dm_views/estructuraCentrosClinicos/centrosClinicos/centrosClinicosEditar.jsx';
import CentrosClinicosBorrar from './views/dm_views/estructuraCentrosClinicos/centrosClinicos/centrosClinicosBorrar.jsx';

//! dm_views > caja
//? Maestros Generales > Tipo de Operacion
import TipoOperaciones from './views/dm_views/caja/maestrosGenerales/tipoOperaciones/tipoOperaciones.jsx';
import TipoOperacionesAgregar from './views/dm_views/caja/maestrosGenerales/tipoOperaciones/tipoOperacionesAgregar.jsx';
import TipoOperacionesVer from './views/dm_views/caja/maestrosGenerales/tipoOperaciones/tipoOperacionesVer.jsx';
import TipoOperacionesEditar from './views/dm_views/caja/maestrosGenerales/tipoOperaciones/tipoOperacionesEditar.jsx';
import TipoOperacionesBorrar from './views/dm_views/caja/maestrosGenerales/tipoOperaciones/tipoOperacionesBorrar.jsx';
//? Maestros Generales > Tipo de Movimiento
import TipoMovimiento from './views/dm_views/caja/maestrosGenerales/tipoMovimiento/tipoMovimiento.jsx';
import TipoMovimientoAgregar from './views/dm_views/caja/maestrosGenerales/tipoMovimiento/tipoMovimientoAgregar.jsx';
import TipoMovimientoVer from './views/dm_views/caja/maestrosGenerales/tipoMovimiento/tipoMovimientoVer.jsx';
import TipoMovimientoEditar from './views/dm_views/caja/maestrosGenerales/tipoMovimiento/tipoMovimientoEditar.jsx';
import TipoMovimientoBorrar from './views/dm_views/caja/maestrosGenerales/tipoMovimiento/tipoMovimientoBorrar.jsx';
//? Maestros Generales > Formas de Pago
import FormasPago from './views/dm_views/caja/maestrosGenerales/formasPago/formasPago.jsx';
import FormasPagoAgregar from './views/dm_views/caja/maestrosGenerales/formasPago/formasPagoAgregar.jsx';
import FormasPagoVer from './views/dm_views/caja/maestrosGenerales/formasPago/formasPagoVer.jsx';
import FormasPagoEditar from './views/dm_views/caja/maestrosGenerales/formasPago/formasPagoEditar.jsx';
import FormasPagoBorrar from './views/dm_views/caja/maestrosGenerales/formasPago/formasPagoBorrar.jsx';
//? Maestros Generales > Motivo de Pago
import MotivoPago from './views/dm_views/caja/maestrosGenerales/motivoPago/motivoPago.jsx';
import MotivoPagoAgregar from './views/dm_views/caja/maestrosGenerales/motivoPago/motivoPagoAgregar.jsx';
import MotivoPagoVer from './views/dm_views/caja/maestrosGenerales/motivoPago/motivoPagoVer.jsx';
import MotivoPagoEditar from './views/dm_views/caja/maestrosGenerales/motivoPago/motivoPagoEditar.jsx';
import MotivoPagoBorrar from './views/dm_views/caja/maestrosGenerales/motivoPago/motivoPagoBorrar.jsx';




const App = () => {
  //! Configuración Ambiente Desarrollo-Producción
  // Ambiente de Desarrollo
  const BaseURL = "/"
  // Ambiente de Producción
  // const BaseURL = "/server/###"

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <SideBar />
        <Routes>
          {/* INICIO RUTAS GENERALES */}
          <Route exact path={BaseURL} element={<Home />} />
          <Route exact path={BaseURL + "dashboard"} element={<Dashboard />} />
          <Route exact path={BaseURL + "prueba"} element={<Prueba />} />
          <Route exact path={BaseURL + "tabla"} element={<Tabla />} />
          <Route exact path={BaseURL + "tablacero"} element={<TablaCero />} />
          <Route exact path={BaseURL + "combobox"} element={<ComboBox />} />
          <Route exact path={BaseURL + "combotable"} element={<ComboTable />} />
          <Route exact path={BaseURL + "pruebaimc"} element={<PruebaIMC />} />
          <Route exact path={BaseURL + "modal"} element={<Modal />} />
          <Route exact path={BaseURL + "spinner"} element={<Spinner />} />
          <Route exact path={BaseURL + "en_construccion"} element={<UnderConstruction />} />
          <Route path="*" element={<NotFound />} />
          {/* FIN RUTAS GENERALES */}

          {/* INICIO AGENDA INTERNA */}
          <Route exact path={BaseURL + "ai_agendar_cita"} element={<AgendaInterna />} />
          <Route exact path={BaseURL + "ai_confirmar_cita/:agenda_id"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ai_eliminar_cita"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ai_confirmar_eliminar_cita/:agenda_id"} element={<UnderConstruction />} />
          {/* FIN AGENDA INTERNA */}

          {/* INICIO CAJA */}
          {/* <Route exact path={BaseURL + "caja"} element={<Caja />} /> */}
          <Route exact path={BaseURL + "caja"} element={<Caja1 />} />
          <Route exact path={BaseURL + "caja/recaudacion"} element={<ValorizarPrestaciones />} />
          {/* FIN CAJA */}
          <Route exact path={BaseURL + "ficha_paciente"} element={<FichaPaciente />} />
          {/* INICIO FICHA PACIENTES */}

          {/* FIN FICHA PACIENTES */}

          {/* INICIO SUBMENU DATOS MAESTROS SIDEBAR */}
          <Route exact path={BaseURL + "aseguradoras"} element={<AseguradorasMain />} />
          <Route exact path={BaseURL + "centros_medicos"} element={<CentrosMedicos />} />
          <Route exact path={BaseURL + "configuracion_de_caja"} element={<ConfiguracionCaja />} />
          <Route exact path={BaseURL + "finanzas"} element={<Finanzas />} />
          <Route exact path={BaseURL + "manejo_de_agenda"} element={<ManejoAgenda />} />
          <Route exact path={BaseURL + "prestaciones"} element={<Prestaciones />} />
          <Route exact path={BaseURL + "profesionales_de_salud"} element={<ProfesionalesSalud />} />
          {/* FIN SUBMENU DATOS MAESTROS SIDEBAR */}

          {/* INICIO AGENDA WEB */}
          <Route exact path={BaseURL + "aw_agendar_cita"} element={<AgendaWeb />} />
          <Route exact path={BaseURL + "aw_agendar_cita/:agenda_id"} element={<AWConfirmarAgenda />} />
          <Route exact path={BaseURL + "aw_eliminar_cita"} element={<AWEliminarCita />} />
          <Route exact path={BaseURL + "aw_eliminar_cita/:agenda_id"} element={<AWConfirmarEliminarCita />} />
          {/* FIN AGENDA WEB */}

          {/* INICIO ASEGURADORAS */}
          {/* INICIO MAESTROS GENERALES > ASEGURADORAS */}
          <Route exact path={BaseURL + "aseguradoras_dm"} element={<Aseguradoras />} />
          <Route exact path={BaseURL + "aseguradoras/agregar"} element={<AseguradorasAgregar />} />
          <Route exact path={BaseURL + "aseguradoras/ver/:cod"} element={<AseguradorasVer />} />
          <Route exact path={BaseURL + "aseguradoras/modificar/:cod"} element={<AseguradorasEditar />} />
          <Route exact path={BaseURL + "aseguradoras/eliminar/:cod"} element={<AseguradorasBorrar />} />
          {/* FIN MAESTROS GENERALES > ASEGURADORAS */}
          {/* FIN ASEGURADORAS */}

          {/* INICIO ESTRUCTURA DE CENTROS CLÍNICOS */}
          {/* INICIO MAESTROS GENERALES > CENTROS CLINICOS */}
          <Route exact path={BaseURL + "ecc_centros_clinicos"} element={<CentrosClinicos />} />
          <Route exact path={BaseURL + "ecc_centros_clinicos/agregar"} element={<CentrosClinicosAgregar />} />
          <Route exact path={BaseURL + "ecc_centros_clinicos/ver/:cod"} element={<CentrosClinicosVer />} />
          <Route exact path={BaseURL + "ecc_centros_clinicos/modificar/:cod"} element={<CentrosClinicosEditar />} />
          <Route exact path={BaseURL + "ecc_centros_clinicos/eliminar/:cod"} element={<CentrosClinicosBorrar />} />
          {/* FIN MAESTROS GENERALES > CENTROS CLINICOS */}
          {/* INICIO MAESTROS GENERALES > SERVICIOS CLINICOS */}
          <Route exact path={BaseURL + "ecc_servicios_clinicos"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_servicios_clinicos/agregar"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_servicios_clinicos/ver/:cod"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_servicios_clinicos/modificar/:cod"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_servicios_clinicos/eliminar/:cod"} element={<UnderConstruction />} />
          {/* FIN MAESTROS GENERALES > SERVICIOS CLINICOS */}
          {/* INICIO MAESTROS POR CENTRO > ASIGNAR EMPRESA ASEGURADORA */}
          <Route exact path={BaseURL + "ecc_asignar_empresas_facturadoras"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_empresas_facturadoras/agregar"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_empresas_facturadoras/ver/:cod"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_empresas_facturadoras/modificar/:cod"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_empresas_facturadoras/eliminar/:cod"} element={<UnderConstruction />} />
          {/* FIN MAESTROS POR CENTRO > ASIGNAR EMPRESA ASEGURADORA */}
          {/* INICIO MAESTROS POR CENTRO > ASIGNAR SERVICIOS CLINICOS */}
          <Route exact path={BaseURL + "ecc_asignar_servicios_clinicos"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_servicios_clinicos/agregar"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_servicios_clinicos/ver/:cod"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_servicios_clinicos/modificar/:cod"} element={<UnderConstruction />} />
          <Route exact path={BaseURL + "ecc_asignar_servicios_clinicos/eliminar/:cod"} element={<UnderConstruction />} />
          {/* FIN MAESTROS POR CENTRO > ASIGNAR SERVICIOS CLINICOS */}
          {/* FIN ESTRUCTURA DE CENTROS CLÍNICOS */}

          {/* INICIO FINANZAS */}
          {/* CENTROS RECAUDADORES */}
          <Route exact path={BaseURL + "fz-centros-recaudadores"} element={<CentrosRecaudadores />} />
          <Route exact path={BaseURL + "fz-centros-recaudadores/agregar"} element={<CentrosRecaudadoresAgregar />} />
          <Route exact path={BaseURL + "fz-centros-recaudadores/ver/:cod"} element={<CentrosRecaudadoresVer />} />
          <Route exact path={BaseURL + "fz-centros-recaudadores/modificar/:cod"} element={<CentrosRecaudadoresEditar />} />
          <Route exact path={BaseURL + "fz-centros-recaudadores/eliminar/:cod"} element={<CentrosRecaudadoresBorrar />} />
          {/* EMPRESAS DEUDORAS */}
          <Route exact path={BaseURL + "fz-empresas-deudoras"} element={<EmpresasDeudoras />} />
          <Route exact path={BaseURL + "fz-empresas-deudoras/agregar"} element={<EmpresasDeudorasAgregar />} />
          <Route exact path={BaseURL + "fz-empresas-deudoras/ver/:cod"} element={<EmpresasDeudorasVer />} />
          <Route exact path={BaseURL + "fz-empresas-deudoras/modificar/:cod"} element={<EmpresasDeudorasEditar />} />
          <Route exact path={BaseURL + "fz-empresas-deudoras/eliminar/:cod"} element={<EmpresasDeudorasBorrar />} />
          {/* EMPRESAS FACTURADORAS */}
          <Route exact path={BaseURL + "fz-empresas-facturadoras"} element={<EmpresasFacturadoras />} />
          <Route exact path={BaseURL + "fz-empresas-facturadoras/agregar"} element={<EmpresasFacturadorasAgregar />} />
          <Route exact path={BaseURL + "fz-empresas-facturadoras/ver/:cod"} element={<EmpresasFacturadorasVer />} />
          <Route exact path={BaseURL + "fz-empresas-facturadoras/modificar/:cod"} element={<EmpresasFacturadorasEditar />} />
          <Route exact path={BaseURL + "fz-empresas-facturadoras/eliminar/:cod"} element={<EmpresasFacturadorasBorrar />} />
          {/* CENTROS DE COSTOS/BENEFICIOS */}
          <Route exact path={BaseURL + "fz-centros-costo-beneficio"} element={<CentrosCostoBeneficio />} />
          <Route exact path={BaseURL + "fz-centros-costo-beneficio/agregar"} element={<CentrosCostoBeneficioAgregar />} />
          <Route exact path={BaseURL + "fz-centros-costo-beneficio/ver/:cod"} element={<CentrosCostoBeneficioVer />} />
          <Route exact path={BaseURL + "fz-centros-costo-beneficio/modificar/:cod"} element={<CentrosCostoBeneficioEditar />} />
          <Route exact path={BaseURL + "fz-centros-costo-beneficio/eliminar/:cod"} element={<CentrosCostoBeneficioBorrar />} />
          {/* CUENTAS CONTABLES DE PRODUCTOS DE SALUD */}
          <Route exact path={BaseURL + "fz-cuentas-contables-prod-salud"} element={<CuentasContablesProdSalud />} />
          <Route exact path={BaseURL + "fz-cuentas-contables-prod-salud/agregar"} element={<CuentasContablesProdSaludAgregar />} />
          <Route exact path={BaseURL + "fz-cuentas-contables-prod-salud/ver/:cod"} element={<CuentasContablesProdSaludVer />} />
          <Route exact path={BaseURL + "fz-cuentas-contables-prod-salud/modificar/:cod"} element={<CuentasContablesProdSaludEditar />} />
          <Route exact path={BaseURL + "fz-cuentas-contables-prod-salud/eliminar/:cod"} element={<CuentasContablesProdSaludBorrar />} />
          {/* TIPOS DE DOCUMENTOS LEGALES */}
          <Route exact path={BaseURL + "fz-tipos-documentos-legales"} element={<TiposDocumentosLegales />} />
          {/* <Route exact path={ BaseURL + "fz-tipos-documentos-legales/agregar" } element={ <CentrosRecaudadores /> } />
          <Route exact path={ BaseURL + "fz-tipos-documentos-legales/ver/:cod" } element={ <CentrosRecaudadores /> } />
          <Route exact path={ BaseURL + "fz-tipos-documentos-legales/modificar/:cod" } element={ <CentrosRecaudadores /> } />
          <Route exact path={ BaseURL + "fz-tipos-documentos-legales/eliminar/:cod" } element={ <CentrosRecaudadores /> } /> */}
          {/* FIN FINANZAS */}

          {/* INICIO CAJA */}
          {/* INICIO CAJA > TIPO OPERACIONES */}
          <Route exact path={BaseURL + "caja_tipo_operaciones"} element={<TipoOperaciones />} />
          <Route exact path={BaseURL + "caja_tipo_operaciones/agregar"} element={<TipoOperacionesAgregar />} />
          <Route exact path={BaseURL + "caja_tipo_operaciones/ver/:cod"} element={<TipoOperacionesVer />} />
          <Route exact path={BaseURL + "caja_tipo_operaciones/modificar/:cod"} element={<TipoOperacionesEditar />} />
          <Route exact path={BaseURL + "caja_tipo_operaciones/eliminar/:cod"} element={<TipoOperacionesBorrar />} />
          {/* FIN CAJA > TIPO OPERACIONES */}
          {/* INICIO CAJA > TIPO OPERACIONES */}
          <Route exact path={BaseURL + "caja_tipo_movimiento"} element={<TipoMovimiento />} />
          <Route exact path={BaseURL + "caja_tipo_movimiento/agregar"} element={<TipoMovimientoAgregar />} />
          <Route exact path={BaseURL + "caja_tipo_movimiento/ver/:cod"} element={<TipoMovimientoVer />} />
          <Route exact path={BaseURL + "caja_tipo_movimiento/modificar/:cod"} element={<TipoMovimientoEditar />} />
          <Route exact path={BaseURL + "caja_tipo_movimiento/eliminar/:cod"} element={<TipoMovimientoBorrar />} />
          {/* FIN CAJA > TIPO OPERACIONES */}
          {/* INICIO CAJA > FORMAS DE PAGO */}
          <Route exact path={BaseURL + "caja_formas_pago"} element={<FormasPago />} />
          <Route exact path={BaseURL + "caja_formas_pago/agregar"} element={<FormasPagoAgregar />} />
          <Route exact path={BaseURL + "caja_formas_pago/ver/:cod"} element={<FormasPagoVer />} />
          <Route exact path={BaseURL + "caja_formas_pago/modificar/:cod"} element={<FormasPagoEditar />} />
          <Route exact path={BaseURL + "caja_formas_pago/eliminar/:cod"} element={<FormasPagoBorrar />} />
          {/* FIN CAJA > FORMAS DE PAGO */}
          {/* INICIO CAJA > MOTIVO DE PAGO */}
          <Route exact path={BaseURL + "caja_motivo_pago"} element={<MotivoPago />} />
          <Route exact path={BaseURL + "caja_motivo_pago/agregar"} element={<MotivoPagoAgregar />} />
          <Route exact path={BaseURL + "caja_motivo_pago/ver/:cod"} element={<MotivoPagoVer />} />
          <Route exact path={BaseURL + "caja_motivo_pago/modificar/:cod"} element={<MotivoPagoEditar />} />
          <Route exact path={BaseURL + "caja_motivo_pago/eliminar/:cod"} element={<MotivoPagoBorrar />} />
          {/* FIN CAJA > MOTIVO DE PAGO */}
          {/* FIN CAJA */}

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default injectContext(App);
