import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../content/appContext';

// FAKEAPI
// import Aseguradoras from '../../../content/fake_db/fakeAseguradoras';

// ICONS
import lupaIcon from '../../../../static/icons/lupaIcon.png';
import modIcon from '../../../../static/icons/modificarIcon.png';
import trashIcon from '../../../../static/icons/trashIcon.png';

const TiposDocumentosLegales = props => {

    const { store } = useContext(Context);
    console.log(store.aseguradoras);

    return (
        <div className="container dmAseguradoras my-3">
            <div className="row center">
                <div className="col">
                    <div className="text-center my-3">
                        <h1>Tipos de Documentos Legales</h1>
                        <Link className="btn btn-success" to="/dm-aseguradoras/agregar">Agregar Nuevo</Link>
                    </div>
                    <div className="scrollTable">
                        <table className="table table-striped table-hover align-middle text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Cod.</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Alias</th>
                                    <th scope="col">Id. identificador</th>
                                    <th scope="col">Razón Social</th>
                                    <th scope="col">Inicio Vigencia</th>
                                    <th scope="col">Modo Facturar Ambulatorio</th>
                                    <th scope="col">Modo Facturar Hospitalizado</th>
                                    <th scope="col">Modo Facturar Urgencia</th>
                                    <th scope="col">Clase</th>
                                    <th scope="col">Orden</th>
                                    <th scope="col">Id Externo</th>
                                    <th scope="col">Término Vigencia</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col" className="size">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    store.aseguradora ? store.map((Aseguradora) => {
                                        return (

                                            <tr>
                                                <th scope="row" key={Aseguradora.dIpr_Fec_Inicio}>{Aseguradora.dIpr_Fec_Inicio}</th>
                                                <td>{Aseguradora.nombre}</td>
                                                <td>{Aseguradora.alias}</td>
                                                <td>{Aseguradora.idIdentificador}</td>
                                                <td>{Aseguradora.razonSocial}</td>
                                                <td>{Aseguradora.inicioVigencia}</td>
                                                <td>{Aseguradora.modoFacturarAmbulatorio}</td>
                                                <td>{Aseguradora.modoFacturarHospitalizado}</td>
                                                <td>{Aseguradora.modoFacturarUrgencia}</td>
                                                <td>{Aseguradora.clase}</td>
                                                <td>{Aseguradora.orden}</td>
                                                <td>{Aseguradora.idExterno}</td>
                                                <td>{Aseguradora.terminoVigencia}</td>
                                                <td>{Aseguradora.tipo}</td>
                                                <td className="size">
                                                    <Link className="btn btn-success" to={"aseguradoras/ver/" + Aseguradora.cod}><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                                    <Link className="btn btn-warning" to={"/aseguradoras/modificar/" + Aseguradora.cod}><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                                    <Link className="btn btn-danger" to={"/aseguradoras/eliminar/" + Aseguradora.cod}><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                                </td>
                                            </tr>

                                        )
                                    }) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TiposDocumentosLegales;