import React from 'react';
import { Link } from 'react-router-dom';

// STYLES
import '../../../../../styles/dmAseguradoras.css';

const AseguradorasAgregar = () => {
    return (
        <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Agregar Nueva Aseguradora</h2>
                <div className="col-8 offset-2">
                    <form action="">
                        <div className="row">
                            <div className="col-5">
                                <p>Cod.</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Nombre</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Alias</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Id. identificador</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Razón Social</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Inicio Vigencia</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Modo Facturar Ambulatorio</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Modo Facturar Hospitalizado</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Modo Facturar Urgencia</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Clase</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Orden</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Id Externo</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Término Vigencia</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Tipo</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" />
                            </div>
                        </div>
                        <Link className="btn btn-danger me-2" to="/dm-aseguradoras/">Atrás</Link>
                        <Link className="btn btn-success" to="/">Agregar</Link>
                        {/* <button className="btn btn-success" type="submit">Agregar</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AseguradorasAgregar;