import React from 'react';
import { Link } from 'react-router-dom';

const CuentasContablesProdSaludEditar = () => {
    return(
        <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Editar Cuenta Contable de Producto de Salud</h2>
                <div className="col-8 offset-2">
                    <form action="">
                        <div className="row">
                            <div className="col-5">
                                <p>Cod.</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Tipo de Episodio</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Tipo Prestación</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Nombre Tipo Prestación</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Sub Tipo Prestación</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Nombre Sub Tipo Prestación</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Código Grupo Prestación</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Descripción Grupo Prestación</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Código Ítem</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Descripción Ítem</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Descripción Breve</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Ingreso</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Provisión Ingreso</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Excedente</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Déficit</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Descuentos</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Ingreso Externo</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Control</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Cuenta Contable Gasto</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" />
                            </div>
                        </div>
                        <Link className="btn btn-danger me-2" to="/fz-cuentas-contables-prod-salud">Atrás</Link>
                        <Link className="btn btn-success" to="/">Editar</Link>
                        {/* <button className="btn btn-success" type="submit">Editar</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CuentasContablesProdSaludEditar;