import React from 'react';
import { Link } from 'react-router-dom';

// ICONS
import lupaIcon from '../../../../static/icons/lupaIcon.png';
import modIcon from '../../../../static/icons/modificarIcon.png';
import trashIcon from '../../../../static/icons/trashIcon.png';

const EmpresasDeudoras = () => {
    return (
        <div className="container dmAseguradoras my-3">
            <div className="row center">
                <div className="col">
                    <div className="text-center my-3">
                        <h1>Empresas Deudoras</h1>
                        <Link className="btn btn-success" to="/fz-empresas-deudoras/agregar">Agregar Nuevo</Link>
                    </div>
                    <div className="scrollTable">
                        <table className="table table-striped table-hover align-middle text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Cod.</th>
                                    <th scope="col">Empresa</th>
                                    <th scope="col">Razón Social</th>
                                    <th scope="col">País</th>
                                    <th scope="col">Ciudad</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">Giro Comercial</th>
                                    <th scope="col">Teléfono</th>
                                    <th scope="col">Correo</th>
                                    <th scope="col" className="size">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>76.098.567-2</td>
                                    <td>Banmedica</td>
                                    <td>Chile</td>
                                    <td>Santiago</td>
                                    <td>Matta 233</td>
                                    <td>SALUD</td>
                                    <td>+56991234567</td>
                                    <td>info@banmedica.cl</td>
                                    <td className="size">
                                        <Link className="btn btn-success" to="/fz-empresas-deudoras/ver/1"><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                        <Link className="btn btn-warning" to="/fz-empresas-deudoras/modificar/1"><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                        <Link className="btn btn-danger" to="/fz-empresas-deudoras/eliminar/1"><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                    </td>
                                </tr>
                                {/* {
                                Aseguradoras ? Aseguradoras.map((Aseguradora) => {
                                    return (

                                        <tr>
                                            <th scope="row" key={Aseguradora.cod}>{Aseguradora.cod}</th>
                                            <td>{Aseguradora.nombre}</td>
                                            <td>{Aseguradora.alias}</td>
                                            <td>{Aseguradora.idIdentificador}</td>
                                            <td>{Aseguradora.razonSocial}</td>
                                            <td>{Aseguradora.inicioVigencia}</td>
                                            <td>{Aseguradora.modoFacturarAmbulatorio}</td>
                                            <td>{Aseguradora.modoFacturarHospitalizado}</td>
                                            <td>{Aseguradora.modoFacturarUrgencia}</td>
                                            <td>{Aseguradora.clase}</td>
                                            <td>{Aseguradora.orden}</td>
                                            <td>{Aseguradora.idExterno}</td>
                                            <td>{Aseguradora.terminoVigencia}</td>
                                            <td>{Aseguradora.tipo}</td>
                                            <td className="size">
                                                <Link className="btn btn-success" to={"/dm-aseguradoras/ver/" + Aseguradora.cod}><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                                <Link className="btn btn-warning" to={"/dm-aseguradoras/modificar/" + Aseguradora.cod}><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                                <Link className="btn btn-danger" to={"/dm-aseguradoras/eliminar/" + Aseguradora.cod}><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                            </td>
                                        </tr>

                                    )
                                }) : null
                            } */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmpresasDeudoras;