import React from 'react';
import { Link } from 'react-router-dom';

// ICONS
import lupaIcon from '../../../../static/icons/lupaIcon.png';
import modIcon from '../../../../static/icons/modificarIcon.png';
import trashIcon from '../../../../static/icons/trashIcon.png';

const CuentasContablesProdSalud = () => {
    return (
        <div className="container dmAseguradoras my-3">
            <div className="row center">
                <div className="col">
                    <div className="text-center my-3">
                        <h1>Cuentas Contables de Productos de la Salud</h1>
                        <Link className="btn btn-success" to="/fz-cuentas-contables-prod-salud/agregar">Agregar Nuevo</Link>
                    </div>
                    <div className="scrollTable">
                        <table className="table table-striped table-hover align-middle text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Cod.</th>
                                    <th scope="col">Tipo Episodio</th>
                                    <th scope="col">Tipo Prestación</th>
                                    <th scope="col">Nombre Tipo Prestación</th>
                                    <th scope="col">Sub Tipo Prestación</th>
                                    <th scope="col">Nombre Sub Tipo Prestación</th>
                                    <th scope="col">Código Grupo Prestación</th>
                                    <th scope="col">Descripción Grupo Prestación</th>
                                    <th scope="col">Código Prestación</th>
                                    <th scope="col">Descripción Prestación</th>
                                    <th scope="col">Código Item</th>
                                    <th scope="col">Descripción Ítem</th>
                                    <th scope="col">Descripción Breve</th>
                                    <th scope="col">Cuenta Contable Ingreso</th>
                                    <th scope="col">Cuenta Contable Provisión Ingreso</th>
                                    <th scope="col">Cuenta Contable Excedente</th>
                                    <th scope="col">Cuenta Contable Déficit</th>
                                    <th scope="col">Cuenta Contable Descuentos</th>
                                    <th scope="col">Ingreso Externo</th>
                                    <th scope="col">Cuenta Contable Control</th>
                                    <th scope="col">Cuenta Contable Gasto</th>
                                    <th scope="col" className="size">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>*</td>
                                    <td>0</td>
                                    <td> </td>
                                    <td>0</td>
                                    <td> </td>
                                    <td>120225</td>
                                    <td> </td>
                                    <td>190</td>
                                    <td>DERECHO PABELLÓN</td>
                                    <td>0</td>
                                    <td> </td>
                                    <td> </td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>4</td>
                                    <td>5</td>
                                    <td>6</td>
                                    <td>7</td>
                                    <td>10</td>
                                    <td className="size">
                                        <Link className="btn btn-success" to="/fz-cuentas-contables-prod-salud/ver/1"><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                        <Link className="btn btn-warning" to="/fz-cuentas-contables-prod-salud/modificar/1"><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                        <Link className="btn btn-danger" to="/fz-cuentas-contables-prod-salud/eliminar/1"><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                    </td>
                                </tr>
                                {/* {
                                Aseguradoras ? Aseguradoras.map((Aseguradora) => {
                                    return (

                                        <tr>
                                            <th scope="row" key={Aseguradora.cod}>{Aseguradora.cod}</th>
                                            <td>{Aseguradora.nombre}</td>
                                            <td>{Aseguradora.alias}</td>
                                            <td>{Aseguradora.idIdentificador}</td>
                                            <td>{Aseguradora.razonSocial}</td>
                                            <td>{Aseguradora.inicioVigencia}</td>
                                            <td>{Aseguradora.modoFacturarAmbulatorio}</td>
                                            <td>{Aseguradora.modoFacturarHospitalizado}</td>
                                            <td>{Aseguradora.modoFacturarUrgencia}</td>
                                            <td>{Aseguradora.clase}</td>
                                            <td>{Aseguradora.orden}</td>
                                            <td>{Aseguradora.idExterno}</td>
                                            <td>{Aseguradora.terminoVigencia}</td>
                                            <td>{Aseguradora.tipo}</td>
                                            <td className="size">
                                                <Link className="btn btn-success" to={"/dm-aseguradoras/ver/" + Aseguradora.cod}><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                                <Link className="btn btn-warning" to={"/dm-aseguradoras/modificar/" + Aseguradora.cod}><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                                <Link className="btn btn-danger" to={"/dm-aseguradoras/eliminar/" + Aseguradora.cod}><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                            </td>
                                        </tr>

                                    )
                                }) : null
                            } */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CuentasContablesProdSalud;