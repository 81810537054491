import React from 'react';
import { Link } from 'react-router-dom';

// STATIC IMG
//! ICONS
import CalendarIcon from '../static/icons/calendarIcon.png';
import CajaIcon from '../static/icons/cajaIcon.png';
import FichaIcon from '../static/icons/fichaIcon.png';
import DMasterIcon from '../static/icons/dMasterIcon.png';
import UserIcon from '../static/icons/userIcon.png';
//! IMG
import Logo from '../static/img/logo.png';

const SideBar = () => {
    return (
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title mx-auto" id="offcanvasExampleLabel"><img className="logo" src={Logo} alt="logo" /></h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <ul className="offcanvas-body ms-1">
                <li data-bs-dismiss="offcanvas">
                    <Link className="btn" to="/ai_agendar_cita"><img className="iconSide me-2" src={CalendarIcon} alt="Calendario" /> Agendar</Link>
                </li>
                <li data-bs-dismiss="offcanvas">
                    <Link className="btn" to="/caja"><img className="iconSide me-2" src={CajaIcon} alt="Caja" /> Caja</Link>
                </li>
                <li data-bs-dismiss="offcanvas">
                    <Link className="btn" to="/ficha_paciente"><img className="iconSide me-2" src={FichaIcon} alt="Ficha" /> Ficha Clínica</Link>
                </li>
                <Link className="btn nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img className="iconSide me-2" src={DMasterIcon} alt="Datos_Maestros" /> Datos Maestros</Link>
                <ul className="dropdown-menu">
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/centros_medicos">
                            Centros Médicos
                        </Link>
                        
                    </li>
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/prestaciones">
                            Prestaciones
                        </Link>
                        
                    </li>
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/aseguradoras">
                            Aseguradoras
                        </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/profesionales_de_salud">
                            Profesionales de Salud
                        </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/manejo_de_agenda">
                            Manejo de Agenda
                        </Link>
                        
                    </li>
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/configuracion_de_caja">
                            Configuración de Caja
                        </Link>
                        
                    </li>
                    <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/finanzas">
                            Finanzas
                        </Link>
                    </li>
                </ul>
                <li data-bs-dismiss="offcanvas">
                    <Link className="btn" to="/en_construccion"><img className="iconSide me-2" src={UserIcon} alt="User" /> Usuario</Link>
                </li>
            </ul>
        </div>
    );
}

export default SideBar;