import React from 'react';
import { Link } from 'react-router-dom';

const TipoMovimientoEditar = () => {
    return(
        <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Tipo de Movimiento Editar</h2>
                <div className="col-8 offset-2">
                    <form action="">
                        <div className="row">
                            <div className="col-5">
                                <p>TMO Código</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>TMO Descripción</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>TMO Acción</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue={null} />
                            </div>
                        </div>
                        <Link className="btn btn-danger me-2" to="/caja_tipo_movimiento/">Atrás</Link>
                        <Link className="btn btn-success" to="/">Editar</Link>
                        {/* <button className="btn btn-success" type="submit">Agregar</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TipoMovimientoEditar;