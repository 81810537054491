import React from 'react';
import UnderConstruction from '../../../enContrucción';

const CentrosClinicosVer = () => {
    return(
        <UnderConstruction
         />
    );
}

export default CentrosClinicosVer;