import React from 'react';

import FakeDash from '../static/img/fakedash.png';

const Dashboard = props => {
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <img className="fakedash" src={FakeDash} alt="fakedash" />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;