import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../content/appContext';

import Select from 'react-select';

import CheckIcon from '../../static/icons/checkIcon.png';
import BorrarIcon from '../../static/icons/borrarIcon.png';

const ComboTable = () => {

    const { store,  actions } = useContext(Context);

    // Variables ComboBox
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(store.codigoPrestacion);
    // Variable Valorización
    const [valorPrestacion, setValorPrestacion] = useState('');
    // Variables Tabla
    const [datosTabla, setDatosTabla] = useState([{ grupo: false, codigo: selectedOption, precio: valorPrestacion, cantidad: '1', seleccionado: false }]);

    useEffect(() => {
        if (store.valorizacion.length > 0) {
            // return setValorPrestacion(store.valorizacion[0]._valor_lista);
            setValorPrestacion(store.valorizacion[0]._valor_lista);
        } else {
            // return setValorPrestacion('');
            setValorPrestacion('');

        }
    }, [store.valorizacion])

    useEffect(() => {
        if (store.citaAgendada.length > 0) {
            setSelectedOption(store.citaAgendada[0].prestacion);
        } else {
            setSelectedOption('');
        }
    }, [store.citaAgendada]);

    // console.log(store.codigoPrestacion)

    // Manipulacion de la tabla
    const agregarFila = () => {
        setDatosTabla([...datosTabla, { grupo: false, codigo: null, precio: '', cantidad: '1', seleccionado: false }]);
        // setSelectedOption(null);
    };

    const handleCheckboxChangeSeleccionado = (indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, seleccionado: !fila.seleccionado };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleCheckboxChangeGrupo = (indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, grupo: !fila.grupo };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleChange = (indice, campo, valor) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, [campo]: valor };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const eliminarFila = (indice) => {
        const nuevasFilas = datosTabla.filter((fila, idx) => idx !== indice);
        setDatosTabla(nuevasFilas);
    };

    // Manipulacion del ComboBox en Tabla


    useEffect(() => {

        setOptions(store.prestacionesServicioCentro.map(option => ({ value: option.prS_CODIGO, label: `${Math.floor(parseFloat(option.prS_CODIGO))} - ${option.prS_DESCRIPCION}` })));

    }, [store.prestacionesServicioCentro]);

    // console.log(store.prestacionesServicioCentro);

    const handleChange1 = (selectedOption, indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, codigo: selectedOption.value };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    //# Condicional para definir la informacion que viene en la prestacion
    //# El primer condicional es cuando hago click en una cita Agendada existente y
    //# el segundo condicional es cuando no tengo cita agendada y sea espontanea.

    let descripcionCompleta;

    if (store.codigoPrestacion === '') {
        descripcionCompleta = store.prestacionesServicioCentro.find(prestacion => prestacion.prS_CODIGO === datosTabla[0].codigo) || '';
    } else {
        descripcionCompleta = store.prestacionesServicioCentro.find(prestacion => prestacion.prS_CODIGO === store.codigoPrestacion) || '';
    }

    const setPrestacionGlobal = () => {
        actions.handleChangeEspecialPrestacion(datosTabla);
    }

    // console.log(store.prestacionSerCen);

    const setDescripcionCompletaGlobal = () => {
        actions.setInfoPrestacion(descripcionCompleta);
    }

    // console.log(store.prestacionSerCen);

    // Lógica para manipular datosTabla y llamar a actualizarDatosTablaEnFlux cuando sea necesario...
    useEffect(() => {
        setPrestacionGlobal();
        setDescripcionCompletaGlobal();
    }, [datosTabla, descripcionCompleta]);

    // console.log(store.prestacionSerCen);

    useEffect(() => {
        actions.getPrestacionServicioCentro();
        // actions.getPrueba();
    }, []);

    return (
        <div className="container">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Grupo</th>
                        <th>Prestación</th>
                        <th>Valor</th>
                        <th>Cantidad</th>
                        <th>Particular</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        datosTabla.map((fila, indice) => (
                            <tr key={indice}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={fila.grupo}
                                        onChange={() => handleCheckboxChangeGrupo(indice)}
                                    />
                                </td>
                                <td>
                                    <Select
                                        className="comboBoxPrestaciones"
                                        options={options}
                                        onChange={(selectedOption) => handleChange1(selectedOption, indice)}
                                        value={options.find(option => (store.codigoPrestacion) ? option.value === store.codigoPrestacion : option.value === fila.codigo)}
                                    // isClearable
                                    />
                                </td>
                                <td>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={(valorPrestacion !== '') ? store.valorizacion[0]._valor_lista : fila.precio}
                                        onChange={(e) => handleChange(indice, 'precio', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={fila.cantidad}
                                        onChange={(e) => handleChange(indice, 'cantidad', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={fila.seleccionado}
                                        onChange={() => handleCheckboxChangeSeleccionado(indice)}
                                    />
                                </td>
                                <td>
                                    {
                                        indice === datosTabla.length - 1 && (
                                            <button className="btn btn-success" onClick={agregarFila}><img className="icon whiteIcon" src={CheckIcon} alt="Agregar Fila" /></button>
                                        )
                                    }
                                    {
                                        indice !== datosTabla.length - 1 && (
                                            <button className="btn btn-warning" onClick={() => eliminarFila(indice)}><img className="icon blackIcon" src={BorrarIcon} alt="Eliminar Fila" /></button>
                                        )
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ComboTable;