import React from 'react';
import { Link } from 'react-router-dom';

const EmpresasDeudorasBorrar = () => {
    return(
    <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Eliminar Empresa Deudora</h2>
                <div className="col-8 offset-2">
                    <form action="">
                        <div className="row">
                            <div className="col-5">
                                <p>Cod.</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Empresa</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Razón Social</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>País</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Ciudad</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Dirección</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Giro Comercial</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Teléfono</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p>Correo</p>
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" defaultValue="" disabled />
                            </div>
                        </div>
                        <h5 className="my-5"><strong>Estás seguro que quieres eliminar a XXXXXX? (Esto será permanente)</strong></h5>
                        <Link className="btn btn-danger me-2" to="/fz-empresas-deudoras">Atrás</Link>
                        <Link className="btn btn-success" to="/">Eliminar</Link>
                        {/* <button className="btn btn-success" type="submit">Eliminar</button> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EmpresasDeudorasBorrar;