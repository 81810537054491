import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Context } from "../../content/appContext";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

//! DATEPICKER CALENDAR --> Documentación: https://react-day-picker.js.org/
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

//! COMBOBOX IMPORT
import Select from 'react-select';

//! STATIC
//? ICONS
import CheckIcon from '../../static/icons/checkIcon.png';
import BorrarIcon from '../../static/icons/borrarIcon.png';
import BuscarIcon from '../../static/icons/lupaIcon.png';
import UserIcon from '../../static/icons/userIcon.png';
import IdIcon from '../../static/icons/idIcon.png';
import BirthIcon from '../../static/icons/birthIcon.png';
import PhoneIcon from '../../static/icons/phoneIcon.png';
import ArrobaIcon from '../../static/icons/arrobaIcon.png';
import GenderIcon from '../../static/icons/genderIcon.png';
import PenIcon from '../../static/icons/penIcon.png';
import GuardarIcon from '../../static/icons/guardarIcon.png';

//! STYLES
import '../../styles/caja.css';

//! FAKEAPI
// import FakePacienteCaja from '../../content/fake_db/fakePacienteCaja';

// const pastMonth = new Date(Date.now());

const Caja1 = () => {

    //! CONEXIÓN CON FLUX
    const { store, actions } = useContext(Context);

    //! FUNCIONES GLOBALES
    // const cerrarModal = () => {
    //     const modal = document.getElementById('cajaModal');
    //     modal.classList.remove('show');
    //     modal.removeAttribute('style');
    // }

    //! CALENDARIO - DATEPICKER

    const [selected, setSelected] = useState(store.fechaInicial);

    let footer = <p>Please pick a day.</p>;
    if (selected) {
        footer = <p>You picked {format(selected, 'PP')}.</p>;
    }

    const disabledDays = [
        { from: new Date(1900, 4, 18), to: (Date.now() - 24 * 60 * 60 * 1000) }
        // --> Deshabilitar en el calendario los dias anteriores hasta "ayer"
    ];

    //! TABLA CITAS AGENDADAS
    const [datos, setDatos] = useState([]);

    const cargaDatos = (CitaTomada) => {
        setDatos(CitaTomada);
    };

    //! VALIDADOR RUT
    const [rut, setRut] = useState('');
    const [resultado, setResultado] = useState('');
    const [resultadoValidacion, setResultadoValidacion] = useState('');

    useEffect(() => {
        const handleChange = (e) => {
            let valor = e.target.value.replace(/-/g, ''); // Elimina todos los guiones
            if (valor.length > 6) {
                // Si hay más de un dígito, formatea el RUT
                valor = valor.slice(0, -1) + '-' + valor.slice(-1);
            }
            e.target.value = valor; // Actualiza el valor del campo con el formato aplicado

            setRut(valor);

            // Validar RUT
            const esValido = validarRut(valor);
            setResultadoValidacion(esValido);
            const mensaje = esValido ? "Válido" : "Inválido";
            setResultado("El RUT ingresado es " + mensaje);
        };

        const validarRut = (rut) => {
            rut = rut.trim();
            if (!/^[0-9]+[-]{1}[0-9kK]{1}$/.test(rut)) return false;

            const rutSinGuion = rut.replace('-', '');
            const dv = rut.slice(-1);
            const rutNumeros = rutSinGuion.slice(0, -1);

            let suma = 0;
            let multiplo = 2;
            for (let i = rutNumeros.length - 1; i >= 0; i--) {
                suma += parseInt(rutNumeros.charAt(i)) * multiplo;
                if (multiplo < 7) multiplo++;
                else multiplo = 2;
            }
            let resto = suma % 11;
            let resultado = 11 - resto;
            if (resultado === 11) resultado = 0;
            else if (resultado === 10) resultado = "K";

            return resultado.toString().toUpperCase() === dv.toUpperCase();
        };

        const inputElement = document.getElementById('rutInput');
        inputElement.addEventListener('input', handleChange);

        return () => {
            inputElement.removeEventListener('input', handleChange);
        };
    }, []);

    //# 18946245-k --> Rut con guion K no ingresado
    //# 12096920-k --> Paciente en BBDD Sin Agenda: FILOMENA PARISI DINARDO 


    //! FILTRO DE CITAS AGENDADAS POR RUT
    const [filtro, setFiltro] = useState('');
    const [aviso, setAviso] = useState(false);

    // Función para manejar cambios en el input de filtro
    const handleFiltroChange = e => {
        setRut(e.target.value);
    };

    // Filtrar las citas según el valor del filtro
    const citasFiltradas = store.citasAgendadas.filter(citaElegida =>
        // const citasFiltradas = FakePacienteCaja.filter(citaElegida =>
        citaElegida.pgL_NRO_DOCUMENTO.includes(rut)
    );

    const handleRutInputCaja = async (e) => {
        // console.log("key pressed " + e.keyCode);
        if (e.key === "Enter") {
            if (citasFiltradas.length === 1) {
                actions.handleChangeEspecialCaja(citasFiltradas[0])
            } else if (citasFiltradas.length === 0) {
                if (store.docto) {
                    await actions.getPacientePorDocto(e);
                    // setTimeout(() => {
                    if (store.codIdent === '') {
                        const modal = document.getElementById('cajaModal');
                        modal.classList.add('show');
                        modal.setAttribute('style', 'display: block');
                        modal.setAttribute('role', 'dialog');

                    }
                    // }, 4000);
                }
            } else {
                // Cambiar una variable para mostrar un aviso al usuario
                // setAviso(true);
                alert("Debes elegir un Paciente!");
            }
        } else if (store.nombrePaciente !== '' || e.key === 8) {
            // Resetear todas las variables
            actions.resetVarsCaja();

        }
    };

    // Funciones para enviar la informacion rut a store.docto

    const setRutGlobal = () => {
        actions.setRutCaja(rut);
    }

    useEffect(() => {
        setRutGlobal();
    }, [rut]);

    //! FORMATO FECHA NACIMIENTO
    const fechaString = store.fecNacPaciente;
    const fecha = new Date(fechaString);

    // Extraer los componentes de la fecha
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear().toString();

    // Formatear la fecha en formato DD/MM/AAAA
    const fechaFormateada = `${dia}/${mes}/${año}`;

    //! FORMATO FECHA Y HORA CITA ELEGIDA
    //# Fecha
    const fechaCitaString = store.fechaCita;
    const fechaCitaMedica = new Date(fechaCitaString);

    // Extraer los componentes de la fecha
    const diaCita = fechaCitaMedica.getDate().toString().padStart(2, '0');
    const mesCita = (fechaCitaMedica.getMonth() + 1).toString().padStart(2, '0');
    const añoCita = fechaCitaMedica.getFullYear().toString();

    // Formatear la fecha en formato DD/MM/AAAA
    const fechaCitaFormateada = `${diaCita}/${mesCita}/${añoCita}`;

    //# Hora
    // Obtener horas y minutos
    const horaCita = fechaCitaMedica.getHours();
    const minutoCita = fechaCitaMedica.getMinutes();

    // Formatear las horas y minutos con ceros a la izquierda si es necesario
    const horasFormateadas = horaCita < 10 ? '0' + horaCita : horaCita;
    const minutosFormateados = minutoCita < 10 ? '0' + minutoCita : minutoCita;

    // Crear el formato hh:mm
    const horaMinutoFormato = `${horasFormateadas}:${minutosFormateados}`;

    //! FECHA CAJA

    const fechaCaja = new Date(store.fechaCaja);

    const diaCaja = fechaCaja.getDate();
    const mesCaja = fechaCaja.getMonth() + 1; // Se suma 1 porque los meses van de 0 a 11
    const anioCaja = fechaCaja.getFullYear();

    // Formatear la fecha en el formato DD-MM-YYYY
    const fechaCajaFormateada = `${diaCaja < 10 ? '0' : ''}${diaCaja}-${mesCaja < 10 ? '0' : ''}${mesCaja}-${anioCaja}`;


    // Obtener los componentes de la hora
    const horaCaja = fechaCaja.getHours();
    const minutosCaja = fechaCaja.getMinutes();

    // Formatear la hora en el formato HH:MM
    const horaCajaFormateada = `${horaCaja < 10 ? '0' : ''}${horaCaja}:${minutosCaja < 10 ? '0' : ''}${minutosCaja}`;

    const [diaActualCaja, setDiaActualCaja] = useState(fechaCajaFormateada);
    const [horaActualCaja, setHoraActualCaja] = useState(horaCajaFormateada);

    const handleChangeDiaCaja = (e) => {
        const nuevoDia = e.target.value;
        setDiaActualCaja(nuevoDia)
    }

    const handleChangeHoraCaja = (e) => {
        const nuevaHora = e.target.value;
        setHoraActualCaja(nuevaHora);
    }

    //! CÁLCULO EDAD
    // Fecha Nacimiento en Milisegundos
    const fechaNacMilisegundos = fecha.getTime(); //number
    // Fecha actual
    const fechaActual = new Date().getTime(); //number

    // Convierte la fecha de nacimiento en un objeto Date
    const fechaNacimiento = new Date(fechaNacMilisegundos).getTime(); //number

    // Calcula la diferencia de tiempo en milisegundos
    const diferenciaMillis = fechaActual - fechaNacimiento; //NaN

    // Convierte la diferencia de tiempo en años, meses y días
    const años = Math.floor(diferenciaMillis / (1000 * 60 * 60 * 24 * 365));
    const meses = Math.floor((diferenciaMillis % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));

    //! MEDICO TRATANTE CON COMBOBOX

    const [optionsMedicoTratante, setOptionsMedicoTratante] = useState([]);
    const [selectedOptionMedicoTratante, setSelectedOptionMedicoTratante] = useState(parseInt(store.rutMedicoSDV));

    useEffect(() => {
        setOptionsMedicoTratante(store.medicosTratantesCompleto.map(option => ({ value: Math.floor(parseFloat(option.meD_RUT)), label: `${Math.floor(parseFloat(option.meD_RUT))} - ${option.meD_NOMBRE} ${option.meD_APELLIDO_P} ${option.meD_APELLIDO_M}` })));
    }, [store.medicosTratantesCompleto]);

    // console.log(selectedOptionMedicoTratante);
    // console.log(store.rutMedicoSDV);

    const handleChangeMedicoTratante = (selectedOption) => {
        setSelectedOptionMedicoTratante(selectedOption);
        console.log('Option selected:', selectedOption);
    };

    // const newValue = optionsMedicoTratante.find(option => ((store.rutMedicoSDV !== '') ? parseInt(option.value) === parseInt(store.rutMedicoSDV) : ''))
    // console.log(newValue)

    //! TABLA DINAMICA PARA PRESTACIONES CON COMBOBOX
    // Variables ComboBox
    const [optionsPrestaciones, setOptionsPrestaciones] = useState([]);
    const [selectedOptionPrestaciones, setSelectedOptionPrestaciones] = useState(store.codigoPrestacion);
    // Variable Valorización
    const [valorPrestacion, setValorPrestacion] = useState(0);
    // Variables Tabla
    // const [datosTabla, setDatosTabla] = useState([{ grupo: false, codigo: (store.fichaAtencion ? selectedOptionPrestaciones : ''), precio: valorPrestacion, cantidad: '1', seleccionado: false }]);
    const [datosTabla, setDatosTabla] = useState([{ grupo: false, codigo: 0, precio: 0, cantidad: 1, seleccionado: false }]);

    //! Creacion de la lista de precios
    const dataRespondeValorizacion = [];
    const [estadoPromesa, setEstadoPromesa] = useState(false);
    const [promesaCumplida, setPromesaCumplida] = useState([]);


    // useEffect(() => {
    // Utiliza Promise.all para esperar a que todas las Promesas se resuelvan
    const dataNueva = Promise.all(store.prestacionServicioCentroPrueba.map(async (prestacion, index) => {
        // Construye el cuerpo de la solicitud utilizando la función del flux
        const body = {
            "cod_centro": Math.floor(parseFloat(store.centroCaja)),
            // "cod_centro": 9,
            "cod_servicio": Math.floor(parseFloat(store.servicioCaja)),
            // "cod_servicio": 15,
            "rut_clinica": Math.floor(parseFloat(store.rutClinica)),
            // "rut_clinica": 777427817,
            "rut_tratante": 0,
            "ambiente": "A",
            "codigo_prestacion": Math.floor(parseFloat(prestacion.prS_CODIGO)),
            "nombre_prestacion": prestacion.prS_DESCRIPCION,
            "cod_interv_quirur": Math.floor(parseFloat(prestacion.iD_INT_QUIR)),
            "cod_homologo": Math.floor(parseFloat(prestacion.prS_CODIGO_HOMOLOGO)),
            "codigo_mm": prestacion.prS_CODIGO_MM,
            "cod_item": (prestacion.itM_CODIGO === null) ? 0 : prestacion.itM_CODIGO,
            "codigo_tipo_factura_X_componente": Math.floor(parseFloat(prestacion.prS_FACTURA_POR_GRUPO_COMPONENTE)),
            "codigo_tp": Math.floor(parseFloat(prestacion.tP_CODIGO)),
            "descripcion_tp": prestacion.prS_NOMBRE_BREVE,
            "codigo_sub_tp": Math.floor(parseFloat(prestacion.stP_CODIGO)),
            "descripcion_sub_tp": prestacion.stP_DESCRIPCION,
            "codprevisionpac": (store.citaAgendada.prevision ? Math.floor(parseFloat(store.citaAgendada.prevision)) : "50"),
            "codprevisionPart": store.codPrevisionPart,
            "cod_convenio": store.codConvenio,
            "fecha_valorizacion": new Date().toISOString(),
            "lst_componentes": [
                {
                    "_tp_codigo": Math.floor(parseFloat(prestacion.tP_CODIGO)),
                    "_sub_tp_codigo": Math.floor(parseFloat(prestacion.stP_CODIGO)),
                    "_codigo": Math.floor(parseFloat(prestacion.gtP_CODIGO)),
                    "_descripcion": prestacion.p_DESCRIPCION,
                    "_item": {
                        "_codigo": (prestacion.itM_CODIGO === null) ? 0 : prestacion.itM_CODIGO,
                        "_descripcion": prestacion.itM_DESCRIPCION
                    },
                    "_muestra": {
                        "_codigo": (prestacion.mT_CODIGO === null) ? 0 : prestacion.mT_CODIGO,
                        "_descripcion": prestacion.mT_DESCRIPCION
                    },
                    // "_codigo_intervencion_quirurg": Math.floor(parseFloat(store.codIntervQuirur)),
                    "_codigo_intervencion_quirurg": 0,
                    "_codigo_homologo": Math.floor(parseFloat(prestacion.prS_CODIGO_HOMOLOGO)),
                    "_nombre_tp": prestacion.prS_DESCRIPCION,
                    "_nombre_sub_tp": prestacion.prS_DESCRIPCION,
                    "_id_prestacion_orden_clinica": 0,
                    "_tipo_divergencia": 0,
                    "_valor_divergente": 0,
                    "_nombre_divergente": "string",
                    "_ID_RESERVA_PABELLON": 0
                }
            ],
            "cod_descuento": false,
            "con_recargo": false,
            "tipo_divergencia": 0,
            "valor_divergente": 0,
            "nombre_divergente": "string",
            "item": {
                "_codigo": 0,
                "_descripcion": "null"
            },
            "muestra": {
                "_codigo": 0,
                "_descripcion": "null"
            },
            "iD_DETALLE_ORDEN_CLINICA": 0
        }
        try {
            // Realiza la solicitud POST utilizando la función del flux
            const response = await fetch('http://10.40.20.13:5105/api/Agenda/valorizaproducto', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Verifica si la respuesta es exitosa
            if (response.ok) {
                const data = await response.json();
                // Extrae el precio de la respuesta y devuélvelo junto con otros datos
                // console.log(data)
                dataRespondeValorizacion.push({
                    codigo: data[0]._obj_prestacion._codigo,
                    descripcion: data[0]._obj_prestacion._descripcion,
                    precio: data[0]._valor_lista
                })

            } else {
                throw new Error('Error al obtener el precio');

            }
        } catch (error) {
            console.error('Error en la solicitud POST:', error);
            // Maneja el error como consideres apropiado
            return {
                value: prestacion.prS_CODIGO,
                label: `${Math.floor(parseFloat(prestacion.prS_CODIGO))} - ${prestacion.prS_DESCRIPCION}`,
                precio: 'hola' // Otra acción si hay un error
            };
        }
        setEstadoPromesa(true);

    }
    ));
    // }, [store.servicioCaja])

    useEffect(() => {
        dataNueva.then(() => {

            // console.log(dataRespondeValorizacion);
            setPromesaCumplida(dataRespondeValorizacion);
            actions.stopWaiting();

        }).catch(error => {
            console.error('Error al completar todas las solicitudes POST:', error);
        });
    }, [estadoPromesa])


    // console.log(dataRespondeValorizacion.length);
    // console.log(dataRespondeValorizacion[0]);
    // useEffect(() => {
    // console.log(promesaCumplida);
    // }, [promesaCumplida])

    //! Manipulacion de la tabla
    const agregarFila = () => {
        setDatosTabla([...datosTabla, { grupo: false, codigo: 0, precio: 0, cantidad: 1, seleccionado: false }]);
    };

    const handleCheckboxChangeGrupo = (indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, grupo: !fila.grupo };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleCheckboxChangeSeleccionado = (indice) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, seleccionado: !fila.seleccionado };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleChangePrecio = (indice, precio, valor) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, precio: ((store.valorizacion.length > 0) ? store.valorizacion[0]._valor_lista : valor) };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const handleChangeCantidad = (indice, cantidad, valor) => {
        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return { ...fila, cantidad: valor };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };

    const eliminarFila = (indice) => {
        const nuevasFilas = datosTabla.filter((fila, idx) => idx !== indice);
        setDatosTabla(nuevasFilas);
    };

    // Manipulacion del ComboBox en Tabla

    useEffect(() => {
        setOptionsPrestaciones(store.prestacionServicioCentroPrueba.map(option => ({ value: option.prS_CODIGO, label: `${Math.floor(parseFloat(option.prS_CODIGO))} - ${option.prS_DESCRIPCION}` })));
    }, [store.prestacionServicioCentroPrueba]);

    // const handleChangeLocalPrestaciones = (selectedOptionPrestaciones, indice) => {
    //     const nuevasFilas = datosTabla.map((fila, idx) => {
    //         if (idx === indice) {
    //             return { ...fila, codigo: selectedOptionPrestaciones.value };
    //         }
    //         return fila;
    //     });
    //     setDatosTabla(nuevasFilas);


    //     // actions.postValorizarProducto();
    // };

    // const handleChangeLocalPrestaciones = (selectedOptionPrestaciones, indice) => {

    //     const codigoPrestacion = selectedOptionPrestaciones.value;
    //     const precioPrestacion = dataRespondeValorizacion.find(item => item.codigo === Math.floor(parseFloat(codigoPrestacion)))?.precio || 0;

    //     const nuevasFilas = datosTabla.map((fila, idx) => {
    //         if (idx === indice) {
    //             return { 
    //                 ...fila, 
    //                 codigo: codigoPrestacion, 
    //                 precio: precioPrestacion 
    //             };
    //         }
    //         return fila;
    //     });
    //     setDatosTabla(nuevasFilas);
    // };

    const handleChangeLocalPrestaciones = (selectedOptionPrestaciones, indice) => {

        let codigoPrestacion;
        let precioPrestacion;

        if (store.fichaAtencion > 0) {
            codigoPrestacion = store.codigoPrestacion;
            precioPrestacion = dataRespondeValorizacion.find(item => item.codigo === Math.floor(parseFloat(codigoPrestacion)))?.precio || 0;
        } else {
            codigoPrestacion = selectedOptionPrestaciones.value;
            precioPrestacion = dataRespondeValorizacion.find(item => item.codigo === Math.floor(parseFloat(codigoPrestacion)))?.precio || 0;
        }

        console.log(codigoPrestacion);
        console.log(precioPrestacion);

        const nuevasFilas = datosTabla.map((fila, idx) => {
            if (idx === indice) {
                return {
                    ...fila,
                    codigo: codigoPrestacion,
                    precio: precioPrestacion
                };
            }
            return fila;
        });
        setDatosTabla(nuevasFilas);
    };


    //# Condicional para definir la informacion que viene en la prestacion
    //# El primer condicional es cuando hago click en una cita Agendada existente y
    //# el segundo condicional es cuando no tengo cita agendada y sea espontanea.

    let descripcionCompleta;

    if (store.fichaAtencion === '') {
        // console.log("Sin FA");
        descripcionCompleta = store.prestacionServicioCentroPrueba.find(prestacion => prestacion.prS_CODIGO === datosTabla[0].codigo) || '';
    } else {
        // console.log("Con FA");
        descripcionCompleta = store.prestacionServicioCentroPrueba.find(prestacion => prestacion.prS_CODIGO === store.codigoPrestacion) || '';
    }

    //! Si cambia store.valorizacion (postValor... es 200) se carga el valor en valorPrestacion
    // useEffect(() => {
    //     if (store.valorizacion.length > 0) {
    //         setValorPrestacion(store.valorizacion[0]._valor_lista);
    //         // console.log(store.valorizacion[0]._valor_lista)
    //     } else {
    //         setValorPrestacion(0);
    //     }
    // }, [store.valorizacion])

    useEffect((indice) => {
        if (store.valorizacion.length > 0) {
            handleChangePrecio(indice, store.valorizacion[0]._valor_lista)
            console.log("valorizó");
        } else {
            return;
        }
    }, [store.valorizacion, datosTabla])


    //! Si cambia store.citaAgendada (Hice click en una agenda), se carga la informacion en SelectedOptionPrestaciones
    useEffect(() => {
        if (store.citaAgendada.length > 0) {
            setSelectedOptionPrestaciones(store.citaAgendada[0].prestacion);
        } else {
            setSelectedOptionPrestaciones('');
        }
    }, [store.citaAgendada]);

    // TODO Me llevo datosTabla al flux en una variable global en flux llamada datosPrestacion
    useEffect(() => {
        actions.handleChangeEspecialPrestacion(datosTabla);
    }, [datosTabla]);

    // TODO Me llevo toda la informacion de la prestacion a su correspondiente variable global en flux
    useEffect(() => {
        actions.setInfoPrestacion(descripcionCompleta);
    }, [descripcionCompleta]);

    // console.log(dataNueva);
    // console.log(store.prestacionServicioCentroPrueba);

    //! ACTIVACION FUNCIONES SIN DEPENDENCIAS
    useEffect(() => {
        actions.getCentrosCaja();
        actions.getComponentesPrestacion();
        actions.getMedicosDerivadoresCompleto();
        actions.getMedicosTratantesCompleto();
        actions.getPrestacionServicioCentroPrueba();
    }, []);

    // useEffect( () => {
    //     actions.postValorizarProducto();
    // }, [selectedOptionPrestaciones])

    // console.log(selectedOptionPrestaciones);

    // console.log(store.codigoPrestacion);
    // console.log(store.citaAgendada);

    // useEffect(() => {
    //     actions.getRutClinica();
    // }, [actions]);

    //! SECCION CONSOLE.LOG
    // console.log(datosTabla)
    console.log("hola")

    return (
        <div className="container caja">
            <div className="row">
                <div className="col-12 conditionalBody">
                    <div className="boxes buscadorPaciente mb-0 d-flex">
                        <div className="col-sm-12 col-md-2">
                            {/* SELECT CENTRO --> REQUERIDO */}
                            <select className="form-select" name="centroCaja" value={store.centroCaja} onChange={e => { actions.handleChange(e); actions.getRutClinica(); actions.getServiciosCaja(e) }} >
                                <option value="" defaultValue>Centros</option>
                                {
                                    store.centrosCaja && store.centrosCaja.map(centroMedico => {
                                        return (
                                            <option key={centroMedico.ceN_IDENT} value={centroMedico.ceN_IDENT}>{centroMedico.ceN_DESCP}</option>
                                        )
                                    })
                                }
                            </select>
                            {/* SELECT SERVICIO --> REQUERIDO */}
                            <select className="form-select" name="servicioCaja" value={store.servicioCaja} onChange={e => { actions.handleChange(e); actions.getCitasAgendadas(e); actions.getPrestacionServicioCentro(e) }} >
                                <option value="" defaultValue>Servicios</option>
                                {
                                    store.serviciosCaja && store.serviciosCaja.map(servicioClinico => {

                                        // Creacion Key unico
                                        const serviceKey = Math.floor(parseInt(servicioClinico.srV_CODIGO)) + servicioClinico.srV_DESCRIPCION

                                        return (
                                            <option key={serviceKey} value={Math.floor(parseFloat(servicioClinico.srV_CODIGO))}>{servicioClinico.srV_DESCRIPCION}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        {/* Filtrador por Rut */}
                        <form className="col-sm-12 col-md-4 mx-auto my-auto" onSubmit={e => actions.getPacientePorDocto(e)}>
                            <span>Buscar Paciente: </span>
                            {/* <input type="text" id="buscaRut" name="docto" value={filtro} onChange={e => { handleFiltroChange(e); actions.handleChange(e) }} onKeyDown={e => handleRutInputCaja(e)} placeholder="Ingrese RUT" /> */}
                            <input
                                type="text"
                                id="rutInput"
                                onChange={e => { handleFiltroChange(e) }}
                                onKeyDown={e => handleRutInputCaja(e)}
                                placeholder="Ingresa el RUT"
                            />
                            <button className="btn btn-success" type="submit"><img className="icon whiteIcon" src={BuscarIcon} alt="Buscar" /></button>
                        </form>
                        {
                            // Formulario cuando existe Paciente
                            (store.nombrePaciente !== '') ? (
                                <div className="infoPaciente col-sm-12 col-md-5 d-flex mx-auto my-auto">
                                    <div className="col-md-11">
                                        <div className="d-flex">
                                            <p><img className="iconMini" src={UserIcon} alt="Paciente" /> {store.nombrePaciente} {store.apPaternoPaciente}</p>
                                            <p className="mx-3"><img className="iconMini" src={IdIcon} alt="Identificación" /> {store.docto}</p>
                                        </div>
                                        <div className="d-flex">
                                            <p><img className="iconMini" src={BirthIcon} alt="Cumpleaños" /> {años} años, {meses} meses</p>
                                            <p className="mx-3"><img className="iconMini" src={PhoneIcon} alt="Teléfono" /> {store.celularPaciente}</p>
                                            <p><img className="iconMini" src={ArrobaIcon} alt="Email" /> {store.emailPaciente}</p>
                                            <p className="mx-3"><img className="iconMini" src={GenderIcon} alt="Sexo" /> {(store.sexoPaciente === "M") ? "Masculino" : (store.sexoPaciente === "F") ? "Femenino" : "Indefinido"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <button className="btn btn-warning" type="button" data-bs-toggle="modal" data-bs-target="#cajaModal" >
                                            <img className="icon whiteIcon newMargin" src={PenIcon} alt="Modificar" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="infoPaciente col-sm-12 col-md-5 mx-auto my-auto">

                                </div>
                            )
                        }
                    </div>
                    <div className="d-flex boxPacientePrestacion">
                        <div className="boxes">
                            <div className="tablaPacientes">
                                <p>Pacientes Agendados</p>
                                <table className="table table-striped table-hover">
                                    <thead className="text-center">
                                        <tr>
                                            <th scope="col">Hora</th>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Apellido</th>
                                            <th scope="col">Especialidad</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {
                                            citasFiltradas ? citasFiltradas.map(citaAgendada => {

                                                const fechaCita = new Date(citaAgendada.fechA_CITA);
                                                // Obtener la hora y los minutos
                                                const horaCita = fechaCita.getHours();
                                                const minutoCita = fechaCita.getMinutes();

                                                // Formatear la hora y los minutos con dos dígitos
                                                const horaCitaFormateada = (horaCita < 10 ? '0' : '') + horaCita;
                                                const minutoCitaFormateados = (minutoCita < 10 ? '0' : '') + minutoCita;

                                                // Construir la cadena de tiempo en formato HH:MM
                                                const horaCitaCompleta = `${horaCitaFormateada}:${minutoCitaFormateados}`;

                                                return (
                                                    <tr className="text-center" name="citaAgendada" value={citaAgendada} key={citaAgendada.fA_ID} onClick={() => { actions.handleChangeEspecialCaja(citaAgendada); handleChangeLocalPrestaciones(selectedOptionPrestaciones, 0) }}>
                                                        <th scope="row">{horaCitaCompleta}</th>
                                                        <td>{citaAgendada.pgL_NOMBRES}</td>
                                                        <td>{citaAgendada.pgL_PATERNO}</td>
                                                        <td>{citaAgendada.especialidad}</td>
                                                    </tr>
                                                )
                                            }) : (
                                                <div></div>
                                            )
                                        }
                                    </tbody>
                                </table>
                                {/* <DayPicker
                                    defaultMonth={store.fechaInicial}
                                    showOutsideDays
                                    mode="single"
                                    selected={store.fechaInicial}
                                    onSelect={e => actions.setFecha(e)}
                                    disabled={disabledDays}
                                    ISOWeek
                                    locale={es}
                                /> */}
                            </div>
                        </div>
                        <div className="container px-0">
                            <div className="boxes tabla tablaPrestacion">
                                <h5 className="ps-2 mt-2 mb-4 c-primary">Datos de la Atención</h5>
                                <div className="row">
                                    {
                                        (store.docto) ? (
                                            // Visualizacion CON datos
                                            <>
                                                <p>Caso Con Datos</p>
                                                <div className="d-flex flex-wrap">
                                                    {/* FECHA => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Fecha</label>
                                                        <input className="form-control mx-2 mt-1 mb-2" type="text" defaultValue={store.fechaCita ? fechaCitaFormateada : ""} />
                                                    </div>
                                                    {/* HORA => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Hora</label>
                                                        <input className="form-control mx-2 mt-1 mb-2" type="text" defaultValue={store.fechaCita ? horaMinutoFormato : ""} />
                                                    </div>
                                                    {/* MEDICO TRATANTE => SELECT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Médico Tratante</label>
                                                        <Select
                                                            className="comboBoxPrestaciones"
                                                            options={optionsMedicoTratante}
                                                            onChange={handleChangeMedicoTratante}
                                                            value={optionsMedicoTratante.find(option => ((store.rutMedicoSDV !== '') ? parseInt(option.value) === parseInt(store.rutMedicoSDV) : ''))}
                                                            placeholder="Médicos"
                                                        // isClearable
                                                        />
                                                    </div>
                                                    {/* MEDICO DERIVADOR => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Médico Derivador</label>
                                                        <select className="form-select mx-2 mt-1 mb-2" name="medicoDerivador" onChange={e => { actions.handleChange(e) }} value={store.medicoDerivador} >
                                                            <option value="" defaultValue>Elige Médico</option>
                                                            {
                                                                store.medicosDerivadores && store.medicosDerivadores.map(medicoDerivador => {
                                                                    // console.log(medicoTratante.meD_RUT);
                                                                    return (
                                                                        <option key={Math.floor(parseFloat(medicoDerivador.meD_RUT))} value={Math.floor(parseFloat(medicoDerivador.meD_RUT))}>{medicoDerivador.meD_NOMBRE} {medicoDerivador.meD_APELLIDO_P} {medicoDerivador.meD_APELLIDO_M}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {/* CONVENIO => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Convenio</label>
                                                        <input className="form-control mx-2 mt-1 mb-2" type="text" />
                                                    </div>
                                                </div>
                                                <hr className="mx-auto width70" />
                                                <h5 className="ps-2 mt-2 mb-4 c-primary">Prestaciones</h5>
                                                <div className="scrollableTable scrollTableY">
                                                    <div className="text-center">
                                                        {/* //! AQUI ESTOY TRABAJANDO!!!!
                                                            //? TABLA DINAMICA PRESTACIONES
                                                            //# AQUI ESTOY TRABAJANDO!!!! */}
                                                        <table className="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>Grupo</th>
                                                                    <th>Prestación</th>
                                                                    <th>Valor</th>
                                                                    <th>Cantidad</th>
                                                                    <th>Particular</th>
                                                                    <th>Acción</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    datosTabla.map((fila, indice) => (
                                                                        <tr key={indice}>
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={fila.grupo}
                                                                                    onChange={() => handleCheckboxChangeGrupo(indice)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className="comboBoxPrestaciones"
                                                                                    options={optionsPrestaciones}
                                                                                    onChange={(selectedOptionPrestaciones) => handleChangeLocalPrestaciones(selectedOptionPrestaciones, indice)}
                                                                                    value={optionsPrestaciones.find((option) => (
                                                                                        (store.codigoPrestacion) ? option.value === store.codigoPrestacion : option.value === fila.codigo
                                                                                    ))}
                                                                                    menuPortalTarget={document.body}
                                                                                    placeholder="Prestaciones"
                                                                                // isClearable
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={fila.precio}
                                                                                    onChange={(e) => handleChangePrecio(indice, 'precio', e.target.value)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={fila.cantidad}
                                                                                    onChange={(e) => handleChangeCantidad(indice, 'cantidad', e.target.value)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={fila.seleccionado}
                                                                                    onChange={() => handleCheckboxChangeSeleccionado(indice)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    indice === datosTabla.length - 1 && (
                                                                                        <button className="btn btn-success" onClick={agregarFila}><img className="icon whiteIcon" src={CheckIcon} alt="Agregar Fila" /></button>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    indice !== datosTabla.length - 1 && (
                                                                                        <button className="btn btn-warning" onClick={() => eliminarFila(indice)}><img className="icon blackIcon" src={BorrarIcon} alt="Eliminar Fila" /></button>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="text-center">
                                                        {/* <a className="btn btn-success mx-5" href="https://www.imed.cl" target="_blank" rel="noreferrer">Bono</a>
                                                        <a className="btn btn-warning" href="https://www.webpay.com" target="_blank" rel="noreferrer">T. Débito</a>
                                                        <a className="btn btn-info mx-5" href="https://www.transbank.com" target="_blank" rel="noreferrer">T. Crédito</a>
                                                        <a className="btn btn-primary" href="https://www.gmail.com" target="_blank" rel="noreferrer">Efectivo</a>
                                                        <a className="btn btn-secondary mx-5" href="https://www.emol.com" target="_blank" rel="noreferrer">Bono Clini</a>
                                                        <a className="btn btn-dark" href="https://www.bice.cl" target="_blank" rel="noreferrer">Transferencia</a>
                                                        <a className="btn btn-danger mx-5" href="https://www.hdi.cl" target="_blank" rel="noreferrer">Convenio</a> */}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            //? Visualizacion SIN datos
                                            <>
                                                <p>Caso Base Sin Datos</p>
                                                <div className="d-flex flex-wrap">
                                                    {/* FECHA => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Fecha</label>
                                                        <input className="form-control mx-2 mt-1 mb-2" name="diaActualCaja" value={diaActualCaja} onChange={e => handleChangeDiaCaja(e)} type="text" />
                                                    </div>
                                                    {/* HORA => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Hora</label>
                                                        <input className="form-control mx-2 mt-1 mb-2" name="horaActualCaja" value={horaActualCaja} onChange={e => handleChangeHoraCaja(e)} type="text" />
                                                    </div>
                                                    {/* MEDICO TRATANTE => SELECT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Médico Tratante</label>
                                                        <Select
                                                            className="comboBoxPrestaciones"
                                                            options={optionsMedicoTratante}
                                                            onChange={handleChangeMedicoTratante}
                                                            value={selectedOptionMedicoTratante}
                                                            placeholder="Médicos"
                                                        // isClearable
                                                        />
                                                        {/* <input className="form-control mx-2 mt-1 mb-2" type="text" /> */}
                                                    </div>
                                                    {/* MEDICO DERIVADOR => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Médico Derivador</label>
                                                        <select className="form-select mx-2 mt-1 mb-2" name="medicoDerivador" onChange={e => { actions.handleChange(e) }} value={store.medicoDerivador} >
                                                            <option value="" defaultValue>Elige Médico</option>
                                                            {
                                                                store.medicosDerivadores && store.medicosDerivadores.map(medicoDerivador => {
                                                                    // console.log(medicoTratante.meD_RUT);
                                                                    return (
                                                                        <option key={Math.floor(parseFloat(medicoDerivador.meD_RUT))} value={Math.floor(parseFloat(medicoDerivador.meD_RUT))}>{medicoDerivador.meD_NOMBRE} {medicoDerivador.meD_APELLIDO_P} {medicoDerivador.meD_APELLIDO_M}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {/* CONVENIO => INPUT */}
                                                    <div>
                                                        <label htmlFor="" className="text-small">Convenio</label>
                                                        <input className="form-control mx-2 mt-1 mb-2" type="text" />
                                                    </div>
                                                </div>
                                                <hr className="mx-auto width70" />
                                                <h5 className="ps-2 mt-2 mb-4 c-primary">Prestaciones</h5>
                                                <div className="scrollableTable scrollTableY">
                                                    <div className="text-center">
                                                        <div className="tabla">

                                                            {
                                                                (store.esperando === false) ? (
                                                                    (promesaCumplida.length > 0) ? (
                                                                        <table className="table table-striped table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Grupo</th>
                                                                                    <th>Prestación</th>
                                                                                    <th>Precio</th>
                                                                                    <th>Cantidad</th>
                                                                                    <th>Particular</th>
                                                                                    <th>Acción</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    datosTabla.map((fila, indice) => {
                                                                                        return (
                                                                                            <tr key={indice}>
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={fila.grupo}
                                                                                                        onChange={() => handleCheckboxChangeGrupo(indice)}
                                                                                                    />
                                                                                                </td>
                                                                                                {/* //! AQUI ESTOY TRABAJANDO!!!!
                                                                                                //? TABLA DINAMICA PRESTACIONES
                                                                                                //# AQUI ESTOY TRABAJANDO!!!! */}
                                                                                                <td>
                                                                                                    <Select
                                                                                                        className="comboBoxPrestaciones"
                                                                                                        options={optionsPrestaciones}
                                                                                                        onChange={(selectedOptionPrestaciones) => { handleChangeLocalPrestaciones(selectedOptionPrestaciones, indice) }}
                                                                                                        value={optionsPrestaciones.find(option => option.value === fila.codigo)}
                                                                                                        menuPortalTarget={document.body}
                                                                                                        placeholder="Prestaciones"
                                                                                                    // isClearable
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        type="text"
                                                                                                        value={fila.precio}
                                                                                                        onChange={(e) => handleChangePrecio(indice, 'precio', e.target.value)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        type="text"
                                                                                                        value={fila.cantidad}
                                                                                                        onChange={(e) => handleChangeCantidad(indice, 'cantidad', e.target.value)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={fila.seleccionado}
                                                                                                        onChange={() => handleCheckboxChangeSeleccionado(indice)}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        indice === datosTabla.length - 1 && (
                                                                                                            <button className="btn btn-success" onClick={agregarFila}><img className="icon whiteIcon" src={CheckIcon} alt="Agregar Fila" /></button>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        indice !== datosTabla.length - 1 && (
                                                                                                            <button className="btn btn-warning" onClick={() => eliminarFila(indice)}><img className="icon blackIcon" src={BorrarIcon} alt="Eliminar Fila" /></button>
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    )
                                                                        : (
                                                                            <p>No hay información para mostrar</p>
                                                                        )
                                                                )
                                                                    : (
                                                                        <div className="spinner-border" role="status">
                                                                            <span className="visually-hidden">Cargando</span>
                                                                        </div>
                                                                    )
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        {/* <a className="btn btn-success mx-5" href="https://www.imed.cl" target="_blank" rel="noreferrer">Bono</a>
                                                        <a className="btn btn-warning" href="https://www.webpay.com" target="_blank" rel="noreferrer">T. Débito</a>
                                                        <a className="btn btn-info mx-5" href="https://www.transbank.com" target="_blank" rel="noreferrer">T. Crédito</a>
                                                        <a className="btn btn-primary" href="https://www.gmail.com" target="_blank" rel="noreferrer">Efectivo</a>
                                                        <a className="btn btn-secondary mx-5" href="https://www.emol.com" target="_blank" rel="noreferrer">Bono Clini</a>
                                                        <a className="btn btn-dark" href="https://www.bice.cl" target="_blank" rel="noreferrer">Transferencia</a>
                                                        <a className="btn btn-danger mx-5" href="https://www.hdi.cl" target="_blank" rel="noreferrer">Convenio</a> */}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="boxes botones ms-0">
                                <button className="btn btn-success">Orden Médica</button>
                                <button className="btn btn-warning">Orden Interna</button>
                                <button className="btn btn-danger">Notas</button>
                                <Link className="btn btn-primary" to="/caja/recaudacion">Siguiente</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span className="modal modalAgenda fade" id="cajaModal" tabIndex="-1" aria-hidden="true">
                <span className="modal-dialog">
                    {
                        // Alert de datos mal ingresados
                        (resultadoValidacion === false || resultadoValidacion === null) ? (
                            <div className="alert alert-danger fade show text-center" role="alert">
                                Ingresa Correctamente los datos
                            </div>
                        ) : (
                            null
                        )
                    }
                    <span className="modal-content d-flex">
                        <span className="modal-header row">
                            <div className="col-6">
                                <h5 className="mt-1">{store.codIdent ? "Modificar" : "Agregar"} Paciente</h5>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn-close d-flex justify-content-end me-2 cerrarModal" data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                        </span>
                        <span className="modal-body">
                            <div className="row">
                                {
                                    store.codIdent ? (
                                        <form onSubmit={e => { actions.postModificarPaciente(e) }}>
                                            <p>Paciente Encontrado - Modificar</p>
                                            <div className="d-flex">
                                                {/* Col. Izq. */}
                                                <span className="col-md-4 offset-md-1 col-sm-12">
                                                    {/* Tipo Docto => Select */}
                                                    <span className="input-group my-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Docto.</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="tipoDoc" onChange={e => actions.handleChange(e)} value={store.tipoDoc}>
                                                                <option value="">Elige Documento</option>
                                                                {
                                                                    store.tipoDocto && store.tipoDocto.map(docto => {
                                                                        return (
                                                                            <option value={docto.tD_ID} key={docto.tD_ID}>{docto.tD_DESCP}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Nombre => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Nombre</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="nombrePaciente" value={store.nombrePaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Ap. Paterno => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Apellido Paterno</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="apPaternoPaciente" value={store.apPaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Ap. Materno => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Apellido Materno</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="apMaternoPaciente" value={store.apMaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Nombre Social => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Nombre Social</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="nombreSocial" value={(store.nombreSocial === null ? '' : store.nombreSocial)} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Teléfono => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Teléfono</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="celularPaciente" value={store.celularPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Correo => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Correo</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input type="text" className="form-control" name="emailPaciente" value={store.emailPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Fecha Nacimiento => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Fecha Nacimiento</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input type="text" className="form-control" placeholder="dd/mm/aaaa" name="fecNacPaciente" value={store.fecNacPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                </span>
                                                {/* Col. Der. */}
                                                <span className="col-md-4 offset-md-1 col-12">
                                                    {/* Rut => Input */}
                                                    <span className="input-group my-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">RUT</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.docto} placeholder="12345678-9" />
                                                        </div>
                                                    </span>
                                                    {/* Sexo => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Sexo</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="sexoPaciente" value={store.sexoPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                <option value=""></option>
                                                                {
                                                                    store.tipoSexo && store.tipoSexo.map(sexo => (
                                                                        <option value={sexo.seX_ID} key={sexo.seX_ID}>{sexo.seX_DESCP}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Estado Civíl => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Estado Civil</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="estCivilPaciente" value={store.estCivilPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                <option value=""></option>
                                                                {
                                                                    store.tipoEstadosCiviles && store.tipoEstadosCiviles.map(estadoCivil => (
                                                                        <option value={estadoCivil.eC_ID} key={estadoCivil.eC_ID}>{estadoCivil.eC_DESCP}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Dirección => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Dirección</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input type="text" className="form-control" name="direccionPaciente" value={store.direccionPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* País => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Pais</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="paisElegido" value={store.paisElegido} onChange={e => actions.handleChange(e)}>
                                                                <option value="">--Elige País--</option>
                                                                <option value="213">CHILE</option>
                                                                {
                                                                    store.nombrePais && store.nombrePais.map(pais => {
                                                                        return (
                                                                            <option value={Math.floor(parseFloat(pais.pS_CODIGO))} key={pais.pS_CODIGO}>{pais.pS_DESCRIPCION}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Comuna => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Comuna</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="comunaElegida" value={store.comunaElegida} onChange={e => { actions.handleChange(e) }}>
                                                                <option value="">--Elige Comuna--</option>
                                                                {
                                                                    store.nombreComunas && store.nombreComunas.map(comuna => {
                                                                        return (
                                                                            <option value={comuna.coM_CODIGO} key={comuna.coM_CODIGO}>{comuna.coM_DESCRIPCION}</option>
                                                                        )
                                                                    })
                                                                }
                                                                <option value=""></option>
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Etnia => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Etnia</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="etniaPaciente" value={store.etniaPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                <option value=""></option>
                                                                {
                                                                    store.tipoEtnias && store.tipoEtnias.map(etnia => (
                                                                        <option value={etnia.etN_CODIGO} key={etnia.etN_CODIGO}>{etnia.etN_DESCRIPCION}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-success mx-2" type="submit" data-bs-dismiss="modal" aria-label="Close"><img className="icon mx-1 whiteIcon" src={GuardarIcon} alt="Guardar" /></button>
                                            </div>
                                        </form>
                                    ) : (
                                        <form onSubmit={e => { actions.postAgregarPaciente(e) }}>
                                            <p>Paciente No Encontrado - Agregar</p>
                                            <div className="d-flex">
                                                {/* Col. Izq. */}
                                                <span className="col-md-4 offset-md-1 col-sm-12">
                                                    {/* Tipo Docto => Select */}
                                                    <span className="input-group my-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Docto.</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="tipoDoc" onChange={e => actions.handleChange(e)} value={store.tipoDoc}>
                                                                <option value="">Elige Documento</option>
                                                                {
                                                                    store.tipoDocto && store.tipoDocto.map(docto => {
                                                                        return (
                                                                            <option value={docto.tD_ID} key={docto.tD_ID}>{docto.tD_DESCP}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Nombre => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Nombre</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="nombrePaciente" value={store.nombrePaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Ap. Paterno => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Apellido Paterno</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="apPaternoPaciente" value={store.apPaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Ap. Materno => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Apellido Materno</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="apMaternoPaciente" value={store.apMaternoPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Nombre Social => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Nombre Social</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="nombreSocial" value={(store.nombreSocial === null ? '' : store.nombreSocial)} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Teléfono => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className='col-5'>
                                                            <span className="input-group-text">Teléfono</span>
                                                        </div>
                                                        <div className='col-7'>
                                                            <input type="text" className="form-control" name="celularPaciente" value={store.celularPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Correo => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Correo</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input type="text" className="form-control" name="emailPaciente" value={store.emailPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* Fecha Nacimiento => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Fecha Nacimiento</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input type="text" className="form-control" placeholder="dd/mm/aaaa" name="fecNacPaciente" value={store.fecNacPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                </span>
                                                {/* Col. Der. */}
                                                <span className="col-md-4 offset-md-1 col-12">
                                                    {/* Rut => Input */}
                                                    <span className="input-group my-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">RUT</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input className={resultadoValidacion === false ? "form-control noValido" : "form-control"} id="rutInput" type="text" name="docto" onChange={e => [actions.handleChange(e)]} value={store.docto} placeholder="12345678-9" />
                                                        </div>
                                                    </span>
                                                    {/* Sexo => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Sexo</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="sexoPaciente" value={store.sexoPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                <option value=""></option>
                                                                {
                                                                    store.tipoSexo && store.tipoSexo.map(sexo => (
                                                                        <option value={sexo.seX_ID} key={sexo.seX_ID}>{sexo.seX_DESCP}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Estado Civíl => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Estado Civil</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="estCivilPaciente" value={store.estCivilPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                <option value=""></option>
                                                                {
                                                                    store.tipoEstadosCiviles && store.tipoEstadosCiviles.map(estadoCivil => (
                                                                        <option value={estadoCivil.eC_ID} key={estadoCivil.eC_ID}>{estadoCivil.eC_DESCP}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Dirección => Input */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Dirección</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <input type="text" className="form-control" name="direccionPaciente" value={store.direccionPaciente} onChange={e => { actions.handleChange(e) }} />
                                                        </div>
                                                    </span>
                                                    {/* País => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Pais</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="paisElegido" value={store.paisElegido} onChange={e => actions.handleChange(e)}>
                                                                <option value="">--Elige País--</option>
                                                                <option value="213">CHILE</option>
                                                                {
                                                                    store.nombrePais && store.nombrePais.map(pais => {
                                                                        return (
                                                                            <option value={Math.floor(parseFloat(pais.pS_CODIGO))} key={pais.pS_CODIGO}>{pais.pS_DESCRIPCION}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Comuna => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Comuna</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="comunaElegida" value={store.comunaElegida} onChange={e => { actions.handleChange(e) }}>
                                                                <option value="">--Elige Comuna--</option>
                                                                {
                                                                    store.nombreComunas && store.nombreComunas.map(comuna => {
                                                                        return (
                                                                            <option value={comuna.coM_CODIGO} key={comuna.coM_CODIGO}>{comuna.coM_DESCRIPCION}</option>
                                                                        )
                                                                    })
                                                                }
                                                                <option value=""></option>
                                                            </select>
                                                        </div>
                                                    </span>
                                                    {/* Etnia => Select */}
                                                    <span className="input-group mb-3">
                                                        <div className="col-5">
                                                            <span className="input-group-text">Etnia</span>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-control m-0" name="etniaPaciente" value={store.etniaPaciente} onChange={e => { actions.handleChange(e) }}>
                                                                <option value=""></option>
                                                                {
                                                                    store.tipoEtnias && store.tipoEtnias.map(etnia => (
                                                                        <option value={etnia.etN_CODIGO} key={etnia.etN_CODIGO}>{etnia.etN_DESCRIPCION}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </span>
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-success mx-2" type="submit" data-bs-dismiss="modal" aria-label="Close"><img className="icon mx-1 whiteIcon" src={GuardarIcon} alt="Guardar" /></button>
                                            </div>
                                        </form>
                                    )
                                }
                            </div>
                        </span>
                    </span>
                </span>
            </span>
        </div>
    );
}

export default Caja1;
