import React from 'react';

// STATIC
import EnConstruccion from '../static/img/underConstruction.png';

const UnderConstruction = () => {
    return (
        <div className="container">
            <img className="enConstruccion mt-5" src={EnConstruccion} alt="En Construccion" />
        </div>
    );
}

export default UnderConstruction;