const CitasTomadas = [
  {
    "id": 1,
    "rut": "15234253-5",
    "num_ficha": 320789,
    "nombre": "Damián",
    "apellido_paterno": "Luque",
    "apellido_materno": "Escribano",
    "sexo": "m",
    "fecha_nacimiento": "1970-07-09T03:55:57.685Z",
    "direccion": "Calle Iquique, 156",
    "comuna": "San Bernardo",
    "correo": "Luque_Escribano@gmail.com",
    "telefono": "+56996040483",
    "aseguradora": "Colmena",
    "centros": "RM - Santiago - Lyon",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO",
    "prevision": "Fonasa",
    "fecha": "22/02/2024",
    "hora": "9:30",
    "cod_prestacion": 300562,
    "item": "CIRUGÍA VASCULAR",
    "estado": "agendadas",
    "valor": 1174099
  },
  {
    "id": 2,
    "rut": "8795718-7",
    "num_ficha": 320932,
    "nombre": "Santiago",
    "apellido_paterno": "Gaos",
    "apellido_materno": "Santa Maria",
    "sexo": "m",
    "fecha_nacimiento": "1984-03-08T11:21:48.278Z",
    "direccion": "Autopista Concepción-talcahuano, 6930",
    "comuna": "Talcahuano",
    "correo": "Gaos_Santa Maria@gmail.com",
    "telefono": "+56993397401",
    "aseguradora": "Escencial",
    "centros": "RM - Santiago - Tobalaba",
    "servicios": "CONSULTA",
    "especialidad": "SERVICIO A DOMICILIO",
    "prevision": "Banmedica",
    "fecha": "23/02/2024",
    "hora": "10:00",
    "cod_prestacion": 300185,
    "item": "CIRUGÍA MAXILOFACIAL",
    "estado": "espera",
    "valor": 2158849
  },
  {
    "id": 3,
    "rut": "13694708-7",
    "num_ficha": 320965,
    "nombre": "Benito",
    "apellido_paterno": "Berganza",
    "apellido_materno": "Mancebo",
    "sexo": "m",
    "fecha_nacimiento": "1967-03-04T16:03:13.075Z",
    "direccion": "Avda Holanda 934",
    "comuna": "Providencia",
    "correo": "Berganza_Mancebo@gmail.com",
    "telefono": "+56998866455",
    "aseguradora": "Vida Tres",
    "centros": "Antofagasta - Diagnosalud",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "LABORATORIO PCR CLINI",
    "prevision": "Colmena",
    "fecha": "24/02/2024",
    "hora": "10:30",
    "cod_prestacion": 300067,
    "item": "SIN ESPECIALIDAD",
    "estado": "enAtencion",
    "valor": 2985286
  },
  {
    "id": 4,
    "rut": "10145340-5",
    "num_ficha": 321075,
    "nombre": "Facundo",
    "apellido_paterno": "Echeverria",
    "apellido_materno": "Amengual",
    "sexo": "m",
    "fecha_nacimiento": "1969-09-23T08:42:02.967Z",
    "direccion": "Calle Eleuterio Ramírez, 951",
    "comuna": "Osorno",
    "correo": "Echeverria_Amengual@gmail.com",
    "telefono": "+56992580418",
    "aseguradora": "ISalud",
    "centros": "Antofagasta - Más Visión",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO CLÍNICO CLINI DOM",
    "prevision": "Cruz del Norte",
    "fecha": "25/02/2024",
    "hora": "11:00",
    "cod_prestacion": 300807,
    "item": "PSICOLOGÍA",
    "estado": "atendido",
    "valor": 2702561
  },
  {
    "id": 5,
    "rut": "9587302-2",
    "num_ficha": 321099,
    "nombre": "Édgar",
    "apellido_paterno": "Fernán",
    "apellido_materno": "Pozo",
    "sexo": "m",
    "fecha_nacimiento": "1968-02-19T00:36:54.769Z",
    "direccion": "Avenida Apoquindo, 4100",
    "comuna": "Las Condes",
    "correo": "Fernán_Pozo@gmail.com",
    "telefono": "+56990401393",
    "aseguradora": "Colmena",
    "centros": "Clinivisión Condell",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO PCR CLINI DOM",
    "prevision": "Cruz Blanca",
    "fecha": "26/02/2024",
    "hora": "11:30",
    "cod_prestacion": 300944,
    "item": "CIRUGÍA GENERAL",
    "estado": "canceladas",
    "valor": 4695889
  },
  {
    "id": 6,
    "rut": "14750111-0",
    "num_ficha": 321112,
    "nombre": "Jerónimo",
    "apellido_paterno": "Miralles",
    "apellido_materno": "Fernandino",
    "sexo": "m",
    "fecha_nacimiento": "1960-03-16T14:10:36.590Z",
    "direccion": "Calle Queilén, 6095",
    "comuna": "Pedro Aguirre Cerda",
    "correo": "Miralles_Fernandino@gmail.com",
    "telefono": "+56998843285",
    "aseguradora": "Escencial",
    "centros": "RM - Santiago - Lyon",
    "servicios": "CONSULTA",
    "especialidad": "IMÁGENES",
    "prevision": "Fundacion",
    "fecha": "22/02/2024",
    "hora": "12:00",
    "cod_prestacion": 300080,
    "item": "CIRUGÍA VASCULAR",
    "estado": "agendadas",
    "valor": 1448869
  },
  {
    "id": 7,
    "rut": "10066136-5",
    "num_ficha": 321255,
    "nombre": "Ignacio",
    "apellido_paterno": "Sanz",
    "apellido_materno": "Tassis",
    "sexo": "m",
    "fecha_nacimiento": "1975-08-31T14:57:58.983Z",
    "direccion": "Avenida El Guanaco, 3469",
    "comuna": "Recoleta",
    "correo": "Sanz_Tassis@gmail.com",
    "telefono": "+56999776084",
    "aseguradora": "Particular",
    "centros": "RM - Santiago - Tobalaba",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO DE CARDIOLOGÍA",
    "prevision": "ISalud",
    "fecha": "23/02/2024",
    "hora": "12:30",
    "cod_prestacion": 300897,
    "item": "CIRUGÍA GENERAL",
    "estado": "espera",
    "valor": 2662644
  },
  {
    "id": 8,
    "rut": "15573881-2",
    "num_ficha": 321365,
    "nombre": "Enrique",
    "apellido_paterno": "Gaona",
    "apellido_materno": "Gisbert",
    "sexo": "m",
    "fecha_nacimiento": "1960-10-05T09:31:16.356Z",
    "direccion": "Avenida Egaña, 474",
    "comuna": "La Reina",
    "correo": "Gaona_Gisbert@gmail.com",
    "telefono": "+56999697858",
    "aseguradora": "Consalud",
    "centros": "Antofagasta - Diagnosalud",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "OFTALMOLOGÍA",
    "prevision": "Nueva MasVida",
    "fecha": "24/02/2024",
    "hora": "13:00",
    "cod_prestacion": 300016,
    "item": "CIRUGÍA GENERAL",
    "estado": "enAtencion",
    "valor": 2199378
  },
  {
    "id": 9,
    "rut": "15152141-k",
    "num_ficha": 321565,
    "nombre": "Santiago",
    "apellido_paterno": "Garrido",
    "apellido_materno": "Berrocal",
    "sexo": "i",
    "fecha_nacimiento": "1971-12-28T12:28:55.589Z",
    "direccion": "Paseo Estado, 337",
    "comuna": "Santiago",
    "correo": "Garrido_Berrocal@gmail.com",
    "telefono": "+56990563072",
    "aseguradora": "Particular",
    "centros": "Antofagasta - Más Visión",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO",
    "prevision": "Vida Tres",
    "fecha": "25/02/2024",
    "hora": "13:30",
    "cod_prestacion": 300439,
    "item": "GINECOLOGÍA",
    "estado": "atendido",
    "valor": 1097957
  },
  {
    "id": 10,
    "rut": "8340534-1",
    "num_ficha": 321725,
    "nombre": "Kevin",
    "apellido_paterno": "Abasto",
    "apellido_materno": "Encarnación",
    "sexo": "m",
    "fecha_nacimiento": "1986-05-12T09:50:52.184Z",
    "direccion": "Calle Juntas Viejas, 794",
    "comuna": "San Javier",
    "correo": "Abasto_Encarnación@gmail.com",
    "telefono": "+56991662578",
    "aseguradora": "Banmedica",
    "centros": "Clinivisión Condell",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "SERVICIO A DOMICILIO",
    "prevision": "Particular",
    "fecha": "26/02/2024",
    "hora": "14:00",
    "cod_prestacion": 300221,
    "item": "CIRUGÍA GENERAL",
    "estado": "canceladas",
    "valor": 4272242
  },
  {
    "id": 11,
    "rut": "9546056-9",
    "num_ficha": 321745,
    "nombre": "Kevin",
    "apellido_paterno": "Gálvez",
    "apellido_materno": "Tafalla",
    "sexo": "i",
    "fecha_nacimiento": "1982-10-14T01:09:16.642Z",
    "direccion": "Pasaje Darwin, 175",
    "comuna": "Punta Arenas",
    "correo": "Gálvez_Tafalla@gmail.com",
    "telefono": "+56992126199",
    "aseguradora": "Escencial",
    "centros": "RM - Santiago - Lyon",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO PCR CLINI",
    "prevision": "Fonasa",
    "fecha": "22/02/2024",
    "hora": "14:30",
    "cod_prestacion": 300524,
    "item": "CIRUGÍA VASCULAR",
    "estado": "agendadas",
    "valor": 4282944
  },
  {
    "id": 12,
    "rut": "14418853-5",
    "num_ficha": 321752,
    "nombre": "Luis",
    "apellido_paterno": "Montenegro",
    "apellido_materno": "Alarcón",
    "sexo": "m",
    "fecha_nacimiento": "1964-02-14T13:17:39.040Z",
    "direccion": "Latorre 779",
    "comuna": "Iquique",
    "correo": "Montenegro_Alarcón@gmail.com",
    "telefono": "+56992247773",
    "aseguradora": "ISalud",
    "centros": "RM - Santiago - Tobalaba",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO CLÍNICO CLINI DOM",
    "prevision": "Banmedica",
    "fecha": "23/02/2024",
    "hora": "15:00",
    "cod_prestacion": 300861,
    "item": "TRAUMATOLOGÍA",
    "estado": "espera",
    "valor": 983775
  },
  {
    "id": 13,
    "rut": "12739144-0",
    "num_ficha": 321870,
    "nombre": "Alonzo",
    "apellido_paterno": "Agramonte",
    "apellido_materno": "Arrabal",
    "sexo": "m",
    "fecha_nacimiento": "1962-01-25T09:19:26.313Z",
    "direccion": "Calle La Concepción, 191",
    "comuna": "Santa Cruz",
    "correo": "Agramonte_Arrabal@gmail.com",
    "telefono": "+56992623222",
    "aseguradora": "Vida Tres",
    "centros": "Antofagasta - Diagnosalud",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO PCR CLINI DOM",
    "prevision": "Colmena",
    "fecha": "24/02/2024",
    "hora": "15:30",
    "cod_prestacion": 300911,
    "item": "SIN ESPECIALIDAD",
    "estado": "enAtencion",
    "valor": 496837
  },
  {
    "id": 14,
    "rut": "14382081-5",
    "num_ficha": 322106,
    "nombre": "Tomas",
    "apellido_paterno": "de la Cruz",
    "apellido_materno": "Collazo",
    "sexo": "m",
    "fecha_nacimiento": "1965-07-14T16:26:27.023Z",
    "direccion": "Av La Paz 501",
    "comuna": "Recoleta",
    "correo": "de la Cruz_Collazo@gmail.com",
    "telefono": "+56999553671",
    "aseguradora": "Consalud",
    "centros": "Antofagasta - Más Visión",
    "servicios": "CONSULTA",
    "especialidad": "IMÁGENES",
    "prevision": "Cruz del Norte",
    "fecha": "25/02/2024",
    "hora": "16:00",
    "cod_prestacion": 300250,
    "item": "SIN ESPECIALIDAD",
    "estado": "atendido",
    "valor": 3305419
  },
  {
    "id": 15,
    "rut": "12212882-2",
    "num_ficha": 322162,
    "nombre": "Isaac",
    "apellido_paterno": "Agramonte",
    "apellido_materno": "Puig",
    "sexo": "m",
    "fecha_nacimiento": "1978-09-23T15:35:09.211Z",
    "direccion": "Alonso De Cordova 2600",
    "comuna": "Vitacura",
    "correo": "Agramonte_Puig@gmail.com",
    "telefono": "+56999550992",
    "aseguradora": "Escencial",
    "centros": "Clinivisión Condell",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "LABORATORIO DE CARDIOLOGÍA",
    "prevision": "Cruz Blanca",
    "fecha": "26/02/2024",
    "hora": "16:30",
    "cod_prestacion": 300334,
    "item": "ANESTESIOLOGÍA",
    "estado": "canceladas",
    "valor": 2574386
  },
  {
    "id": 16,
    "rut": "10427260-6",
    "num_ficha": 322182,
    "nombre": "Josefa",
    "apellido_paterno": "Fernán",
    "apellido_materno": "Cadaval",
    "sexo": "f",
    "fecha_nacimiento": "1979-03-17T05:38:14.723Z",
    "direccion": "Calle Juan Aravena, 472",
    "comuna": "San Joaquín",
    "correo": "Fernán_Cadaval@gmail.com",
    "telefono": "+56990009149",
    "aseguradora": "Escencial",
    "centros": "RM - Santiago - Lyon",
    "servicios": "CONSULTA",
    "especialidad": "OFTALMOLOGÍA",
    "prevision": "Fundacion",
    "fecha": "22/02/2024",
    "hora": "17:00",
    "cod_prestacion": 300885,
    "item": "UROLOGÍA",
    "estado": "agendadas",
    "valor": 2929265
  },
  {
    "id": 17,
    "rut": "9350975-7",
    "num_ficha": 322313,
    "nombre": "Elsa",
    "apellido_paterno": "Villacrés",
    "apellido_materno": "del Pozo",
    "sexo": "f",
    "fecha_nacimiento": "1985-04-19T03:26:06.239Z",
    "direccion": "Pedro Mira 425",
    "comuna": "San Joaquin",
    "correo": "Villacrés_del Pozo@gmail.com",
    "telefono": "+56990065985",
    "aseguradora": "Consalud",
    "centros": "RM - Santiago - Tobalaba",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO",
    "prevision": "ISalud",
    "fecha": "23/02/2024",
    "hora": "17:30",
    "cod_prestacion": 300694,
    "item": "ANESTESIOLOGÍA",
    "estado": "espera",
    "valor": 3700662
  },
  {
    "id": 18,
    "rut": "15334784-0",
    "num_ficha": 322427,
    "nombre": "Martina",
    "apellido_paterno": "Santángel",
    "apellido_materno": "Rasgado",
    "sexo": "f",
    "fecha_nacimiento": "1960-12-06T10:04:42.734Z",
    "direccion": "Calle 18 De Septiembre",
    "comuna": "Viña Del Mar",
    "correo": "Santángel_Rasgado@gmail.com",
    "telefono": "+56999592086",
    "aseguradora": "Escencial",
    "centros": "Antofagasta - Diagnosalud",
    "servicios": "CONSULTA",
    "especialidad": "SERVICIO A DOMICILIO",
    "prevision": "Nueva MasVida",
    "fecha": "24/02/2024",
    "hora": "18:00",
    "cod_prestacion": 300042,
    "item": "UROLOGÍA",
    "estado": "enAtencion",
    "valor": 1905718
  },
  {
    "id": 19,
    "rut": "11038442-4",
    "num_ficha": 322545,
    "nombre": "Montserrat",
    "apellido_paterno": "Baldwin",
    "apellido_materno": "Montes",
    "sexo": "f",
    "fecha_nacimiento": "1985-02-28T13:53:37.634Z",
    "direccion": "Calle Romero, 2969",
    "comuna": "Santiago",
    "correo": "Baldwin_Montes@gmail.com",
    "telefono": "+56999459077",
    "aseguradora": "Banmedica",
    "centros": "Antofagasta - Más Visión",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO PCR CLINI",
    "prevision": "Vida Tres",
    "fecha": "25/02/2024",
    "hora": "9:30",
    "cod_prestacion": 300546,
    "item": "DERMATOLOGÍA",
    "estado": "atendido",
    "valor": 3276867
  },
  {
    "id": 20,
    "rut": "8206917-8",
    "num_ficha": 322547,
    "nombre": "Estela",
    "apellido_paterno": "Gálvez",
    "apellido_materno": "Tasis",
    "sexo": "i",
    "fecha_nacimiento": "1979-04-13T19:36:58.962Z",
    "direccion": "Camus 130",
    "comuna": "Putaendo",
    "correo": "Gálvez_Tasis@gmail.com",
    "telefono": "+56993297420",
    "aseguradora": "ISalud",
    "centros": "Clinivisión Condell",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "LABORATORIO CLÍNICO CLINI DOM",
    "prevision": "Particular",
    "fecha": "26/02/2024",
    "hora": "10:00",
    "cod_prestacion": 300707,
    "item": "PSICOLOGÍA",
    "estado": "canceladas",
    "valor": 2709088
  },
  {
    "id": 21,
    "rut": "8649763-8",
    "num_ficha": 322803,
    "nombre": "Adriana",
    "apellido_paterno": "Moruga",
    "apellido_materno": "Alguacil",
    "sexo": "f",
    "fecha_nacimiento": "1984-04-29T22:51:26.917Z",
    "direccion": "Baquedano 1001",
    "comuna": "Llanquihue",
    "correo": "Moruga_Alguacil@gmail.com",
    "telefono": "+56994426888",
    "aseguradora": "Consalud",
    "centros": "RM - Santiago - Lyon",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO PCR CLINI DOM",
    "prevision": "Fonasa",
    "fecha": "22/02/2024",
    "hora": "10:30",
    "cod_prestacion": 300742,
    "item": "UROLOGÍA",
    "estado": "agendadas",
    "valor": 1699456
  },
  {
    "id": 22,
    "rut": "9271805-0",
    "num_ficha": 323058,
    "nombre": "Mercedes",
    "apellido_paterno": "Tasis",
    "apellido_materno": "Tejedor",
    "sexo": "i",
    "fecha_nacimiento": "1978-06-30T19:00:34.947Z",
    "direccion": "Almte. Lorenzo Gotuzzo 96",
    "comuna": "Santiago",
    "correo": "Tasis_Tejedor@gmail.com",
    "telefono": "+56999674223",
    "aseguradora": "Escencial",
    "centros": "RM - Santiago - Tobalaba",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "IMÁGENES",
    "prevision": "Banmedica",
    "fecha": "23/02/2024",
    "hora": "11:00",
    "cod_prestacion": 300300,
    "item": "CIRUGÍA VASCULAR",
    "estado": "espera",
    "valor": 833127
  },
  {
    "id": 23,
    "rut": "9540662-9",
    "num_ficha": 323183,
    "nombre": "Montserrat",
    "apellido_paterno": "Ruiz",
    "apellido_materno": "Ferrant",
    "sexo": "f",
    "fecha_nacimiento": "1982-02-23T21:28:41.937Z",
    "direccion": "Avenida Los Robles, 3762",
    "comuna": "Coronel",
    "correo": "Ruiz_Ferrant@gmail.com",
    "telefono": "+56995008500",
    "aseguradora": "ISalud",
    "centros": "Antofagasta - Diagnosalud",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO DE CARDIOLOGÍA",
    "prevision": "Colmena",
    "fecha": "24/02/2024",
    "hora": "11:30",
    "cod_prestacion": 300219,
    "item": "DERMATOLOGÍA",
    "estado": "enAtencion",
    "valor": 2807878
  },
  {
    "id": 24,
    "rut": "9581756-4",
    "num_ficha": 323225,
    "nombre": "Elisenda",
    "apellido_paterno": "Cadaval",
    "apellido_materno": "Izquierdo",
    "sexo": "i",
    "fecha_nacimiento": "1960-08-07T06:50:51.323Z",
    "direccion": "Augusto Leguia Sur 79",
    "comuna": "Las Condes",
    "correo": "Cadaval_Izquierdo@gmail.com",
    "telefono": "+56995737247",
    "aseguradora": "Particular",
    "centros": "Antofagasta - Más Visión",
    "servicios": "CONSULTA",
    "especialidad": "OFTALMOLOGÍA",
    "prevision": "Cruz del Norte",
    "fecha": "25/02/2024",
    "hora": "12:00",
    "cod_prestacion": 300358,
    "item": "PSICOLOGÍA",
    "estado": "atendido",
    "valor": 1409317
  },
  {
    "id": 25,
    "rut": "8268728-9",
    "num_ficha": 323478,
    "nombre": "Isidora",
    "apellido_paterno": "Rodríguez",
    "apellido_materno": "Noboa",
    "sexo": "f",
    "fecha_nacimiento": "1989-01-08T19:43:34.863Z",
    "direccion": "Monjitas 527",
    "comuna": "Santiago",
    "correo": "Rodríguez_Noboa@gmail.com",
    "telefono": "+56999122727",
    "aseguradora": "Banmedica",
    "centros": "Clinivisión Condell",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO",
    "prevision": "Cruz Blanca",
    "fecha": "26/02/2024",
    "hora": "12:30",
    "cod_prestacion": 300923,
    "item": "UROLOGÍA",
    "estado": "canceladas",
    "valor": 2228674
  },
  {
    "id": 26,
    "rut": "13304320-9",
    "num_ficha": 323534,
    "nombre": "Tatiana",
    "apellido_paterno": "Illanes",
    "apellido_materno": "Ruiz",
    "sexo": "f",
    "fecha_nacimiento": "1990-01-29T10:03:37.228Z",
    "direccion": "Calle Arlegui, 1175",
    "comuna": "Viña Del Mar",
    "correo": "Illanes_Ruiz@gmail.com",
    "telefono": "+56994551309",
    "aseguradora": "Escencial",
    "centros": "RM - Santiago - Lyon",
    "servicios": "CONSULTA",
    "especialidad": "SERVICIO A DOMICILIO",
    "prevision": "Fundacion",
    "fecha": "22/02/2024",
    "hora": "13:00",
    "cod_prestacion": 300667,
    "item": "ANESTESIOLOGÍA",
    "estado": "agendadas",
    "valor": 4239931
  },
  {
    "id": 27,
    "rut": "13542627-k",
    "num_ficha": 323652,
    "nombre": "Javiera",
    "apellido_paterno": "Cabal",
    "apellido_materno": "Pomar",
    "sexo": "f",
    "fecha_nacimiento": "1973-06-12T08:44:01.984Z",
    "direccion": "Barros Borgoño 105",
    "comuna": "Maipú",
    "correo": "Cabal_Pomar@gmail.com",
    "telefono": "+56999955714",
    "aseguradora": "Colmena",
    "centros": "RM - Santiago - Tobalaba",
    "servicios": "CONSULTA",
    "especialidad": "LABORATORIO PCR CLINI",
    "prevision": "ISalud",
    "fecha": "23/02/2024",
    "hora": "13:30",
    "cod_prestacion": 300783,
    "item": "TRAUMATOLOGÍA",
    "estado": "espera",
    "valor": 2041965
  },
  {
    "id": 28,
    "rut": "15412927-8",
    "num_ficha": 323759,
    "nombre": "Marcela",
    "apellido_paterno": "Espina",
    "apellido_materno": "Ruiz",
    "sexo": "f",
    "fecha_nacimiento": "1980-05-03T02:41:56.329Z",
    "direccion": "Via Gris 9425",
    "comuna": "Vitacura",
    "correo": "Espina_Ruiz@gmail.com",
    "telefono": "+56996150181",
    "aseguradora": "Escencial",
    "centros": "Antofagasta - Diagnosalud",
    "servicios": "CIRUGIA GENERAL",
    "especialidad": "LABORATORIO CLÍNICO CLINI DOM",
    "prevision": "Nueva MasVida",
    "fecha": "24/02/2024",
    "hora": "14:00",
    "cod_prestacion": 300600,
    "item": "PSICOLOGÍA",
    "estado": "enAtencion",
    "valor": 3858044
  },
  {
    "id": 29,
    "rut": "10506986-3",
    "num_ficha": 323878,
    "nombre": "Violeta",
    "apellido_paterno": "Sarmiento",
    "apellido_materno": "Ossandon",
    "sexo": "f",
    "fecha_nacimiento": "1966-01-29T06:55:41.381Z",
    "direccion": "Av. Larraín 6642",
    "comuna": "La Reina",
    "correo": "Sarmiento_Ossandon@gmail.com",
    "telefono": "+56994450870",
    "aseguradora": "Vida Tres",
    "centros": "Antofagasta - Más Visión",
    "servicios": "CIRUGÍA REFRACTIVA - CIRUGÍA LASIK",
    "especialidad": "LABORATORIO PCR CLINI DOM",
    "prevision": "Vida Tres",
    "fecha": "25/02/2024",
    "hora": "14:30",
    "cod_prestacion": 300620,
    "item": "CIRUGÍA PLÁSTICA",
    "estado": "atendido",
    "valor": 2872704
  },
  {
    "id": 30,
    "rut": "12170044-1",
    "num_ficha": 324047,
    "nombre": "Valery",
    "apellido_paterno": "Martino",
    "apellido_materno": "Vera",
    "sexo": "f",
    "fecha_nacimiento": "1978-02-23T12:59:09.814Z",
    "direccion": "Ureta Cox 755",
    "comuna": "San Miguel",
    "correo": "Martino_Vera@gmail.com",
    "telefono": "+56998327632",
    "aseguradora": "Escencial",
    "centros": "Clinivisión Condell",
    "servicios": "CONSULTA",
    "especialidad": "IMÁGENES",
    "prevision": "Particular",
    "fecha": "26/02/2024",
    "hora": "15:00",
    "cod_prestacion": 300207,
    "item": "ENDOCRINOLOGÍA",
    "estado": "canceladas",
    "valor": 2303559
  }
]

export default CitasTomadas;