import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Context } from '../../../../../content/appContext';

// FAKEAPI
// import Aseguradorasfk from '../../../../../content/fake_db/fakeAseguradoras';

// STYLES
import '../../../../../styles/dmAseguradoras.css';

const AseguradorasBorrar = () => {
    
    const { store } = useContext(Context);
    const { cod } = useParams();
    
    return (
        <div className="container DmAseguradorasAccion">
            <div className="row mb-5">
                <h2 className="my-3">Borrar Aseguradora</h2>
                <div className="col-8 offset-2">
                    {
                        store.aseguradoras ? store.aseguradoras.map(Aseguradora => {
                            if (parseInt(cod) === parseInt(Aseguradora.ipr_Codigo_Prev)) {
                                return (
                                    <form action="" key={Aseguradora.ipr_Codigo_Prev}>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Cod.</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Codigo_Prev} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Nombre</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Descripcion_Prev} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Alias</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Alias} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Id. identificador</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Rut} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Razón Social</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Razon_Social} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Inicio Vigencia</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Fec_Inicio} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Modo Facturar Ambulatorio</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Forma_Fac_Amb} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Modo Facturar Hospitalizado</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Forma_Fac_Hosp} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Modo Facturar Urgencia</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Forma_Fac_Urg} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Clase</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.cas_Codigo} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Orden</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Orden} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Id Externo</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Id_Externo} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Término Vigencia</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Fec_Termino} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <p>Tipo</p>
                                            </div>
                                            <div className="col-7">
                                                <input className="form-control" type="text" defaultValue={Aseguradora.ipr_Tipo_Prevision} disabled />
                                            </div>
                                        </div>
                                        <h5 className="my-5"><strong>Estás seguro que quieres eliminar a {Aseguradora.ipr_Alias}? (Esto será permanente)</strong></h5>
                                        <Link className="btn btn-danger me-2" to="/dm-aseguradoras/">Atrás</Link>
                                        <Link className="btn btn-success" to="/">Eliminar</Link>
                                        {/* <button className="btn btn-success" type="submit">Eliminar</button> */}


                                    </form>
                                )
                            } else {
                                return null;
                            }
                        }) : (
                            <div className="d-flex justify-content-center text-center my-5">
                                <div className="spinner-grow text-warning my-5" role="status">
                                    <span className="visually-hidden"></span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default AseguradorasBorrar;