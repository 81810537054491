import React from 'react';
import { Link } from 'react-router-dom';

// ICONS
import lupaIcon from '../../../../static/icons/lupaIcon.png';
import modIcon from '../../../../static/icons/modificarIcon.png';
import trashIcon from '../../../../static/icons/trashIcon.png';

const CentrosClinicos = () => {
    return (
        <div className="container dmAseguradoras my-3">
            <div className="row center">
                <div className="col">
                    <div className="text-center my-3">
                        <h1>Centros Clínicos</h1>
                        <Link className="btn btn-success" to="/ecc_centros_clinicos/agregar">Agregar Nuevo</Link>
                    </div>
                    <table className="table table-striped table-hover align-middle text-center">
                        <thead>
                            <tr>
                                <th scope="col">Cod.</th>
                                <th scope="col">Id. Legal</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Alias</th>
                                <th scope="col">País</th>
                                <th scope="col">Ciudad</th>
                                <th scope="col">Dirección</th>
                                <th scope="col">Teléfono</th>
                                <th scope="col">Otro Teléfono</th>
                                <th scope="col">Usa Hospitalización</th>
                                <th scope="col">Usa Urgencias</th>
                                <th scope="col">Usa Alta Administrativa</th>
                                <th scope="col">Tiempo Max. Libera Cama (min)</th>
                                <th scope="col">Hora Inicio Ind. Médica</th>
                                <th scope="col">Nro. horas Máx. Indicac. Medica</th>
                                <th scope="col">Nro. Máx. Ind. Manual x Planif.</th>
                                <th scope="col">Tiene Consulta Médica</th>
                                <th scope="col">Nivel Fonasa</th>
                                <th scope="col">Utiliza Vta Electrónica</th>
                                <th scope="col">Utiliza Vta Internet</th>
                                <th scope="col">Utiliza Vta Docto. Físico</th>
                                <th scope="col">Centro Recaudador</th>
                                <th scope="col">Logo Centro</th>
                                <th scope="col">Término de Vigencia</th>
                                <th scope="col">Correo</th>
                                <th scope="col">Servidor Correo</th>
                                <th scope="col">Cuenta Usuario</th>
                                <th scope="col">Password Cuenta</th>
                                <th scope="col">Puerto Correo</th>
                                <th scope="col">Autentifica SSL</th>
                                <th scope="col" className="size">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>34567</td>
                                <td>jhgfds</td>
                                <td>ertyu</td>
                                <td>nbvcx</td>
                                <td>45678</td>
                                <td>87654</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>Si</td>
                                <td>No</td>
                                <td>7654</td>
                                <td>123</td>
                                <td>sef</td>
                                <td>qwe</td>
                                <td>sdfdsf</td>
                                <td>adfdf</td>
                                <td>aefdfv</td>
                                <td>sacvsdv</td>
                                <td>Aadvsbf01</td>
                                <td>uytr</td>
                                <td className="size">
                                    <Link className="btn btn-success" to="/ecc_centros_clinicos/ver/1"><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                    <Link className="btn btn-warning" to="/ecc_centros_clinicos/modificar/1"><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                    <Link className="btn btn-danger" to="/ecc_centros_clinicos/eliminar/1"><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                </td>
                            </tr>
                            {/* {
                                Aseguradoras ? Aseguradoras.map((Aseguradora) => {
                                    return (

                                        <tr>
                                            <th scope="row" key={Aseguradora.cod}>{Aseguradora.cod}</th>
                                            <td>{Aseguradora.nombre}</td>
                                            <td>{Aseguradora.alias}</td>
                                            <td>{Aseguradora.idIdentificador}</td>
                                            <td>{Aseguradora.razonSocial}</td>
                                            <td>{Aseguradora.inicioVigencia}</td>
                                            <td>{Aseguradora.modoFacturarAmbulatorio}</td>
                                            <td>{Aseguradora.modoFacturarHospitalizado}</td>
                                            <td>{Aseguradora.modoFacturarUrgencia}</td>
                                            <td>{Aseguradora.clase}</td>
                                            <td>{Aseguradora.orden}</td>
                                            <td>{Aseguradora.idExterno}</td>
                                            <td>{Aseguradora.terminoVigencia}</td>
                                            <td>{Aseguradora.tipo}</td>
                                            <td className="size">
                                                <Link className="btn btn-success" to={"/dm-aseguradoras/ver/" + Aseguradora.cod}><img className="icon" src={lupaIcon} alt="Ver" /></Link>
                                                <Link className="btn btn-warning" to={"/dm-aseguradoras/modificar/" + Aseguradora.cod}><img className="icon" src={modIcon} alt="Modificar" /></Link>
                                                <Link className="btn btn-danger" to={"/dm-aseguradoras/eliminar/" + Aseguradora.cod}><img className="icon" src={trashIcon} alt="Eliminar" /></Link>
                                            </td>
                                        </tr>

                                    )
                                }) : null
                            } */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CentrosClinicos;