import React from 'react';
import { Link, useParams } from 'react-router-dom';

// FAKEDATA
import Citas from '../../content/fake_db/fakeCitas';

const AWConfirmarEliminarCita = () => {
    
    const { agenda_id } = useParams();

    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                <div>
                        {
                            Citas ? Citas.map((Cita) => {
                                if (parseInt(Cita.id) === parseInt(agenda_id)) {
                                    return (
                                        <div className="card card-reserva m-5" key={Cita.id}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-body" key={Cita.id}>
                                                        <h4 className="card-title">¡Estás a punto de Eliminar una hora!</h4>
                                                        <div className="d-flex">
                                                            <p className="card-text">El día {Cita.fecha} a las {Cita.hora} en {Cita.centro} con doctor(a) {Cita.profesional}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            }) : (
                                <div className="d-flex justify-content-center text-center my-5">
                                    <div className="spinner-grow text-warning my-5" role="status">
                                        <span className="visually-hidden"></span>
                                    </div>
                                </div>
                            )
                        }
                        <form className="col-md-4 offset-md-4 col-sm-12">
                            <h5 className="my-5 center">¿Confirma la eliminación de la cita?</h5>
                            <div className="d-flex justify-content-center my-5">
                                <Link className="btn btn-danger" to="/">Cancelar</Link>
                                {/* <button className="btn btn-success ms-3" type="submit">Agendar</button> */}
                                <Link className="btn btn-success ms-3" to="/">Agendar</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AWConfirmarEliminarCita;